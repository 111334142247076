import React, { useState } from "react";
import CompanyGuidelineForm from "./CompanyGuidelineForm";
import CommonModal from "../../components/common/Modal";
import { fileUrl } from "../../utils/Constants";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const CompanyGuidelineList = (props) => {
  const [modal, setModal] = useState(false);
  const { companyGuideline, handleView, isLoading, type } = props;
  const checkCompanyGuideline = companyGuideline ?? "";
  const finalCompanyGuideline =
    checkCompanyGuideline !== "" ? [companyGuideline] : [];
  const toggleModal = () => {
    setModal(!modal);
  };

  // if (isLoading) {
  //   return (
  //     <div className="text-center">
  //       <Spinner />
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  {/* table header and filed value need to be changed later */}
                  <th className="text-center">SN.</th>
                  <th>Heading</th>
                  <th>Active</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {companyGuideline && companyGuideline.length > 0 ? (
                  companyGuideline.map((cmp, id) => {
                    return (
                      <tr key={id}>
                        <td className="text-center">{id + 1}</td>
                        <td>{cmp?.header}</td>
                        <td>{cmp?.is_active === 1 ? "Yes" : "No"}</td>
                        <td className="td-actions text-right">
                          {cmp.media && cmp.media.length ? (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-primary rounded-circle btn-sm"
                              style={{ marginLeft: "0.75rem" }}
                              data-original-title=""
                              title=""
                              // onClick={e => handleView(e, cmp.id)}
                            >
                              <i className="material-symbols-outlined">
                                <a
                                  href={
                                    fileUrl +
                                    "/" +
                                    cmp.media[0]?.id +
                                    "/" +
                                    cmp.media[0]?.file_name
                                  }
                                  target={"_blank"}
                                >
                                  {" "}
                                  visibility
                                </a>
                              </i>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    {isLoading ? (
                      <td colSpan={4}>
                        <LoadingAnimation spinner />
                      </td>
                    ) : (
                      <td colSpan={4}>{`No ${type} found.`}</td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="xl"
        modalHeader="Edit CompanyGuideline"
        modalBody={
          <>
            <CompanyGuidelineForm />
          </>
        }
      />
    </>
  );
};

export default CompanyGuidelineList;
