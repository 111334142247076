import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CommonModal from "../../components/common/Modal";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import ListLeaveRequest from "./ListLeaveRequest";
import { ViewLeaveDetails } from "./ViewLeaveDetails";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

class ViewRequestedLeaves extends Component {
  state = {
    allUsers: [],
    ccUsers: [],
    leaves: null,
    leaveStatus: null,
    loader: false,
    modal: false,
    modalRemarks: false,
    remarks: "",
    selectedLeaveToView: null,
    submitSpinner: false,
  };

  componentDidMount() {
    this.getAllUsers();
    this.getRequestedLeaves();
    // this.setState({ remarks: "" });
  }

  getAllUsers = () => {
    axiosGet(URL.getOverTimeUsers, (response) => {
      if (response.status === 200) {
        let responseData = response.data;
        responseData?.forEach((element) => {
          element.label = element.email;
          element.value = element.id;
        });
        this.setState({ allUsers: responseData });
      }
    });
  };

  getRequestedLeaves = (pageNumber = 1) => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.getRequestedLeaves + "?" + "page=" + pageNumber,
        (res) => {
          if (res.status === 200) {
            this.setState({ loader: false, leaves: res.data.data });
          }
        },
        (err) => {
          this.setState({
            loader: false,
          });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleAcceptLeave = (e, id, status) => {
    let leaveStatus = {
      id: id,
      status: status,
    };
    this.setState(
      { modalRemarks: true, leaveStatus: leaveStatus },
      function () {}
    );
  };

  handleCcToTeam = (e) => {
    const { options } = e.target;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ ccUsers: value });
  };

  handleChangeRemarks = (value) => {
    this.setState({ remarks: value });
  };

  handleSubmitLeave = (e) => {
    e.preventDefault();
    let leaveStatus = this.state.leaveStatus;
    let params = {
      leaveId: Number(leaveStatus?.id),
      remarks: this.state.remarks,
      cc: this.state.ccUsers,
    };
    if (leaveStatus?.status === "accept") {
      params.status = "Accepted";
    } else {
      params.status = "Rejected";
    }
    this.setState({ submitSpinner: true }, function () {
      axiosPost(
        URL.acceptLeave,
        params,
        (res) => {
          this.setState({ submitSpinner: false });
          if (res.data.success) {
            this.toggleModalRemarks();
            swal(
              "Success",
              leaveStatus?.status === "accept"
                ? "Leave request accepted successfully"
                : "Leave request rejected",
              "success"
            );
            this.setState({ remarks: "" });
            this.getRequestedLeaves();
          }
        },
        (err) => {
          this.setState({ submitSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleViewLeave = (e, id) => {
    const selectedLeave = this.state.leaves.data.find(
      (leav) => leav?.id === Number(id)
    );
    this.setState({ modal: true, selectedLeaveToView: selectedLeave });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  toggleModalRemarks = () => {
    this.setState({ modalRemarks: !this.state.modalRemarks });
  };

  render() {
    return (
      <>
        <div className="mt-2">
          <div className="pageTitle">View Requested Leaves </div>

          {this.state.submitSpinner ? (
            <div
              className="container"
              style={{ padding: "1rem", height: "100%" }}
            >
              <div className="fullWindow-Spinner">
                <div>
                  <Spinner color="white"></Spinner>
                </div>
                <div style={{ fontSize: "16px", marginTop: "15px" }}>
                  Please Wait. Submitting ...
                </div>
              </div>
            </div>
          ) : null}

          <div className="row justify-content-center d-flex text-center">
            {/* {this.state.leaves?.data?.length > 0 ? ( */}
            <div>
              <ListLeaveRequest
                allUsers={this.state.allUsers}
                ccUsers={this.state.ccUsers}
                request={true}
                leaveData={this.state.leaves}
                toggleModal={this.toggleModalRemarks}
                modal={this.state.modalRemarks}
                // assignedPermission={assignedPermission}
                handleAcceptLeave={this.handleAcceptLeave}
                handleSubmit={this.handleSubmitLeave}
                handleCcToTeam={this.handleCcToTeam}
                remarks={this.state.remarks}
                setRemarks={this.handleChangeRemarks}
                leaveStatus={this.state.leaveStatus}
                handleViewLeave={this.handleViewLeave}
                getLeaveData={this.getRequestedLeaves}
                activeModule={"main"}
                isLoading={this.state.loader}
              />
            </div>
            {/* ) : this.state.loader ? (
              <LoadingAnimation spinner />
            ) : (
              <div className="newTextColor text-center">No Leave Requests</div>
            )} */}
          </div>
        </div>
        <CommonModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          size="lg"
          modalHeader="Leave Details"
          modalBody={
            <ViewLeaveDetails
              selectedLeaveToView={this.state.selectedLeaveToView}
            />
          }
        />
      </>
    );
  }
}

export default ViewRequestedLeaves;
