import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  faAngleDown,
  faExternalLinkAlt,
  faPassport,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import logo from "../assets/images/user-image.png";
import { fileUrl, cryptoSecretKey, siteName } from "../utils/Constants";
import CommonModal from "./common/Modal";
import PasswordChange from "../pages/DepartmentStaff/DepartmentStaffForm/PasswordChange";
import { faFoursquare } from "@fortawesome/free-brands-svg-icons";
import userImage from "../assets/images/user-image.png";

class Header extends Component {
  state = {
    userDropdown: false,
    modal: false,
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const data = localStorage.getItem("roles");
    const roles = JSON.parse(
      CryptoJS.AES.decrypt(data, cryptoSecretKey).toString(CryptoJS.enc.Utf8)
    );
    const userRole = ["Developer Admin", "Super Admin"];
    const isDevAdmin = userRole?.includes(roles[0]);
    // const navigate = useNavigate();

    return (
      <>
        <div className="headerBg">
          <div className="userInfo">
            {this.props.userInfo
              ? this.props.userInfo.company
                ? this.props.userInfo.company.name
                : siteName
              : siteName}
          </div>
          <div style={{ position: "absolute", right: "10px" }}>
            <div>
              <Dropdown
                toggle={() => {
                  this.setState({ userDropdown: !this.state.userDropdown });
                }}
                isOpen={this.state.userDropdown}
                className="userDropdown"
              >
                <DropdownToggle className="d-flex">
                  <div className="wel">
                    <small>Welcome, </small>
                    <div>
                      {this.props.userInfo ? (
                        <>{this.props.userInfo?.first_name}</>
                      ) : (
                        "User"
                      )}
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{ marginLeft: "10px" }}
                  ></FontAwesomeIcon>
                </DropdownToggle>
                <DropdownMenu className="userDropdown-menu" container="body">
                  <div className="user">
                    {this.props.userInfo ? (
                      this.props.userInfo.company ? (
                        this.props.userInfo.company.media.length > 0 ? (
                          this.props.userInfo.company.media.map((img, idx) => {
                            if (img.collection_name === "company_logo") {
                              return (
                                <img
                                  key={img?.id}
                                  src={
                                    fileUrl + "/" + img.id + "/" + img.file_name
                                  }
                                  className="user-img img-fluid"
                                  alt="logo"
                                  onError={(e) => (e.target.src = userImage)}
                                />
                              );
                            }
                          })
                        ) : (
                          <img
                            src={logo}
                            className="user-img img-fluid"
                            alt="logo"
                            onError={(e) => (e.target.src = userImage)}
                          />
                        )
                      ) : (
                        this.props.userInfo.media &&
                        this.props.userInfo.media.map((img, idx) => {
                          if (img.collection_name === "profile_picture_files") {
                            return (
                              <img
                                key={img?.id}
                                src={
                                  fileUrl + "/" + img.id + "/" + img.file_name
                                }
                                className="user-img img-fluid"
                                alt="logo"
                                onError={(e) => (e.target.src = userImage)}
                              />
                            );
                          }
                        })
                      )
                    ) : null}

                    <h5>
                      {this.props.userInfo?.first_name}{" "}
                      {this.props.userInfo?.middle_name
                        ? `${this.props.userInfo.middle_name} `
                        : null}
                      {this.props.userInfo?.last_name}
                    </h5>
                    {/* <h6>{this.props.userInfo?.email}]</h6> */}
                    <h6 className=" pb-1">
                      {this.props.userInfo?.office_email ??
                        this.props.userInfo?.email}
                    </h6>
                  </div>
                  {/* <DropdownItem onClick={this.myProfile}>
                  <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>{" "}
                  <span>Profile</span>
                </DropdownItem> */}
                  <Link to="/">
                    {!isDevAdmin && (
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleModal();
                        }}
                      >
                        <FontAwesomeIcon icon={faPassport}></FontAwesomeIcon>{" "}
                        <span>Change Password</span>
                      </DropdownItem>
                    )}

                    <Link to="/faq">
                      <DropdownItem
                      // onClick={() => navigate("/faq")}
                      // className={isDevAdmin && "text-center"}
                      >
                        <FontAwesomeIcon icon={faFoursquare}></FontAwesomeIcon>{" "}
                        <span>HRM FAQ's</span>
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      onClick={() => localStorage.clear()}
                      // className={isDevAdmin && "text-center"}
                    >
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                      ></FontAwesomeIcon>{" "}
                      <span>Logout</span>
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>

        <CommonModal
          modal={this.state.modal}
          toggleModal={this.toggleModal}
          size="lg"
          modalHeader="Change Password"
          modalBody={<PasswordChange toggleModal={this.toggleModal} Header />}
        />
      </>
    );
  }
}

export default Header;
