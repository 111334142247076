import React, { Component, Suspense } from "react";
import Routes from "./routes/Routes";
import LoadingAnimation from "./components/LoaderAnimation/LoaderAnimation";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={<LoadingAnimation />}>
          <Routes />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App;
