import React, { useState } from "react";
import { bytesToMegaBytes, fileUrl } from "../../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";
import { phoneValidate, convertToSnakeCase } from "../../../utils/Validation";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Button from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
// import FileViewer from "react-file-viewer";
import errorImage from "../../../assets/images/user-image.png";
import CustomFileViewer from "../../../components/common/CustomFileViewer";

const StaffDetails = (props) => {
  const {
    staff,
    setStaff,
    editParam,
    updatedFiles,
    setUpdatedFiles,
    department,
    setRoles,
    defaultRoles,
    country,
    cities,
    handleNext,
    addProfile,
    userInfo,
    editableDptStf,
    setEditableDptStf,
    fileName,
    handleRemoveFile,
    userInfoFile,
    setUserInfo,
    roles1,
  } = props;

  const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const [modal, setModal] = useState({
    ctzModal: false,
    status: null,
    panModal: false,
    sigModal: false,
  });

  const [modalI, setModalI] = useState({
    ctzInfoModal: false,
    status: null,
    panInfoModal: false,
    sigInfoModal: false,
  });

  const [modalD, setModalD] = useState({
    ctzModal: false,
    status: null,
    panModal: false,
    sigModal: false,
  });

  const [modalU, setModalU] = useState({
    ctzModal: false,
    status: null,
    panModal: false,
    sigModal: false,
  });

  const [errImage, setErrImage] = useState(false);

  const handleImageError = (e) => {
    setErrImage(true);
  };

  const toggleModal = (e, status) => {
    status === undefined &&
      setModal((prevState) => ({
        ...prevState,
        ctzModal: false,
        panModal: false,
        sigModal: false,
        // ctzInfoModal: false
      }));

    status === "ctz" &&
      setModal((prevState) => ({
        ...prevState,
        ctzModal: !modal.ctzModal,
        status,
      }));

    status === "pan" &&
      setModal((prevState) => ({
        ...prevState,
        panModal: !modal.panModal,
        status,
      }));

    status === "sig" &&
      setModal((prevState) => ({
        ...prevState,
        sigModal: !modal.sigModal,
        status,
      }));
  };
  const toggleModalU = (e, status) => {
    status === undefined &&
      setModalU((prevState) => ({
        ...prevState,
        ctzModal: false,
        panModal: false,
        sigModal: false,
      }));

    status === "ctz" &&
      setModalU((prevState) => ({
        ...prevState,
        ctzModal: !modalU.ctzModal,
        status,
      }));

    status === "pan" &&
      setModalU((prevState) => ({
        ...prevState,
        panModal: !modalU.panModal,
        status,
      }));

    status === "sig" &&
      setModalU((prevState) => ({
        ...prevState,
        sigModal: !modalU.sigModal,
        status,
      }));
  };
  const toggleModalD = (e, status) => {
    status === undefined &&
      setModalD((prevState) => ({
        ...prevState,
        ctzModal: false,
        panModal: false,
        sigModal: false,
      }));

    status === "ctz" &&
      setModalD((prevState) => ({
        ...prevState,
        ctzModal: !modalD.ctzD,
        status,
      }));

    status === "pan" &&
      setModalD((prevState) => ({
        ...prevState,
        panModal: !modalD.panModal,
        status,
      }));

    status === "sig" &&
      setModalD((prevState) => ({
        ...prevState,
        sigModal: !modalD.sigModal,
        status,
      }));
  };

  const toggleModalI = (e, status) => {
    status === undefined &&
      setModalI((prevState) => ({
        ...prevState,
        ctzInfoModal: false,
        panInfoModal: false,
        sigInfoModal: false,
      }));

    status === "ctzInfo" &&
      setModalI((prevState) => ({
        ...prevState,
        ctzInfoModal: !modalI.ctzInfoModal,
        status,
      }));

    status === "panInfo" &&
      setModalI((prevState) => ({
        ...prevState,
        panInfoModal: !modalI.panInfoModal,
        status,
      }));

    status === "sigInfo" &&
      setModalI((prevState) => ({
        ...prevState,
        sigInfoModal: !modalI.sigInfoModal,
        status,
      }));
  };

  const image =
    "https://media.istockphoto.com/vectors/default-gray-placeholder-man-vector-id871752462?s=612x612";

  const handleRoleChange = (selectedRole, action) => {
    if (!editParam?.isEdit) {
      if (Array.isArray(selectedRole)) {
        setRoles(selectedRole);
        setStaff((prevState) => ({ ...prevState, roles: selectedRole }));
      }
    } else {
      const cloneDptStf = [...editableDptStf];
      const isRoleAvailable =
        cloneDptStf[editParam.selectedDepartment].staff[editParam.selectedIndex]
          .department_staff_roles;

      if (action.action === "remove-value") {
        const finalRole = isRoleAvailable.filter(
          (el) => (el.name ?? el.label) !== action.removedValue.label
        );
        cloneDptStf[editParam.selectedDepartment].staff[
          editParam.selectedIndex
        ].department_staff_roles = finalRole;
      }
      if (action.action === "select-option") {
        cloneDptStf[editParam.selectedDepartment].staff[
          editParam.selectedIndex
        ].department_staff_roles = selectedRole;
      }
      setEditableDptStf(cloneDptStf);
    }
  };

  const handlePhoneChange = (e, value) => {
    if (editParam?.isEdit) {
      const cloneDptStf = [...editableDptStf];
      const finalName = value.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
      cloneDptStf[editParam.selectedDepartment].staff[editParam.selectedIndex][
        finalName
      ] = e;

      setEditableDptStf(cloneDptStf);
    } else {
      setStaff((prevState) => ({ ...prevState, [value]: e }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const fileName = [
      "citizenship",
      "panCard",
      "staffImage",
      "digitalSignature",
    ];
    const reqName = [
      "personalNumber",
      "corporateNumber",
      "emergency_numbertempo",
    ];
    setErrImage(false);

    if (!editParam?.isEdit && !addProfile) {
      if (!fileName.includes(name)) {
        if (reqName.includes(name) && value !== "") {
          if (!phoneValidate(value)) {
            swal("Warning", "Numbers only", "warning");
            return;
          }
        }
        setStaff((prevState) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }));
      } else {
        let data = e.target.files;

        if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }

        if (data && data[0]) {
          setStaff((prevState) => ({ ...prevState, [name]: data[0] }));
        }
      }
    } else if (editParam?.isEdit) {
      const cloneDptStf = [...editableDptStf];

      if (!fileName.includes(name)) {
        let finalName;
        if (name === "isAllFieldRequired") {
          finalName = "need_to_be_filled";
          cloneDptStf[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ][finalName] = checked === true ? 1 : 0;
        } else {
          finalName = name.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
          cloneDptStf[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ][finalName] = value;
        }
      } else {
        let data = e.target.files;

        if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }

        if (data && data[0]) {
          setUpdatedFiles((prevState) => ({ ...prevState, [name]: data[0] }));
        }
      }
      setEditableDptStf(cloneDptStf);
    } else {
      const fileName = [
        "citizenship",
        "panCard",
        "staffImage",
        "digitalSignature",
      ];
      const reqName = ["personalNumber", "corporateNumber"];

      //make changes

      const fileToBeStore = [
        "firstName",
        "lastName",
        "middleName",
        "personalEmail",
        "officeEmail",
        "jobTitle",
      ];
      // const cloneUserInfo = { ...userInfo };
      if (fileToBeStore.includes(name)) {
        let reqName = convertToSnakeCase(name);
        setUserInfo(value, reqName);
      }
      if (!fileName.includes(name) && !fileToBeStore.includes(name)) {
        if (reqName.includes(name) && value !== "") {
          if (!isValidPhoneNumber(value)) {
            swal("Warning", "Invalid phone number", "warning");
            return;
          }
        }
        setStaff((prevState) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }));
      } else {
        let data = e.target.files;

        if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        } else {
          if (name === "staffImage" && !data[0].type.includes("image")) {
            swal(
              "warning",
              "Unsupported file type. Please upload image file only",
              "warning"
            );
            return;
          }
        }

        if (data && data[0]) {
          setStaff((prevState) => ({ ...prevState, [name]: data[0] }));
        }
      }
    }
  };

  const getImage = () => {
    if (!editParam?.isEdit && !addProfile) {
      if (staff?.staffImage) {
        return URL.createObjectURL(staff?.staffImage);
      } else {
        return image;
      }
    } else if (editParam?.isEdit) {
      //find media
      if (updatedFiles && updatedFiles.staffImage) {
        return URL.createObjectURL(updatedFiles?.staffImage);
      }

      const { id, file_name } = editableDptStf[
        editParam.selectedDepartment
      ]?.staff[editParam.selectedIndex]?.media.find(
        (el) => el.collection_name === "profile_picture_files"
      );
      return fileUrl + "/" + id + "/" + file_name;
    } else {
      if (staff && staff.staffImage) {
        return URL.createObjectURL(staff.staffImage);
      }
      const { id, file_name } = userInfo?.media.find(
        (el) => el.collection_name === "profile_picture_files"
      );
      return fileUrl + "/" + id + "/" + file_name;
    }
  };

  const getRolesValue = () => {
    if (editParam?.isEdit) {
      const cloneDptStf = [...editableDptStf];
      const { selectedDepartment, selectedIndex } = editParam;
      const selectedRoles =
        cloneDptStf[selectedDepartment]?.staff[selectedIndex]
          ?.department_staff_roles;
      const roles = selectedRoles.map((el) => ({
        label: el.name ?? el.label,
        value: el.id ?? el.value,
      }));
      return roles;
    } else {
      return staff?.roles;
    }
  };

  const selectedStaffToBeEdited =
    editableDptStf &&
    editableDptStf[editParam.selectedDepartment]?.staff[
      editParam.selectedIndex
    ];
  return (
    <>
      <h2 className="fs-title">Staff Details</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>
      <div className="container border">
        <div className="row">
          <div className="col profile-img1">
            <div>
              <img
                src={errImage ? errorImage : getImage()}
                alt="companyLogo"
                onError={handleImageError}
              />
              <div className="file btn ">
                {editParam?.isEdit ?? addProfile
                  ? "Edit Image"
                  : "Upload Image"}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                <input
                  accept="image/png,image/jpeg,image/jpg"
                  type="file"
                  name="staffImage"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              First Name <span className="required"> *</span>{" "}
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.first_name
                  : addProfile
                  ? staff?.firstName ?? userInfo?.first_name
                  : staff?.firstName
              }
              onChange={handleChange}
            />
          </div>

          <div className="col">
            <label className="floating">Middle Name</label>
            <input
              type="text"
              name={"middleName"}
              placeholder="Middle Name"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.middle_name
                  : addProfile
                  ? staff?.middleName ?? userInfo?.middle_name
                  : staff?.middleName
              }
              // value={staff?.middleName}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="floating">
              Last Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name={"lastName"}
              placeholder="Last Name"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.last_name
                  : addProfile
                  ? staff?.lastName ?? userInfo?.last_name
                  : staff?.lastName
              }
              // value={staff?.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              Job Title <span className="required">*</span>
            </label>
            <input
              type="text"
              name="jobTitle"
              placeholder="Job Title"
              onChange={handleChange}
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.job_title
                  : addProfile
                  ? staff?.jobTitle ?? userInfo?.job_title
                  : staff?.jobTitle
              }
              // value={staff?.jobTitle}
            />
          </div>
        </div>

        <div className="row">
          {!addProfile && (
            <>
              <div className="col">
                <label className="floating">
                  Department<span className="required">*</span>
                </label>

                <select
                  className="formSelect"
                  onChange={handleChange}
                  name="department"
                  // disabled={!!addProfile}
                  value={
                    editParam && editParam.isEdit
                      ? editableDptStf[editParam.selectedDepartment]?.staff[
                          editParam.selectedIndex
                        ]?.department
                      : addProfile
                      ? userInfo?.job_title
                      : staff?.department
                  }
                >
                  <option value="" disabled selected>
                    Choose Department
                  </option>
                  {department && department.length > 0
                    ? department.map((dpt, id) => {
                        return (
                          <option value={dpt.id} key={id}>
                            {dpt.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>

              <div className="col">
                <label className="floating">
                  Role <span className="required">*</span>{" "}
                </label>

                <Select
                  // className="formSelect"
                  classNamePrefix="select"
                  placeholder="Role"
                  isMulti={true}
                  isClearable={true}
                  // value={roles}
                  options={defaultRoles}
                  onChange={handleRoleChange}
                  isDisabled={
                    editParam?.isEdit
                      ? false
                      : staff?.department === undefined || addProfile
                      ? true
                      : false
                  }
                  value={getRolesValue()}
                  // noOptionsMessage={() => "Select Department First"}
                />
              </div>
            </>
          )}
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              Office Email <span className="required">*</span>
            </label>
            <input
              type="email"
              name="officeEmail"
              placeholder="Office Email"
              // value={staff?.officeEmail}
              disabled={addProfile}
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam?.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.office_email
                  : addProfile
                  ? userInfo?.office_email
                  : staff?.officeEmail
              }
              onChange={handleChange}
            />
          </div>

          <div className="col">
            <label className="floating">
              Personal Email <span className="required">*</span>
            </label>
            <input
              type="email"
              name="personalEmail"
              placeholder="Personal Email"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.personal_email
                  : addProfile
                  ? staff?.personalEmail ?? userInfo?.personal_email
                  : staff?.personalEmail
              }
              onChange={handleChange}
            />
          </div>
        </div>

        {addProfile ? null : (
          <div className="row mb-2">
            <div className="col text-center">
              <input
                type="checkbox"
                name="isAllFieldRequired"
                checked={
                  editParam && !editParam?.isEdit
                    ? staff?.isAllFieldRequired
                    : editableDptStf[editParam?.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1
                    ? true
                    : false
                }
                onChange={handleChange}
                id="requiredField"
              />
              <label className="checkboxLabel" htmlFor="requiredField">
                Tick the checkbox if all field are required{" "}
              </label>
            </div>
          </div>
        )}

        <div className="row mb-2">
          <div className="col">
            <label className="floating">
              Country
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>{" "}
            </label>
            <select
              className="formSelect"
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.country
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.country ?? userInfo?.country
                  : ""
              }
              name="country"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Choose Country
              </option>
              {country && country.length > 0
                ? country.map((dpt, id) => {
                    return (
                      <option value={dpt.id} key={id}>
                        {dpt.country_name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col">
            <label className="floating">
              City
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>{" "}
            </label>
            <select
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editParam?.isEdit &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              className="formSelect"
              onChange={handleChange}
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.city
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.city ?? userInfo?.city
                  : ""
              }
              name="city"
            >
              <option value="" disabled selected>
                Choose City
              </option>

              {cities && cities.length > 0
                ? cities.map((dpt, id) => {
                    return (
                      <option value={dpt.id} key={id}>
                        {dpt.city_name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col position-relative">
            <label className="floating floatingNumberDiv">
              Personal Number
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (
                    editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]
                  )?.need_to_be_filled === 1
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              placeholder="Personal Number"
              name={"personalNumber"}
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam?.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam?.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.personal_number
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.personalNumber ?? userInfo?.personal_number
                  : ""
              }
              // onChange={()=>handlePhoneChange(e,'corporateNumber')}
              onChange={(e) => handlePhoneChange(e, "personalNumber")}
              defaultCountry="NP"
            />
            {staff?.personalNumber ||
            (editableDptStf &&
              editableDptStf[editParam?.selectedDepartment]?.staff[
                editParam?.selectedIndex
              ]?.personal_number) ||
            userInfo?.personal_number
              ? isValidPhoneNumber(
                  staff?.personalNumber ||
                    (editableDptStf &&
                      editableDptStf[editParam?.selectedDepartment]?.staff[
                        editParam?.selectedIndex
                      ]?.personal_number) ||
                    userInfo?.personal_number
                )
                ? undefined
                : (staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam?.selectedDepartment]?.staff[
                        editParam?.selectedIndex
                      ]?.need_to_be_filled)) && (
                    <small
                      style={{
                        color: "red",
                        marginTop: "-20px",
                        display: "block",
                      }}
                    >
                      Phone number is not valid
                    </small>
                  )
              : ""}
          </div>

          <div className="col position-relative">
            <label className="floating floatingNumberDiv">
              Corporate Number{" "}
              {/* <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span> */}
            </label>

            <PhoneInput
              international
              countryCallingCodeEditable={false}
              placeholder="Corporate Number "
              name="corporateNumber"
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.corporate_number
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.corporateNumber ?? userInfo?.corporate_number
                  : ""
              }
              onChange={(e) => handlePhoneChange(e, "corporateNumber")}
              defaultCountry="NP"
            />
            {staff?.corporateNumber ||
            (editableDptStf &&
              editableDptStf[editParam.selectedDepartment]?.staff[
                editParam.selectedIndex
              ]?.corporate_number)
              ? isValidPhoneNumber(
                  staff?.corporateNumber ??
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.corporate_number)
                )
                ? undefined
                : (staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam?.selectedDepartment]?.staff[
                        editParam?.selectedIndex
                      ]?.need_to_be_filled)) && (
                    <small
                      style={{
                        color: "red",
                        marginTop: "-20px",
                        display: "block",
                      }}
                    >
                      Phone number is not valid
                    </small>
                  )
              : ""}
          </div>
        </div>

        <div className="row">
          <div className="col text-start">
            <label className="floating">
              DOB{" "}
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              type="date"
              className="form-control"
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              name="d_o_b"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.d_o_b
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.d_o_b ?? userInfo?.d_o_b
                  : ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="floating">
              Weekly Hours{" "}
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              type="number"
              name="weeklyHours"
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              placeholder="Weekly Hours"
              // value={staff?.weeklyHours}
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.weekly_hours
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.weeklyHours ?? userInfo?.weekly_hours
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <label className="floating">
              Gender
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>{" "}
            </label>
            <select
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              className="formSelect"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.gender
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.gender ?? userInfo?.gender
                  : ""
              }
              name="gender"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Choose Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Both">Both</option>
            </select>
          </div>
          <div className="col">
            <label className="floating">
              Marital Status
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>{" "}
            </label>
            <select
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              className="formSelect"
              name="maritalStatus"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.marital_status
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.maritalStatus ?? userInfo?.marital_status
                  : ""
              }
              onChange={handleChange}
            >
              <option selected value="" disabled>
                Choose MartialStatus
              </option>
              <option value="married">Married</option>
              <option value="Not married">Unmarried</option>
            </select>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <label className="floating">
              Status
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>{" "}
            </label>
            <select
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              className="formSelect"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.status
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.status ?? userInfo?.status
                  : ""
              }
              name="status"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Choose Status
              </option>
              <option value="Current">Current</option>
              <option value="Left">Left</option>
              <option value="Resigned">Resigned</option>
              <option value="Terminated">Terminated</option>
            </select>
          </div>
        </div>

        <div className="row mb-2">
          {(!addProfile && !fileName?.citizenship) ||
          (addProfile && !userInfoFile?.ctz) ? (
            <div className="col">
              <label className="floating">
                Citizenship
                <span
                  className={`${
                    staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1)
                      ? "required"
                      : ""
                  }`}
                >
                  *
                </span>
              </label>
              <input
                type="file"
                name="citizenship"
                accept="image/*,.pdf"
                disabled={
                  staff?.isAllFieldRequired
                    ? false
                    : addProfile
                    ? false
                    : editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1
                    ? false
                    : true
                }
                onChange={handleChange}
              />
            </div>
          ) : (
            <>
              <div className="col justify-content-start mt-4">
                <Button
                  name="Remove Citizenship File"
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  disabled={
                    addProfile
                      ? null
                      : selectedStaffToBeEdited?.need_to_be_filled !== 1
                  }
                  onClick={(e) => handleRemoveFile(e, "citizenship")}
                />
              </div>
            </>
          )}

          {userInfoFile?.ctz && (
            <div className="col-4 mt-4">
              <Button
                name="View Citizenship file"
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalI(e, "ctzInfo");
                }}
              ></Button>
            </div>
          )}

          {staff?.citizenship && (
            <div className="col-4 mt-4">
              <Button
                name="View Citizenship file"
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModal(e, "ctz");
                }}
              ></Button>
            </div>
          )}

          {editParam?.isEdit && updatedFiles?.citizenship && (
            <div className="col-4 mt-4">
              <Button
                name="View Uploaded Citizenship file"
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalU(e, "ctz");
                }}
              ></Button>
            </div>
          )}

          {editParam?.isEdit &&
            updatedFiles?.citizenship === undefined &&
            fileName?.citizenship && (
              <div className="col-4 mt-4">
                <Button
                  name="View Citizenship file"
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  disabled={
                    addProfile
                      ? null
                      : selectedStaffToBeEdited?.need_to_be_filled !== 1
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalD(e, "ctz");
                  }}
                ></Button>
              </div>
            )}

          <div className="col-3">
            <label className="floating">
              Citizenship Number
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              name="citizenshipNo"
              type="text"
              placeholder="Citizenship No"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.citizenship_no
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.citizenshipNo ?? userInfo?.citizenship_no
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          {(!addProfile && !fileName?.panCard) ||
          (addProfile && !userInfoFile?.pan) ? (
            <div className="col">
              <label className="floating">
                PAN{" "}
                <span
                  className={`${
                    staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1)
                      ? "required"
                      : ""
                  }`}
                >
                  *
                </span>
              </label>
              <input
                type="file"
                name="panCard"
                accept="image/*,.pdf"
                disabled={
                  staff?.isAllFieldRequired
                    ? false
                    : addProfile
                    ? false
                    : editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1
                    ? false
                    : true
                }
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="col justify-content-start mt-4">
              <Button
                name="Remove PAN Card"
                onClick={(e) => handleRemoveFile(e, "panCard")}
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
              />
            </div>
          )}

          {userInfoFile?.pan && (
            <div className="col-4 mt-4">
              <Button
                name="View Pan Card "
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalI(e, "panInfo");
                }}
              ></Button>
            </div>
          )}

          {staff?.panCard && (
            <div className="col-4 mt-4">
              <Button
                name="View PAN Card"
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModal(e, "pan");
                }}
              ></Button>
            </div>
          )}
          {editParam?.isEdit && updatedFiles?.panCard && (
            <div className="col-4 mt-4">
              <Button
                name="View Uploaded PAN Card"
                status={
                  selectedStaffToBeEdited?.need_to_be_filled === 1 || addProfile
                    ? null
                    : "disabled"
                }
                disabled={
                  addProfile
                    ? null
                    : selectedStaffToBeEdited?.need_to_be_filled !== 1
                }
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalU(e, "pan");
                }}
              ></Button>
            </div>
          )}

          {editParam?.isEdit &&
            updatedFiles?.panCard === undefined &&
            fileName?.panCard && (
              <div className="col-4 mt-4">
                <Button
                  name="View PAN Card"
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  disabled={
                    addProfile
                      ? null
                      : selectedStaffToBeEdited?.need_to_be_filled !== 1
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalD(e, "pan");
                  }}
                ></Button>
              </div>
            )}

          <div className="col-3">
            <label className="floating">
              PAN Number
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              name="panNo"
              type="number"
              placeholder="PAN No"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.pan_no
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.panNo ?? userInfo?.pan_no
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col text-start">
            <label className="floating">
              Contract Start Date
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="date"
              className="form-control"
              name="contractStartDate"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.contract_start_date
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.contractStartDate ?? userInfo?.contract_start_date
                  : ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="col text-start">
            <label className="floating">
              Contract End Date
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="date"
              className="form-control"
              name="contractEndDate"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.contract_end_date
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.contractEndDate ?? userInfo?.contract_end_date
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 text-start">
            <label className="floating">
              Start Work Hour
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="time"
              className="form-control"
              name="startWorkHour"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.start_work_hour
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.startWorkHour ?? userInfo?.start_work_hour
                  : ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 text-start">
            <label className="floating">
              End Work Hour
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="time"
              className="form-control"
              name="endWorkHour"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.end_work_hour
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.endWorkHour ?? userInfo?.end_work_hour
                  : ""
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-6 text-start">
            <label className="floating">
              Temporary Address
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="input"
              className="form-control"
              name="temporary_address"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.temporary_address
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.temporary_address ?? userInfo?.temporary_address
                  : ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 text-start">
            <label className="floating">
              Permanent Address
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="text"
              className="form-control"
              name="permanent_address"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.permanent_address
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.permanent_address ?? userInfo?.permanent_address
                  : ""
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-6 text-start">
            <label className="floating">
              Blood Group
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>

            <select
              className="form-control"
              name="blood_group"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.blood_group
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.blood_group ?? userInfo?.blood_group
                  : ""
              }
              onChange={handleChange}
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
            >
              {bloodGroups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>

            {/* <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="text"
              className="form-control"
              name="blood_group"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.blood_group
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.blood_group ?? userInfo?.blood_group
                  : ""
              }
              onChange={handleChange}
            /> */}
          </div>

          <div className="col-md-6 text-start">
            <label className="floating">
              Emergency Contact's Name
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="text"
              className="form-control"
              name="emergency_name"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.emergency_name
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.emergency_name ?? userInfo?.emergency_name
                  : ""
              }
              onChange={handleChange}
            />
          </div>

          <div className="col-md-6 text-start position-relative">
            <label className="floating floatingNumberDiv">
              Emergency Contact Number{" "}
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              placeholder="Emergency Number"
              name="emergency_number"
              disabled={
                staff?.isAllFieldRequired ||
                addProfile ||
                (editableDptStf &&
                  editableDptStf[editParam.selectedDepartment]?.staff[
                    editParam.selectedIndex
                  ]?.need_to_be_filled === 1)
                  ? false
                  : true
              }
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.emergency_number
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.emergency_number ?? userInfo?.emergency_number
                  : ""
              }
              onChange={(e) => handlePhoneChange(e, "emergency_number")}
              defaultCountry="NP"
            />
            {staff?.emergency_number ||
            (editableDptStf &&
              editableDptStf[editParam.selectedDepartment]?.staff[
                editParam.selectedIndex
              ]?.emergency_number)
              ? isValidPhoneNumber(
                  staff?.emergency_number ??
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.emergency_number)
                )
                ? undefined
                : (staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam?.selectedDepartment]?.staff[
                        editParam?.selectedIndex
                      ]?.need_to_be_filled)) && (
                    <small
                      style={{
                        color: "red",
                        marginTop: "-20px",
                        display: "block",
                      }}
                    >
                      Phone number is not valid
                    </small>
                  )
              : ""}
          </div>
          <div className="col-md-6 text-start">
            <label className="floating">
              Relation
              <span
                className={`${
                  staff?.isAllFieldRequired ||
                  addProfile ||
                  (editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1)
                    ? "required"
                    : ""
                }`}
              >
                *
              </span>
            </label>
            <input
              disabled={
                staff?.isAllFieldRequired
                  ? false
                  : addProfile
                  ? false
                  : editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.need_to_be_filled === 1
                  ? false
                  : true
              }
              type="text"
              className="form-control"
              name="emergency_relation"
              value={
                editParam && editParam.isEdit
                  ? editableDptStf &&
                    editableDptStf[editParam.selectedDepartment]?.staff[
                      editParam.selectedIndex
                    ]?.emergency_relation
                  : staff?.isAllFieldRequired || addProfile
                  ? staff?.emergency_relation ?? userInfo?.emergency_relation
                  : ""
              }
              onChange={handleChange}
            />
          </div>
        </div>

        {addProfile ? null : (
          <div className="row mb-1">
            {!fileName?.digitalSignature ? (
              <div className={editParam?.isEdit ? "col" : "col-6"}>
                <label className="floating">
                  Signature{" "}
                  <span
                    className={`${
                      staff?.isAllFieldRequired ||
                      addProfile ||
                      (editableDptStf &&
                        editableDptStf[editParam.selectedDepartment]?.staff[
                          editParam.selectedIndex
                        ]?.need_to_be_filled === 1)
                        ? "required"
                        : ""
                    }`}
                  >
                    *
                  </span>
                </label>
                <input
                  type="file"
                  name="digitalSignature"
                  accept="image/*,.pdf"
                  disabled={
                    staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1)
                      ? false
                      : true
                  }
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="col-6 justify-content-start mt-4">
                <Button
                  disabled={
                    staff?.isAllFieldRequired ||
                    addProfile ||
                    (editableDptStf &&
                      editableDptStf[editParam.selectedDepartment]?.staff[
                        editParam.selectedIndex
                      ]?.need_to_be_filled === 1)
                      ? false
                      : true
                  }
                  name="Remove Signature "
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  // disabled={
                  //   selectedStaffToBeEdited?.need_to_be_filled !== 1 ||
                  //   !addProfile
                  // }
                  onClick={(e) => handleRemoveFile(e, "digitalSignature")}
                />
              </div>
            )}

            {staff?.digitalSignature && (
              <div className="col">
                <Button
                  name="View Signature"
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  disabled={
                    addProfile
                      ? null
                      : selectedStaffToBeEdited?.need_to_be_filled !== 1
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal(e, "sig");
                  }}
                ></Button>
              </div>
            )}

            {editParam?.isEdit && updatedFiles?.digitalSignature && (
              <div className="col-4 mt-4">
                <Button
                  name="View Uploaded Signature"
                  status={
                    selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                    addProfile
                      ? null
                      : "disabled"
                  }
                  disabled={
                    addProfile
                      ? null
                      : selectedStaffToBeEdited?.need_to_be_filled !== 1
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalU(e, "sig");
                  }}
                ></Button>
              </div>
            )}

            {editParam?.isEdit &&
              updatedFiles?.digitalSignature === undefined &&
              fileName?.digitalSignature && (
                <div className="col-4 mt-4">
                  <Button
                    name="View Signature"
                    status={
                      selectedStaffToBeEdited?.need_to_be_filled === 1 ||
                      addProfile
                        ? null
                        : "disabled"
                    }
                    disabled={
                      addProfile
                        ? null
                        : selectedStaffToBeEdited?.need_to_be_filled !== 1
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      toggleModalD(e, "sig");
                    }}
                  ></Button>
                </div>
              )}
          </div>
        )}
      </div>
      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={(e) => handleNext(e, editParam)}
      />

      <CommonModal
        modal={
          modalD.status === "ctz"
            ? modalD.ctzModal
            : modalD.status === "pan"
            ? modalD.panModal
            : modalD.status === "sig" && modalD.sigModal
        }
        modalHeader={
          modalD.status === "ctz"
            ? "Citizenship"
            : modalD.status === "pan"
            ? "PAN Card"
            : modalD.status === "sig" && "Digital Signature"
        }
        modalBody={
          modalD.status === "ctz" ? (
            <CustomFileViewer
              fileExtension={fileName?.citizenship?.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${fileName?.citizenship?.id}/${fileName?.citizenship?.file_name}`}
            />
          ) : modalD.status === "pan" ? (
            <CustomFileViewer
              fileExtension={fileName?.panCard?.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${fileName?.panCard?.id}/${fileName?.panCard?.file_name}`}
            />
          ) : (
            modalD.status === "sig" && (
              <CustomFileViewer
                fileExtension={fileName?.digitalSignature?.file_name
                  ?.split(".")
                  .pop()}
                filePath={`${fileUrl}/${fileName?.digitalSignature?.id}/${fileName?.digitalSignature?.file_name}`}
              />
            )
          )
        }
        size="xl"
        toggleModal={toggleModalD}
      />
      <CommonModal
        modal={
          modalU.status === "ctz"
            ? modalU.ctzModal
            : modalU.status === "pan"
            ? modalU.panModal
            : modalU.status === "sig" && modalU.sigModal
        }
        modalHeader={
          modalU.status === "ctz"
            ? "Citizenship"
            : modalU.status === "pan"
            ? "PAN Card"
            : modalU.status === "sig" && "Digital Signature"
        }
        modalBody={
          modalU.status === "ctz" ? (
            <CustomFileViewer
              fileExtension={updatedFiles?.citizenship?.name?.split(".").pop()}
              filePath={URL.createObjectURL(updatedFiles?.citizenship)}
            />
          ) : modalU.status === "pan" ? (
            <CustomFileViewer
              fileExtension={updatedFiles?.panCard?.name?.split(".").pop()}
              filePath={URL.createObjectURL(updatedFiles?.panCard)}
            />
          ) : (
            modalU.status === "sig" && (
              <CustomFileViewer
                fileExtension={updatedFiles?.digitalSignature?.name
                  ?.split(".")
                  .pop()}
                filePath={URL.createObjectURL(updatedFiles?.digitalSignature)}
              />
            )
          )
        }
        size="xl"
        toggleModal={toggleModalU}
      />

      <CommonModal
        modal={
          modal.status === "ctz"
            ? modal.ctzModal
            : modal.status === "pan"
            ? modal.panModal
            : modal.status === "sig"
            ? modal.sigModal
            : modal.status === "ctzInfo" && modal.ctzInfoModal
        }
        modalHeader={
          modal.status === "ctz" || modal.status === "ctzInfo"
            ? "Citizenship"
            : modal.status === "pan"
            ? "PAN Card"
            : modal.status === "sig" && "Digital Signature"
        }
        modalBody={
          modal.status === "ctz" ? (
            <CustomFileViewer
              fileExtension={staff?.citizenship?.name?.split(".").pop()}
              filePath={URL.createObjectURL(staff?.citizenship)}
            />
          ) : modal.status === "pan" ? (
            <CustomFileViewer
              fileExtension={staff?.panCard?.name?.split(".").pop()}
              filePath={URL.createObjectURL(staff?.panCard)}
            />
          ) : (
            modal.status === "sig" && (
              <CustomFileViewer
                fileExtension={staff?.digitalSignature?.name?.split(".").pop()}
                filePath={URL.createObjectURL(staff?.digitalSignature)}
              />
            )
          )
        }
        size="xl"
        toggleModal={toggleModal}
      />

      <CommonModal
        modal={
          modalI.status === "ctzInfo"
            ? modalI.ctzInfoModal
            : modalI.status === "panInfo"
            ? modalI.panInfoModal
            : modalI.status === "sigInfo" && modal.sigInfoModalI
        }
        modalHeader={
          modalI.status === "ctzInfo"
            ? "Citizenship"
            : modalI.status === "panInfo"
            ? "PAN Card"
            : modalI.status === "sigInfo" && "Digital Signature"
        }
        modalBody={
          modalI.status === "ctzInfo" ? (
            <CustomFileViewer
              fileExtension={userInfoFile?.ctz?.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${userInfoFile?.ctz?.id}/${userInfoFile?.ctz?.file_name}`}
            />
          ) : modalI.status === "panInfo" ? (
            <CustomFileViewer
              fileExtension={userInfoFile?.pan?.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${userInfoFile?.pan?.id}/${userInfoFile?.pan?.file_name}`}
            />
          ) : (
            modalI.status === "sigInfo" && (
              <CustomFileViewer
                fileExtension={userInfoFile?.sig?.file_name?.split(".").pop()}
                filePath={`${fileUrl}/${userInfoFile?.sig?.id}/${userInfoFile?.sig?.file_name}`}
              />
            )
          )
        }
        size="xl"
        toggleModal={toggleModalI}
      />
    </>
  );
};

export default StaffDetails;
