import React, { useState } from "react";
import { bytesToMegaBytes, fileUrl } from "../../../utils/Constants";
import swal from "sweetalert";
import Button from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import CustomFileViewer from "../../../components/common/CustomFileViewer";

const EduacationalDetails = (props) => {
  const {
    defaultEduacationDetails,
    setDefaultEduacationDetails,
    editParam,
    staff,
    addProfile,
    editableDptStf,
    setEditableDptStf,
    userInfo,
    handleNext,
    setUserInfo,
  } = props;

  const educationalDetReq = [
    {
      degree: null,
      program: null,
      board: null,
      enrolled_year: null,
      graduation_year: null,
      name_of_institution: null,
      obtained_marks: null,
      // media: null
    },
  ];

  const educationToBeEdited =
    editableDptStf &&
    editableDptStf[editParam?.selectedDepartment]?.staff[
      editParam?.selectedIndex
    ]?.education;

  const eduForProfile = userInfo?.education;

  const requiredEducationalDetails =
    editParam && editParam?.isEdit && !addProfile
      ? educationToBeEdited.length === 0
        ? educationalDetReq
        : educationToBeEdited
      : addProfile
      ? eduForProfile.length === 0
        ? educationalDetReq
        : eduForProfile
      : defaultEduacationDetails;

  const requiredEduactionalSetterFunc =
    editParam && editParam?.isEdit && !addProfile
      ? setEditableDptStf
      : addProfile
      ? setUserInfo
      : setDefaultEduacationDetails;

  const staffDetails =
    editParam?.isEdit &&
    editableDptStf[editParam.selectedDepartment].staff[editParam.selectedIndex];

  const [modal, setmodal] = useState({
    fileModal: false,
    fileToBeViewed: null,
    status: null,
  });

  const toggleModalFile = (e, ed) => {
    if (ed === undefined) {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: false,
        fileToBeViewed: null,
      }));
    } else {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: true,
        fileToBeViewed: ed?.media.length === 0 ? ed?.uploadedMedia : ed?.media,
      }));
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let cloneEd = [...requiredEducationalDetails];
    if (editParam?.isEdit || addProfile) {
      cloneEd.push({
        degree: "",
        program: "",
        board: "",
        enrolled_year: "",
        graduation_year: "",
        name_of_institution: "",
        obtained_marks: "",
        // media: ""
      });
    } else {
      cloneEd.push({
        degree: "",
        program: "",
        board: "",
        enrolledYear: "",
        graduationYear: null,
        nameOfInstitution: "",
        obtainedMarks: "",
        academics_docs: "",
      });
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].education = cloneEd;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredEduactionalSetterFunc(cloneEd, "edu");
    }
  };

  const handleRemoveCustomEduBtnClick = (e, index) => {
    let cloneEd = [...requiredEducationalDetails];
    cloneEd.splice(index, 1);
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].education = cloneEd;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredEduactionalSetterFunc(cloneEd, "edu");
    }
  };

  const handleCustomInputChange = (e, index) => {
    let { name, value } = e.target;
    let cloneEdu = [...requiredEducationalDetails];
    const fileName = ["academics_docs", "uploadedMedia"];
    if (!fileName.includes(name)) {
      if (
        (name === "graduationYear" || name === "graduation_year") &&
        value === ""
      ) {
        cloneEdu[index][name] = null;
      } else {
        cloneEdu[index][name] = value;
      }
    } else {
      let data = e.target.files;
      if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
        swal(
          "warning",
          "File size must be equal or less than 4 MB.",
          "warning"
        );
        return;
      }
      data && data[0] && (cloneEdu[index][name] = data[0]);
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].education = cloneEdu;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredEduactionalSetterFunc(cloneEdu, "edu");
    }
  };

  return (
    <>
      <h2 className="fs-title">Educational Details</h2>
      <h3 className="fs-subtitle">All fields are required </h3>

      <div className="container border" style={{ paddingTop: "20px" }}>
        {requiredEducationalDetails && requiredEducationalDetails.length > 0
          ? requiredEducationalDetails.map((ed, id) => {
              return (
                <React.Fragment key={id}>
                  <div className="row">
                    {id != 0 ? (
                      <div className="col text-end">
                        <Button
                          name="Remove"
                          className="mb-3"
                          onClick={(e) => handleRemoveCustomEduBtnClick(e, id)}
                        ></Button>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        Degree
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>

                      <input
                        type="text"
                        name="degree"
                        placeholder="Degree"
                        onChange={(e) => handleCustomInputChange(e, id)}
                        value={ed.degree}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Program
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="program"
                        placeholder="Program"
                        onChange={(e) => handleCustomInputChange(e, id)}
                        value={ed.program}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Board
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="board"
                        placeholder="Board"
                        value={ed.board}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-start">
                      <label className="floating">
                        Enrolled Year
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name={
                          editParam?.isEdit || addProfile
                            ? "enrolled_year"
                            : "enrolledYear"
                        }
                        value={ed.enrolledYear ?? ed.enrolled_year}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col text-start">
                      <label className="floating">
                        Graduation Year
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name={
                          editParam?.isEdit || addProfile
                            ? "graduation_year"
                            : "graduationYear"
                        }
                        value={ed.graduationYear ?? ed.graduation_year}
                        onChange={(e) => handleCustomInputChange(e, id)}
                        min={ed.enrolledYear ?? ed.enrolled_year}
                        disabled={ed.enrolledYear ? true : false}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        Name of Institution
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "name_of_institution"
                            : "nameOfInstitution"
                        }
                        placeholder="Name of Institution"
                        value={ed.nameOfInstitution ?? ed.name_of_institution}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Obtained Marks
                        {staff?.isAllFieldRequired ||
                        staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "obtained_marks"
                            : "obtainedMarks"
                        }
                        placeholder="Obtained Marks"
                        value={ed.obtainedMarks ?? ed.obtained_marks}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label className="floating">
                        File
                        <span>*</span>
                      </label>
                      <input
                        type="file"
                        name={
                          editParam?.isEdit || addProfile
                            ? "uploadedMedia"
                            : "academics_docs"
                        }
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>

                    {((ed?.media &&
                      (ed?.media?.length !== 0 ?? ed?.media !== "")) ||
                      (ed?.academics_docs && ed?.academics_docs !== "") ||
                      (ed?.uploadedMedia && ed?.uploadedMedia !== "")) && (
                      <div className="col-4 mt-4">
                        <Button
                          name="View Academic Doc file"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleModalFile(e, ed);
                          }}
                        ></Button>
                      </div>
                    )}
                  </div>
                  <p style={{ fontSize: "11px", textAlign: "left" }}>
                    <strong>Note : </strong>
                    If your Education degree is not completed than, Don't fill
                    Graduation Year field.
                  </p>
                  <hr />
                </React.Fragment>
              );
            })
          : null}
        <div className="row mb-3">
          <div className="col text-end">
            <Button name="Add Education" onClick={handleAdd}></Button>
          </div>
        </div>
      </div>

      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={props.handlePrevious}
      />
      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={(e) => handleNext(e, editParam)}
      />

      <CommonModal
        modal={modal.fileModal}
        modalHeader={"Education Doc"}
        modalBody={
          modal?.fileModal && (
            <CustomFileViewer
              fileExtension={
                Array.isArray(modal?.fileToBeViewed)
                  ? modal?.fileToBeViewed[0]?.file_name?.split(".").pop()
                  : modal?.fileToBeViewed?.name?.split(".").pop()
              }
              filePath={
                Array.isArray(modal?.fileToBeViewed)
                  ? `${fileUrl}/${modal?.fileToBeViewed[0]?.id}/${modal?.fileToBeViewed[0]?.file_name}`
                  : modal?.fileToBeViewed &&
                    URL.createObjectURL(modal?.fileToBeViewed)
              }
            />
          )
        }
        size="xl"
        toggleModal={toggleModalFile}
      />
    </>
  );
};

export default EduacationalDetails;
