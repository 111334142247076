import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import { fileUrl } from "../../utils/Constants";
import CommonModal from "../../components/common/Modal";
import CustomFileViewer from "../../components/common/CustomFileViewer";
import errorImage from "../../assets/images/user-image.png";
import moment from "moment";

const DepartmentStaffDetails = ({ dptStf, departmentName }) => {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [modalFile, setModalFile] = useState({
    modal: false,
    selected: null
  });
  const [modal, setModal] = useState({ modal: false, file: null });
  const [errImage, setErrImage] = useState(false);

  useEffect(() => {
    getDoc();
  }, []);

  const getDoc = () => {
    //find if doc available
    const eduDoc = dptStf?.media.find(
      uf => uf.collection_name === "academic_docs_files"
    );
    if (eduDoc) {
      const { id, file_name } = eduDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        edu: { id, file_name }
      }));
    }
    const ctzDoc = dptStf?.media.find(
      uf => uf.collection_name === "citizenship_files"
    );
    if (ctzDoc) {
      const { id, file_name } = ctzDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        ctz: { id, file_name }
      }));
    }

    const panDoc = dptStf?.media.find(
      uf => uf.collection_name === "pan_card_files"
    );
    if (panDoc) {
      const { id, file_name } = panDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        pan: { id, file_name }
      }));
    }
    const sigDoc = dptStf?.media.find(
      uf => uf.collection_name === "signature_files"
    );
    if (sigDoc) {
      const { id, file_name } = sigDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        sig: { id, file_name }
      }));
    }

    const expDoc = dptStf?.media.find(
      uf => uf.collection_name === "experience_docs_files"
    );
    if (expDoc) {
      const { id, file_name } = expDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        exp: { id, file_name }
      }));
    }
    const traDoc = dptStf?.media.find(
      uf => uf.collection_name === "training_docs_files"
    );
    if (traDoc) {
      const { id, file_name } = traDoc;
      setSelectedDoc(prevState => ({
        ...prevState,
        tra: { id, file_name }
      }));
    }
    //
  };

  const handleImageError = e => {
    setErrImage(true);
  };

  const toggleFileModal = status => {
    setModalFile(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      selected: status
    }));
  };

  const toggleModal = (e, ed) => {
    if (!ed) {
      setModal(prevState => ({
        ...prevState,
        modal: !prevState.modal,
        file: null
      }));
    } else {
      setModal(prevState => ({
        ...prevState,
        modal: !prevState.modal,
        file: ed?.media[0]
      }));
    }
  };

  return (
    <>
      <div className="container p-0 m-0">
        <div className="row m-0">
          {dptStf && (
            <>
              <div className="col-md-8">
                {/* <div className="pageTitle">Staff Information</div> */}
                <div className="pageTitle">
                  {dptStf.first_name}
                  {dptStf.middle_name ? ` ${dptStf.middle_name} ` : " "}
                  {dptStf.last_name}
                </div>
                <div className="table-responsive whiteContainer p-4 mt-0">
                  <table className="table table-sm table-custom">
                    <tbody>
                      {dptStf.status ? (
                        <tr>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <td>:</td>
                          <td>{dptStf.status}</td>
                        </tr>
                      ) : null}
                      {dptStf.d_o_b ? (
                        <tr>
                          <td>
                            <strong>Date of Birth</strong>
                          </td>
                          <td>:</td>
                          <td>{dptStf.d_o_b}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td>:</td>
                        <td>{dptStf.office_email}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Job Title</strong>
                        </td>
                        <td>:</td>
                        <td>{dptStf.job_title}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td>
                          {dptStf.department_staff_roles &&
                          dptStf.department_staff_roles.length > 1
                            ? dptStf.department_staff_roles.map((el, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    {el.name}
                                    {idx ===
                                    dptStf.department_staff_roles.length - 1
                                      ? ""
                                      : ", "}
                                  </React.Fragment>
                                );
                              })
                            : dptStf?.department_staff_roles[0]?.name}
                        </td>
                      </tr>
                      {dptStf.start_work_hour ? (
                        <tr>
                          <td>
                            <strong>Work Hours</strong>
                          </td>
                          <td>:</td>
                          <td>
                            {dptStf.start_work_hour} - {dptStf.end_work_hour} [
                            {dptStf.weekly_hours} hrs - weekly]
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td>
                          <strong>Personal Email</strong>
                        </td>
                        <td>:</td>
                        <td>{dptStf.personal_email}</td>
                      </tr>
                      {dptStf.personal_number ? (
                        <tr>
                          <td>
                            <strong>Personal Number</strong>
                          </td>
                          <td>:</td>
                          <td>{dptStf.personal_number}</td>
                        </tr>
                      ) : null}
                      {dptStf.gender ? (
                        <tr>
                          <td>
                            <strong>Gender</strong>
                          </td>
                          <td>:</td>
                          <td>{dptStf.gender}</td>
                        </tr>
                      ) : null}
                      {dptStf.marital_status ? (
                        <tr>
                          <td>
                            <strong>Marital Status</strong>
                          </td>
                          <td>:</td>
                          <td>{dptStf.marital_status}</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="col-md-4 pe-md-0">
            {dptStf?.media &&
              dptStf.media.map((logos, id) => {
                if (logos.collection_name === "profile_picture_files") {
                  return (
                    <React.Fragment key={id}>
                      <div className="userInfoLogo whiteContainer mt-0 p-4">
                        <img
                          alt="logo"
                          className="img-fluid"
                          key={logos.id}
                          src={
                            errImage
                              ? errorImage
                              : fileUrl + "/" + logos.id + "/" + logos.file_name
                          }
                          onError={handleImageError}
                        />
                      </div>
                      {/* <div className="text-center whiteContainer mt-2 p-3">
                        <h5 className="sectionTitle mb-0">Profile Image</h5>
                      </div> */}
                      {selectedDoc?.ctz && (
                        <div className="text-center whiteContainer mt-2 p-3">
                          <Button
                            name="View Citizenship Document "
                            onClick={() => toggleFileModal("ctz")}
                          ></Button>
                        </div>
                      )}
                      {selectedDoc?.pan && (
                        <div className="text-center whiteContainer mt-2 p-3">
                          <Button
                            name="View Pan Document "
                            onClick={() => toggleFileModal("pan")}
                          ></Button>
                        </div>
                      )}
                      {selectedDoc?.sig && (
                        <div className="text-center whiteContainer mt-2 p-3">
                          <Button
                            name="View Digital Signature  "
                            onClick={() => toggleFileModal("sig")}
                          ></Button>
                        </div>
                      )}
                      {dptStf.contract_start_date ? (
                        <div className="table-responsive whiteContainer p-4 mt-2">
                          <table className="table table-sm table-custom">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Contract Start Date</strong>
                                </td>
                                <td>:</td>
                                <td>
                                  {moment(dptStf.contract_start_date).format(
                                    "LL"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Contract End Date</strong>
                                </td>
                                <td>:</td>
                                <td>
                                  {moment(dptStf.contract_end_date).format(
                                    "LL"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                }
              })}
            {dptStf.contract_file ? (
              <div className="text-center whiteContainer mt-2 p-3">
                <Button
                  name="View Contract"
                  onClick={() => toggleFileModal("contract")}
                ></Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="container mt-2">
        {dptStf &&
        (dptStf?.education === undefined ||
          dptStf?.education?.length === 0 ||
          dptStf?.education === null) ? null : (
          <div>
            <div className="pageTitle">Educational Details</div>
            <div className="row">
              {dptStf?.education.map((ed, idx) => (
                <div
                  key={idx}
                  className={
                    dptStf.education.length > 1 ? "col-md-6" : "col-md-12"
                  }
                >
                  <div className="table-responsive whiteContainer p-4 mt-0">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          <td>{ed.degree ?? "Not available"}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Board</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.board ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Name of the Institution</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.name_of_institution ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Program</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.program ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Obtained Marks</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.obtained_marks ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Enrolled Year</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.enrolled_year ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Graduation Year</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.graduation_year ?? "Not available"}</td>
                        </tr>
                      </tbody>
                    </table>
                    {ed?.media.length !== 0 ? (
                      <Button
                        name="View Educational Documents "
                        onClick={e => toggleModal(e, ed)}
                      ></Button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="container mt-2">
        {dptStf &&
        (dptStf?.experience === undefined ||
          dptStf?.experience?.length === 0 ||
          dptStf?.experience === null) ? null : (
          <div>
            <div className="pageTitle">Experience Details</div>
            <div className="row">
              {dptStf?.experience.map((ed, idx) => (
                <div
                  key={idx}
                  className={
                    dptStf.experience.length > 1 ? "col-md-6" : "col-md-12"
                  }
                >
                  <div className="table-responsive whiteContainer p-4 mt-0">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          <td>{ed.job_title ?? "Title Not available"}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Job Level</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.job_level ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Duties</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.duties ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Nature of Organisation</strong>
                          </td>
                          <td>:</td>
                          <td>
                            {ed.nature_of_organisation ?? "Not available"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Organisation name</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.organisation_name ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Organisation Location</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.organisation_location ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Start Date</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.start_date ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>End Date</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.end_date ?? "Not available"}</td>
                        </tr>
                      </tbody>
                    </table>
                    {ed?.media.length !== 0 ? (
                      <Button
                        name="View Experience Documents "
                        onClick={e => toggleModal(e, ed)}
                      ></Button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="container mt-2">
        {dptStf &&
        (dptStf?.skill === undefined ||
          dptStf?.skill?.length === 0 ||
          dptStf?.skill === null) ? null : (
          <div>
            <div className="pageTitle">Skill Details</div>
            <div className="row">
              {dptStf?.skill.map((ed, idx) => (
                <div
                  key={idx}
                  className={dptStf.skill.length > 1 ? "col-md-6" : "col-md-12"}
                >
                  <div className="table-responsive whiteContainer p-4 mt-0">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          <td>{ed.skill_name ?? "Skill name not available"}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Skill level</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.skill_level ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Description</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.description ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Experience duration</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.experience_duration ?? "Not available"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="container mt-2">
        {dptStf &&
        (dptStf?.training === undefined ||
          dptStf?.training?.length === 0 ||
          dptStf?.training === null) ? null : (
          <div>
            <div className="pageTitle">Training Details</div>
            <div className="row">
              {dptStf?.training.map((ed, idx) => (
                <div
                  key={idx}
                  className={
                    dptStf.training.length > 1 ? "col-md-6" : "col-md-12"
                  }
                >
                  <div className="table-responsive whiteContainer p-4 mt-0">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          <td>
                            {ed.training_name ?? "Training name mot available"}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Training Institute</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.training_institute ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Duration</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.duration ?? "Not available"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Completed Date</strong>
                          </td>
                          <td>:</td>
                          <td>{ed.completed_date ?? "Not available"}</td>
                        </tr>
                      </tbody>
                    </table>
                    {ed?.media.length !== 0 ? (
                      <Button
                        name="View Training Documents "
                        onClick={e => toggleModal(e, ed)}
                      ></Button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {selectedDoc?.ctz && modalFile.selected === "ctz" && (
        <CommonModal
          modal={modalFile.modal}
          modalHeader="Citizenship Document"
          modalBody={
            <CustomFileViewer
              fileExtension={selectedDoc.ctz.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${selectedDoc.ctz.id}/${selectedDoc.ctz.file_name}`}
            />
          }
          size="xl"
          toggleModal={toggleFileModal}
        />
      )}
      {selectedDoc?.pan && modalFile.selected === "pan" && (
        <CommonModal
          modal={modalFile.modal}
          modalHeader="PAN Card"
          modalBody={
            <CustomFileViewer
              fileExtension={selectedDoc.pan.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${selectedDoc.pan.id}/${selectedDoc.pan.file_name}`}
            />
          }
          size="xl"
          toggleModal={toggleFileModal}
        />
      )}

      {modalFile.selected === "contract" ? (
        <CommonModal
          modal={modalFile.modal}
          modalHeader="Contract"
          modalBody={
            <CustomFileViewer
              fileExtension={dptStf.contract_file.media[0].file_name
                ?.split(".")
                .pop()}
              filePath={`${fileUrl}/${dptStf.contract_file.media[0].id}/${dptStf.contract_file.media[0].file_name}`}
            />
          }
          size="xl"
          toggleModal={toggleFileModal}
        />
      ) : null}

      {modal.modal && (
        <CommonModal
          modal={modal?.modal}
          modalHeader="File"
          modalBody={
            <CustomFileViewer
              fileExtension={modal?.file.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${modal?.file.id}/${modal?.file.file_name}`}
            />
          }
          size="xl"
          toggleModal={toggleModal}
        />
      )}

      {selectedDoc?.sig && modalFile.selected === "sig" && (
        <CommonModal
          modal={modalFile.modal}
          modalHeader="Digital Signature"
          modalBody={
            <CustomFileViewer
              fileExtension={selectedDoc.sig.file_name?.split(".").pop()}
              filePath={`${fileUrl}/${selectedDoc.sig.id}/${selectedDoc.sig.file_name}`}
            />
          }
          size="xl"
          toggleModal={toggleFileModal}
        />
      )}
    </>
  );
};

export default DepartmentStaffDetails;
