import React from "react";
import "../../assets/scss/btn.scss";

const Button = (props) => {
  const { style, className, name } = props;
  const dynamicClassName =
    name === "Edit"
      ? "flag-edit edit"
      : name === "Delete" || name === "Remove"
      ? "flag-delete delete"
      : name === "Submit"
      ? "flag-normal "
      : name === "Reset"
      ? "flag-delete delete"
      : "flag-all all";

  const btnDisable = props.status === "disabled" ? "disabledBtn" : "enableBtn";

  return (
    <>
      <button
        onClick={props.onClick}
        className={`custom-btn btn-9 mx-2 ${dynamicClassName} ${className} ${btnDisable} `}
        disabled={props.disabled ? props.disabled : false}
        onSubmit={props.onSubmit}
        style={style}
      >
        {props.name}
      </button>
    </>
  );
};

export default Button;
