import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import swal from "sweetalert";
import CommonModal from "../../components/common/Modal";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import GroupEmailForm from "./GroupEmailForm";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

export const GroupEmail = () => {
  const [modal, setModal] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [grpMail, setGrpMail] = useState(null);
  const [users, setUsers] = useState(null);
  const [groupConfigData, setGroupConfigData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getStaff();
    getGroupMailData();
  }, []);

  const getStaff = () => {
    axiosGet(
      URL.getAllDepartmentStaff,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          let finalValue = modules.map((el) => ({
            label: el.email,
            value: el.id,
          }));
          setUsers(finalValue);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };
  const getGroupMailData = () => {
    setIsFetching(true);
    axiosGet(
      URL.getGroupMail,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setIsFetching(false);
          setGroupConfigData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      grpMail === null ||
      grpMail.email === "" ||
      grpMail.email === null ||
      grpMail.users === undefined ||
      grpMail.users.length === 0
    ) {
      swal("Warning", "Both field are required", "warning");
      return;
    }
    let params = { ...grpMail, users: grpMail.users.map((el) => el.value) };
    setSubmitSpinner(true);
    axiosPost(
      URL.insertGroupMail,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          setGrpMail({ email: "", users: null });
          getGroupMailData();
          swal(
            "Success",
            "Group mail configuration added successfully",
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Group Email</div>
        {submitSpinner ? (
          <div
            className="container"
            style={{ padding: "1rem", height: "100%" }}
          >
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Submitting ...
              </div>
            </div>
          </div>
        ) : null}
        {checkRolePermissions("store", moduleName.groupEmail) ? (
          <Button name="Create Group EMail" onClick={toggleModal} />
        ) : null}

        <div className="table-responsive whiteContainer p-4">
          <table className="table table-sm table-custom">
            <thead>
              <tr>
                <th>SN.</th>
                <th>Group Mail</th>
                <th>Users</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {groupConfigData && groupConfigData.length > 0 ? (
                groupConfigData.map((gD, id) => (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td>{gD.email}</td>
                    {/* <td>{gD?.user?.map(el => el.email).join(",")}</td> */}
                    <td>
                      {gD?.user?.map((el, idx) => (
                        <React.Fragment key={idx}>
                          {el.email}
                          <br />
                        </React.Fragment>
                      ))}
                    </td>
                    {/* <td>
                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-success btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="Edit"
                          disabled
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>

                        <button
                          disabled
                          type="button"
                          rel="tooltip"
                          className="btn btn-danger btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                      </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    {isFetching ? (
                      <LoadingAnimation spinner />
                    ) : (
                      "No Group mail config have been created"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="lg"
        modalHeader="Group Mail Configuration"
        modalBody={
          <GroupEmailForm
            grpMail={grpMail}
            users={users}
            setGrpMail={setGrpMail}
            handleSubmit={handleSubmit}
          />
        }
      />
    </>
  );
};
