import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordEye from "../../../components/common/PasswordEye";
import { checkIsValidPassword } from "../../../utils/Validation";

const User = (props) => {
  const {
    user,
    setUser,
    handlePrevious,
    handleSubmit,
    editParam,
    companyData,
    setCompanyData,
  } = props;
  const [inputType, setInputType] = useState("password");
  const [capsOn, setCapsOn] = useState(false);
  const [errors, setErrors] = useState({
    isErrorPwd: false,
    isErrorCpwd: false,
    validationMsg: null,
  });

  const handleChange = (e) => {
    if (!editParam?.isEdit) {
      const { name, value } = e.target;
      setUser((prevState) => ({ ...prevState, [name]: value }));
      const fieldName = ["password", "confirmPassword"];
      if (name === "password") {
        if (value !== null && value !== "") {
          if (!checkIsValidPassword(value)) {
            setErrors({
              ...errors,
              isErrorPwd: true,
              validationMsg:
                "Password must contain Special characters, Numbers, Uppercase and Lowercase letter",
            });
          } else if (value.length < 8) {
            setErrors({
              ...errors,
              isErrorPwd: true,
              validationMsg: "Password must be 8 characters long",
            });
          } else {
            setErrors({
              ...errors,
              isErrorPwd: false,
              validationMsg: null,
            });
          }
        }
      }

      if (name === "confirmPassword") {
        if (value !== user?.password) {
          setErrors({
            ...errors,
            isErrorCpwd: true,
            validationMsg: "Password don't match",
          });
        } else {
          setErrors({
            ...errors,
            isErrorCpwd: false,
            validationMsg: null,
          });
        }
      }
    } else {
      const cloneCmpData = [...companyData];
    }
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
    if (e.getModifierState("CapsLock")) {
      setCapsOn(true);
    } else {
      setCapsOn(false);
    }
  };

  const handleInputTypeChange = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <>
      <h2 className="fs-title">User Details</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>
      <div className="container border">
        <div className="row mt-4">
          <div className="col">
            <label className="floating">
              First Name<span>*</span>{" "}
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={user?.firstName}
              // value={
              //   isEdit && isEdit ? companyData[selectedIndex].name : user?.firstName
              // }
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="floating">Middle Name </label>
            <input
              type="text"
              name="middleName"
              placeholder="Middle Name"
              value={user?.middleName}
              onChange={handleChange}
            />
          </div>

          <div className="col">
            <label className="floating">
              Last Name<span>*</span>{" "}
            </label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={user?.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              Email Address<span>*</span>{" "}
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={user?.email}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col" style={{ position: "relative" }}>
            <label className="floating">
              Password<span>*</span>{" "}
            </label>
            <input
              type={inputType}
              name="password"
              placeholder="Password"
              value={user?.password}
              onChange={handleChange}
              onKeyDown={handleKeydown}
            />
            {user?.password && errors?.isErrorPwd ? (
              <span style={{ color: "red" }}>{errors?.validationMsg}</span>
            ) : null}
            <span></span>
            <span>
              <FontAwesomeIcon
                icon={inputType === "password" ? faEye : faEyeSlash}
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "24px",
                }}
                onClick={handleInputTypeChange}
              />
            </span>
            {capsOn ? (
              <div>
                f<strong>Caps Lock is On</strong>
              </div>
            ) : null}
          </div>

          <PasswordEye
            name="confirmPassword"
            user={user}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            placeholder="Confirm Password"
            label="Confirm Password"
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      </div>

      <input
        type="button"
        name="previous"
        className=" action-button-previous"
        value="Previous"
        // value={staff?.next}
        onClick={handlePrevious}
      />
      <input
        type="button"
        name="submit"
        className="next action-button"
        value="Submit"
        // value={staff?.next}
        onClick={handleSubmit}
      />
    </>
  );
};

export default User;
