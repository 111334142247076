import CryptoJS from "crypto-js";
import { cryptoSecretKey, moduleName } from "./Constants";

export const checkRolePermissions = (action, moduleName) => {
  let encryptedData = localStorage.getItem("permissions");
  let checkData = `${action}|${moduleName}`;
  let checkIndex = -1;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (permissions.length > 0) {
      checkIndex = permissions.findIndex(
        (permission) => permission.name === checkData
      );
      if (checkIndex !== -1) return true;
      else return false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkModulePermission = (module) => {
  const permission = ["view", "viewOwn", "update", "delete", "store"];
  let obj = {};
  permission.forEach((prm) => {
    let check = moduleName;
    const assignedPrm = checkRolePermissions(prm, check[module]);
    obj = { ...obj, [prm]: assignedPrm };
  });

  return obj;
};

export const checkRoles = (roleName) => {
  let encryptedData = localStorage.getItem("roles");
  let checkIndex = -1;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var roles = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (roles.length > 0) {
      checkIndex = roles.findIndex((role) => role === roleName);
      if (checkIndex !== -1) return true;
      else return false;
    } else {
      return false;
    }
  }
  return false;
};
