import React, { useState, useEffect } from "react";
import "../../../assets/scss/departmentStaff.scss";
import StaffDetails from "./StaffDetails";
import EduacationalDetails from "./EduacationalDetails";
import Experience from "./Experience";
import Skill from "./Skill";
import Training from "./Training";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { URL } from "../../../utils/Constants";
import { axiosPost, axiosGet } from "../../../utils/AxiosApi";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import {
  checkIfEmpty,
  emailValidate,
  capitalizeFirstLetter,
  convertToCamelCase,
} from "../../../utils/Validation";
import { isValidPhoneNumber } from "react-phone-number-input";

/**
 * @author Sameer Pokharel
 */

const initialState = [
  {
    degree: null,
    program: null,
    board: null,
    enrolledYear: null,
    graduationYear: null,
    nameOfInstitution: null,
    obtainedMarks: null,
    academics_docs: null,
  },
];
const defaultExperience = [
  {
    organisationName: null,
    natureOfOrganisation: null,
    organisationLocation: null,
    startDate: null,
    endDate: null,
    jobTitle: null,
    jobLevel: null,
    duties: null,
    experience_docs: null,
  },
];
const defaultTraining = [
  {
    trainingName: null,
    trainingInstitute: null,
    duration: null,
    completedDate: null,
    training_docs: null,
  },
];
const defaultSkills = [
  {
    skillName: null,
    skillLevel: null,
    experienceDuration: null,
    description: null,
  },
];

const DepartmentStaffForm = ({
  toggleModal,
  editParam,
  departmentStaff,
  setDepartmentStaff,
  getDepartmentStaff,
  addProfile,
  userInfo,
  editableDptStf,
  setEditableDptStf,
  fileName,
  setFileName,
  userInfoFile,
  setterFunction,
  setUserInfo,
  setStatus,
  originalUserInfo,
  changeImageError,
}) => {
  const [step, setStep] = useState(1);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [staff, setStaff] = useState(null);
  const [defaultEduacationDetails, setDefaultEduacationDetails] = useState([
    ...initialState,
  ]);
  const [experience, setExperience] = useState([...defaultExperience]);
  const [trainings, setTrainings] = useState([...defaultTraining]);
  const [skills, setSkills] = useState([...defaultSkills]);
  const [roles, setRoles] = useState(null);
  const [defaultRoles, setDefaultRoles] = useState(null);
  const [department, setDepartment] = useState(null);
  const [country, setCountry] = useState(null);
  const [cities, setCities] = useState(null);
  const [updatedFiles, setUpdatedFiles] = useState(null);

  useEffect(() => {
    getDepartment();
    getCountry();
    getCities();
  }, []);

  useEffect(() => {
    let depRole;
    if (staff?.department) {
      depRole = department
        ?.find((dpt) => dpt?.id === Number(staff?.department))
        ?.roles?.map((dpt) => ({ label: dpt?.name, value: dpt?.id }));
    } else if (editParam?.isEdit) {
      const departmentId =
        editableDptStf[editParam.selectedDepartment]?.staff[
          editParam.selectedIndex
        ]?.department;
      depRole = department
        ?.find((dpt) => dpt?.id === Number(departmentId))
        ?.roles?.map((dpt) => ({ label: dpt?.name, value: dpt?.id }));
    }

    setDefaultRoles(depRole);
    // }, [
    //   department,
    //   staff?.department,
    //   staff?.department ||
    //     (editableDptStf &&
    //       editableDptStf[editParam.selectedDepartment]?.staff[
    //         editParam.selectedIndex
    //       ]?.department)
    // ]);
  }, [
    staff?.department ||
      (editableDptStf &&
        editableDptStf[editParam.selectedDepartment]?.staff[
          editParam.selectedIndex
        ]?.department),
    department,
  ]);

  const checkKeyAvailable = (value) => {
    const keyNeedToBeChecked = ["digitalSignature", "panCard", "citizenship"];
    let missingKey = [];
    for (const key of keyNeedToBeChecked) {
      if (!(key in value)) {
        missingKey.push({ isKey: false, key });
      }
    }
    return missingKey;
  };

  const checkFileNameAvailable = (value) => {
    let fileNameToBeChecked;
    let missingKey = [];
    if (value) {
      if (
        Object.keys(value).includes("citizenship") ||
        Object.keys(value).includes("panCard")
      ) {
        fileNameToBeChecked = ["citizenship", "panCard"];
      } else {
        fileNameToBeChecked = ["ctz", "pan"];
      }
      for (const key of fileNameToBeChecked) {
        if (!(key in value)) {
          missingKey.push({ isKey: false, key });
        }
      }
    }
    return missingKey;
  };

  const handleRemoveFile = (e, status) => {
    e.preventDefault();
    const cloneFileName = { ...fileName };
    if (fileName === undefined) {
      setStatus();
      setterFunction(status);
    } else {
      status in cloneFileName && delete cloneFileName[status];
      setFileName(cloneFileName);
    }
  };

  const getCountry = () => {
    axiosGet(
      URL.getCountry,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;

          setCountry(modules);
        }
      },
      (err) => {
        displayErrorAlert(err.message);
      }
    );
  };

  const getCities = () => {
    axiosGet(
      URL.getCities,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setCities(modules);
        }
      },
      (err) => {
        displayErrorAlert(err.message);
      }
    );
  };

  const getDepartment = () => {
    // axiosPost(
    //   URL.getDepartment,
    //   {
    //     pageNumber: null,
    //     limit: null
    //   },
    //   response => {
    //     if (response.data.success) {
    //       let modules = response.data.data.items;
    //       setDepartment(modules);
    //     }
    //   },
    //   err => {
    //     displayErrorAlert(err);
    //   }
    // );
    axiosGet(
      URL.getDepartment,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDepartment(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const addProfileDetails = () => {
    if (addProfile) {
      if (step === 1) {
        if (!staff) {
          // if (!userInfo?.city) {
          //   swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          //   return;
          // } else {
          const status = checkFileNameAvailable(userInfoFile);
          if (status.length !== 0) {
            let missingKey = "";
            status.forEach((cS) => {
              let finalKey = capitalizeFirstLetter(cS.key);
              missingKey = missingKey + finalKey + ", ";
            });

            if (missingKey !== "") {
              swal("Warning", `${missingKey} file missing`, "warning");
              return;
            }
          }
          // }

          setStep((prevState) => prevState + 1);
        } else {
          //destructure the required data
          let isEmpty = false;
          const { first_name, last_name, job_title, personal_email } = userInfo;
          //check whether these fields are empty or no t
          const dataToBeChecked = {
            first_name,
            last_name,
            job_title,
            personal_email,
          };
          let validator = checkIfEmpty(dataToBeChecked);
          if (validator?.isEmpty) {
            isEmpty = true;
            // let finalMsg = convertToSnakeCase(msg);
            let msg = convertToCamelCase(validator?.emptyKey);
            let finalMsg = capitalizeFirstLetter(msg);
            swal("Warning", `${finalMsg} Field is missing`, "warning");
            return;
          }

          // if (!userInfo?.city) {
          //   if (Object.keys(staff).length < 17) {
          //     swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          //     return;
          //   }
          // }

          // const {}=staff
          // debugger;
          let { middleName, ...reqStaff } = staff;
          let reqValue = checkIfEmpty(reqStaff);
          if (reqValue?.isEmpty) {
            isEmpty = true;
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field is missing`, "warning");
            return;
          }
          const { personalNumber, corporateNumber } = staff;
          //check Media

          //  if userInfoFile doesn't contains files(ctz and pan )
          // const fileName = ["ctz", "pan"];

          // const status = checkFileNameAvailable(staff);
          const status = checkFileNameAvailable(userInfoFile);
          // const status = checkIfEmpty(staff);n
          if (status.length !== 0) {
            let missingKey = "";
            status.forEach((cS) => {
              let checkKeyName;
              if (cS.key === "pan") checkKeyName = "panCard";
              if (cS.key === "ctz") checkKeyName = "citizenship";
              if (!(checkKeyName in staff)) {
                let finalKey = capitalizeFirstLetter(cS.key);
                missingKey = missingKey + finalKey + ", ";
              }
            });
            if (missingKey !== "") {
              swal("Warning", `${missingKey} file missing`, "warning");
              return;
            }
          }

          if (personalNumber && !isValidPhoneNumber(personalNumber)) {
            swal("Warning", `Invalid   ${"personal number"} `, "warning");
            return;
          }
          if (corporateNumber && !isValidPhoneNumber(corporateNumber)) {
            swal("Warning", `Invalid   ${"corporate number"} `, "warning");
            return;
          }
          !isEmpty && setStep((prevState) => prevState + 1);
        }
      }
      if (step === 2) {
        let reqValue;
        let isAllEmpty = [...userInfo.education].every(
          (dft) =>
            dft.degree === null &&
            dft.program === null &&
            dft.board === null &&
            dft.enrolled_year === null &&
            // dft.graduation_year === null &&
            dft.name_of_institution === null &&
            dft.obtained_marks === null &&
            dft.media.length === 0
        );
        // console.log(reqValue);
        // debugger;
        if (isAllEmpty) {
          setStep((prevState) => prevState + 1);
        } else {
          for (let edu of [...userInfo?.education]) {
            reqValue = checkIfEmpty(edu);
          }
          if (reqValue?.isEmpty) {
            if (reqValue.emptyKey === "graduation_year") {
              setStep((prevState) => prevState + 1);
              return;
            } else {
              let msg = capitalizeFirstLetter(reqValue?.emptyKey);
              swal("Warning", `${msg} field missing`, "warning");
              return;
            }
          } else {
            setStep((prevState) => prevState + 1);
          }
        }
      }
      if (step === 3) {
        let reqValue;
        let isAllEmpty = [...userInfo.experience].every(
          (dft) =>
            dft.organisation_name === null &&
            dft.nature_of_organisation === null &&
            dft.organisation_location === null &&
            dft.start_date === null &&
            dft.end_date === null &&
            dft.job_title === null &&
            dft.job_level === null &&
            dft.duties === null &&
            dft.media.length === 0
        );
        if (isAllEmpty) {
          setStep((prevState) => prevState + 1);
        } else {
          for (let exp of [...userInfo?.experience]) {
            reqValue = checkIfEmpty(exp);
          }
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field missing`, "warning");
            return;
          } else {
            setStep((prevState) => prevState + 1);
          }
        }
      }

      if (step === 4) {
        let reqValue;
        let isAllEmpty = [...userInfo.skill].every(
          (dft) =>
            dft.skill_name === null &&
            dft.skill_level === null &&
            dft.experience_duration === null &&
            dft.description === null
        );
        if (isAllEmpty) {
          setStep((prevState) => prevState + 1);
        } else {
          for (let skl of [...userInfo?.skill]) {
            reqValue = checkIfEmpty(skl);
          }
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field missing`, "warning");
            return;
          } else {
            setStep((prevState) => prevState + 1);
          }
        }
      }
    }
  };

  const handleNext = (e, editParam, roles) => {
    if (step === 1) {
      if (!editParam?.isEdit && !addProfile) {
        if (staff === null) {
          swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          return;
        }
        const { isAllFieldRequired, ...staffDetails } = staff;
        if (isAllFieldRequired === undefined || isAllFieldRequired === false) {
          const { middleName, roles, ...reqStaffDetails } = staff;
          const keyNeededToBeFiltered = [
            "firstName",
            "lastName",
            "department",
            "jobTitle",
            "officeEmail",
            "personalEmail",
            "staffImage",
            "personalNumber",
          ];
          const filteredStaff = Object.fromEntries(
            Object.entries(reqStaffDetails).filter(([key]) =>
              keyNeededToBeFiltered.includes(key)
            )
          );

          if (Object.keys(filteredStaff).length < 7) {
            swal("Warning", "Field with asterisk(*) are mandatory", "warning");
            return;
          }

          if (roles === undefined || roles.length === 0) {
            swal("Warning", "Role field is missing", "warning");
            return;
          }

          let reqValue = checkIfEmpty(filteredStaff);
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field is missing`, "warning");
            return;
          } else {
            if (
              staffDetails?.officeEmail !== null &&
              staffDetails?.officeEmail !== null &&
              staffDetails?.officeEmail !== undefined
            ) {
              if (!emailValidate(staffDetails?.officeEmail)) {
                swal("Warning", `Invalid office email `, "warning");
                return;
              }
            }

            if (
              staffDetails?.personalEmail !== null &&
              staffDetails?.personalEmail !== null &&
              staffDetails?.personalEmail !== undefined
            ) {
              if (!emailValidate(staffDetails?.personalEmail)) {
                swal("Warning", `Invalid personal email `, "warning");
                return;
              }
            }
            setStep((prevState) => prevState + 1);
          }
        } else {
          const { roles, middleName, ...reqStaffDetails } = staff;
          if (Object.keys(reqStaffDetails).length < 26) {
            swal("Warning", "Field with asterisk(*) are mandatory", "warning");
            return;
          }
          if (roles === undefined || roles.length === 0) {
            swal("Warning", "Role field is missing", "warning");
            return;
          }

          let reqValue = checkIfEmpty(reqStaffDetails);
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} Field is missing`, "warning");
            return;
          }

          const { personalNumber, corporateNumber } = staffDetails;
          if (personalNumber === undefined || corporateNumber === undefined) {
            swal(
              "Warning",
              `Missing   ${
                personalNumber === undefined
                  ? "personal number"
                  : corporateNumber === undefined && "corporate number"
              } `,
              "warning"
            );
            return;
          }

          if (
            !isValidPhoneNumber(personalNumber) ||
            !isValidPhoneNumber(corporateNumber)
          ) {
            swal(
              "Warning",
              `Invalid ${
                !isValidPhoneNumber(personalNumber)
                  ? "Personal Number"
                  : corporateNumber && "Corporate Number"
              } `,
              "warning"
            );
            return;
          }

          setStep((prevState) => prevState + 1);
        }
      } else if (editParam?.isEdit && !addProfile) {
        const cloneDptStf = [...editableDptStf];
        const { selectedIndex, selectedDepartment } = editParam;
        const {
          middle_name,
          company,
          education,
          media,
          training,
          experience,
          skill,
          updated_at,
          user_id,
          id,
          created_at,
          joining_date,
          need_to_be_filled,
          user,
          department_staff_roles,
          ...reqDepartmentStf
        } = cloneDptStf[selectedDepartment].staff[selectedIndex];
        let reqValue;
        let stfRoles = department_staff_roles.map((el) => ({
          label: el.name ?? el.label,
          value: el.id ?? el.value,
        }));

        if (need_to_be_filled === 0) {
          const {
            department,
            first_name,
            job_title,
            last_name,
            office_email,
            personal_email,
          } = reqDepartmentStf;

          let keyNeedToBeChecked = {
            department,
            first_name,
            job_title,
            last_name,
            office_email,
            personal_email,
          };

          if (stfRoles.length === 0) {
            swal("Warning", "Role field missing", "warning");
            return;
          }

          reqValue = checkIfEmpty(keyNeedToBeChecked);
          !reqValue && setStep((prevState) => prevState + 1);
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          const camelCaseMsg = msg.replace(/(_\w)/g, (k) => k[1].toUpperCase());
          swal("Warning", `${camelCaseMsg} field missing`, "warning");
        }

        if (need_to_be_filled === 1) {
          if (stfRoles.length === 0) {
            swal("Warning", "Role field missing", "warning");
            return;
          }

          //check media-updatedFiles ,fileName
          if (updatedFiles === null) {
            let checkStatus = checkKeyAvailable(fileName);
            if (checkStatus.length !== 0) {
              let missingKey = "";
              checkStatus.forEach((cS) => {
                let finalKey = capitalizeFirstLetter(cS.key);
                missingKey = missingKey + finalKey + ", ";
              });
              if (missingKey !== "") {
                swal("Warning", `${missingKey} file missing`, "warning");
                return;
              }
            }
          }

          if (updatedFiles !== null) {
            let checkStatus = checkKeyAvailable(updatedFiles);
            if (checkStatus.length !== 0) {
              let missingKey = "";
              checkStatus.forEach((cS) => {
                if (!(cS.key in fileName)) {
                  let finalKey = capitalizeFirstLetter(cS.key);
                  missingKey = missingKey + finalKey + ", ";
                }
              });
              if (missingKey !== "") {
                swal("Warning", `${missingKey} file missing`, "warning");
                return;
              }
            }
          }

          reqValue = checkIfEmpty(reqDepartmentStf);
          !reqValue && setStep((prevState) => prevState + 1);
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          const camelCaseMsg = msg.replace(/(_\w)/g, (k) => k[1].toUpperCase());
          swal("Warning", `${camelCaseMsg} field missing`, "warning");
        }
      } else {
        addProfileDetails();
      }
    }

    if (step === 2) {
      if (!editParam?.isEdit && !addProfile) {
        let reqValue, isAllEmpty;
        if (staff?.isAllFieldRequired) {
          isAllEmpty = [...defaultEduacationDetails].every(
            (dft) =>
              dft.degree === null &&
              dft.program === null &&
              dft.board === null &&
              dft.enrolledYear === null &&
              // dft.graduationYear === null &&
              dft.nameOfInstitution === null &&
              dft.obtainedMarks === null &&
              dft.academics_docs === null
          );
        } else if (addProfile) {
          isAllEmpty = [...defaultEduacationDetails].some(
            (dft) =>
              dft.degree === null ||
              dft.program === null ||
              dft.board === null ||
              dft.enrolledYear === null ||
              // dft.graduationYear === null ||
              dft.nameOfInstitution === null ||
              dft.obtainedMarks === null ||
              dft.academics_docs === null
          );
        } else {
          setStep((prevState) => prevState + 1);
          return;
        }

        if (isAllEmpty) {
          swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          return;
        }

        for (let edu of [...defaultEduacationDetails]) {
          reqValue = checkIfEmpty(edu);
        }

        if (reqValue?.isEmpty) {
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `${msg} field missing`, "warning");
          return;
        } else {
          setStep((prevState) => prevState + 1);
        }
      } else if (editParam?.isEdit && !addProfile) {
        const cloneDptStfEdu = [...editableDptStf];
        const reqEduToValidate =
          cloneDptStfEdu[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].education;

        if (
          cloneDptStfEdu[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].need_to_be_filled === 0
        ) {
          setStep((prevState) => prevState + 1);
        } else {
          let isEmpty = false;
          reqEduToValidate.forEach((edu) => {
            const {
              updated_at,
              created_at,
              id,
              staff_id,
              ...finalEduToValidate
            } = edu;
            let reqValue = checkIfEmpty(finalEduToValidate);
            if (reqValue?.isEmpty) {
              isEmpty = true;
              let msg = capitalizeFirstLetter(reqValue?.emptyKey);
              swal("Warning", `${msg} field missing`, "warning");
              return;
            }
          });
          !isEmpty && setStep((prevState) => prevState + 1);
        }
      } else {
        addProfileDetails();
      }
    }

    if (step === 3) {
      if (!editParam?.isEdit && !addProfile) {
        let reqValue, isAllNotEmpty, isEmpty;
        if (addProfile) {
          isEmpty = experience.some(
            (dft) =>
              dft.organisationName === null ||
              dft.natureOfOrganisation === null ||
              dft.organisationLocation === null ||
              dft.startDate === null ||
              dft.endDate === null ||
              dft.jobTitle === null ||
              dft.jobLevel === null ||
              dft.duties === null
          );
        } else {
          isAllNotEmpty = experience.some(
            (dft) =>
              dft.organisationName == "" ||
              dft.natureOfOrganisation === "" ||
              dft.organisationLocation === "" ||
              dft.startDate === "" ||
              dft.endDate === "" ||
              dft.jobTitle === "" ||
              dft.jobLevel === "" ||
              dft.duties === ""
          );
        }

        const checkStatus = addProfile ? isEmpty : isAllNotEmpty;

        if (checkStatus) {
          for (let exp of experience) {
            reqValue = checkIfEmpty(exp);
          }
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field missing`, "warning");
            return;
          } else {
            setStep((prevState) => prevState + 1);
          }
        } else {
          setStep((prevState) => prevState + 1);
        }
      } else if (editParam?.isEdit && !addProfile) {
        const cloneDptStfExp = [...editableDptStf];
        const reqExpToValidate =
          cloneDptStfExp[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].experience;

        if (
          cloneDptStfExp[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].need_to_be_filled === 0
        ) {
          setStep((prevState) => prevState + 1);
        } else {
          let isEmpty = false;
          reqExpToValidate.forEach((exp) => {
            const {
              updated_at,
              created_at,
              id,
              staff_id,
              ...finalExpToValidate
            } = exp;
            let reqValue = checkIfEmpty(finalExpToValidate);
            if (reqValue?.isEmpty) {
              isEmpty = true;
              let msg = capitalizeFirstLetter(reqValue?.emptyKey);
              swal("Warning", `${msg} field missing`, "warning");
              return;
            }
          });
          !isEmpty && setStep((prevState) => prevState + 1);
        }
      } else {
        addProfileDetails();
      }
    }

    if (step === 4) {
      if (!editParam?.isEdit && !addProfile) {
        let reqValue, isEmpty, isAllNotEmpty;
        if (addProfile) {
          isEmpty = skills.some(
            (dft) =>
              dft.skillName === null ||
              dft.skillLevel === null ||
              dft.experienceDuration === null ||
              dft.description === null
          );
        } else {
          isAllNotEmpty = skills.some(
            (dft) =>
              dft.skillName !== null ||
              dft.skillLevel !== null ||
              dft.experienceDuration !== null ||
              dft.description !== null
          );
        }

        const checkStatus = addProfile ? isEmpty : isAllNotEmpty;

        if (checkStatus) {
          for (let skl of skills) {
            reqValue = checkIfEmpty(skl);
          }
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field missing`, "warning");
            return;
          } else {
            setStep((prevState) => prevState + 1);
          }
        } else {
          setStep((prevState) => prevState + 1);
        }
      } else if (editParam?.isEdit && !addProfile) {
        const cloneDptStfTra = [...editableDptStf];
        let reqSklToValidate =
          cloneDptStfTra[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].skill;

        if (
          cloneDptStfTra[editParam.selectedDepartment].staff[
            editParam.selectedIndex
          ].need_to_be_filled === 0
        ) {
          setStep((prevState) => prevState + 1);
        } else {
          let isEmpty = false;
          reqSklToValidate.forEach((skl) => {
            const {
              updated_at,
              created_at,
              id,
              staff_id,
              ...finalSklToValidate
            } = skl;

            let reqValue = checkIfEmpty(finalSklToValidate);
            if (reqValue?.isEmpty) {
              isEmpty = true;
              let msg = capitalizeFirstLetter(reqValue?.emptyKey);
              swal("Warning", `${msg} field missing`, "warning");
              return;
            }
          });
          !isEmpty && setStep((prevState) => prevState + 1);
        }
      } else {
        addProfileDetails();
      }
    }
  };

  const handlePrevious = () => {
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    setDefaultEduacationDetails([
      {
        degree: null,
        program: null,
        board: null,
        enrolledYear: null,
        graduationYear: null,
        nameOfInstitution: null,
        obtainedMarks: null,
        academics_docs: null,
      },
    ]);
    setExperience([
      {
        organisationName: null,
        natureOfOrganisation: null,
        organisationLocation: null,
        startDate: null,
        endDate: null,
        jobTitle: null,
        jobLevel: null,
        duties: null,
        experience_docs: null,
      },
    ]);
    setTrainings([
      {
        trainingName: null,
        trainingInstitute: null,
        duration: null,
        completedDate: null,
        training_docs: null,
      },
    ]);
    setSkills([
      {
        skillName: null,
        skillLevel: null,
        experienceDuration: null,
        description: null,
      },
    ]);
  }, []);

  const updateDepartmentStaff = () => {
    const cloneDptStfExp = [...editableDptStf];
    const reqTraToValidate =
      cloneDptStfExp[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].training;

    let isError = false;
    if (
      cloneDptStfExp[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].need_to_be_filled === 0
    ) {
      isError = false;
    } else {
      reqTraToValidate.forEach((tra) => {
        const {
          updated_at,
          created_at,
          id,
          staff_id,
          media,
          ...finalTraToValidate
        } = tra;
        let reqValue = checkIfEmpty(finalTraToValidate);
        if (reqValue?.isEmpty) {
          isError = true;
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `${msg} field missing`, "warning");
          return;
        }
      });
    }

    if (!isError) {
      let formData = new FormData();
      const updatedStaffData =
        editableDptStf[editParam.selectedDepartment].staff[
          editParam.selectedIndex
        ];
      const originalStaffData =
        departmentStaff[editParam.selectedDepartment].staff[
          editParam.selectedIndex
        ];
      const {
        created_at,
        user_id,
        user,
        skill,
        country,
        city,
        updated_at,
        joining_date,
        company,
        need_to_be_filled,
        education,
        experience,
        training,
        department_staff_roles,
        media,
        ...finalStaff
      } = updatedStaffData;

      if (need_to_be_filled === 0) {
        //check if stafImage is Changed or not
        updatedFiles?.staffImage &&
          formData.append("profile_picture[]", updatedFiles?.staffImage);
        const keyNeededToBeFiltered = [
          "first_name",
          "last_name",
          "department",
          "job_title",
          "office_email",
          "personal_email",
          "id",
          "need_to_be_filled",
        ];
        const FilteredStaff = Object.fromEntries(
          Object.entries(finalStaff).filter(([key]) =>
            keyNeededToBeFiltered.includes(key)
          )
        );
        const finalFilteredStaff = {
          ...FilteredStaff,
          middle_name: finalStaff?.middle_name ? finalStaff?.middle_name : null,
        };
        const StaffDetails = JSON.stringify(finalFilteredStaff);
        formData.append("staff", StaffDetails);
      } else {
        updatedFiles?.staffImage &&
          formData.append("profile_picture[]", updatedFiles.staffImage);

        updatedFiles?.citizenship &&
          formData.append("citizenship[]", updatedFiles.citizenship);

        updatedFiles?.panCard &&
          formData.append("pan_card[]", updatedFiles.panCard);

        updatedFiles?.digitalSignature &&
          formData.append("signature[]", updatedFiles.digitalSignature);

        const staff = {
          ...finalStaff,
          need_to_be_filled,
          country: country.id,
          city: city.id,
        };

        const StaffDetails = JSON.stringify(staff);
        formData.append("staff", StaffDetails);
      }
      let finalRoles = [];
      department_staff_roles.forEach((rol) => {
        finalRoles.push({ id: rol.id ?? rol.value });
      });
      const role = JSON.stringify(finalRoles);
      formData.append("roles", role);

      let finalEducationDetails = [];
      let eduPictureDetails = [];
      let eduDeletedPictureDetails = [];
      education.forEach((edu, index) => {
        const {
          academics_docs,
          uploadedMedia,
          media,
          updated_at,
          created_at,
          staff_id,
          ...finalEducation
        } = edu;
        finalEducationDetails.push(finalEducation);
        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("academic_docs[]", uploadedMedia);
          eduPictureDetails.push({ index });
          if (media && media.length > 0) {
            eduDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
      });
      const eduacationalDetails = JSON.stringify(finalEducationDetails);
      formData.append("education", eduacationalDetails);

      if (eduPictureDetails.length > 0) {
        const eduPicDetail = JSON.stringify(eduPictureDetails);
        formData.append("academic_docs_details", eduPicDetail);
      }

      originalStaffData?.education.forEach((oed) => {
        if (education.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            eduDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (eduDeletedPictureDetails.length !== 0) {
        const eduDPicDetail = JSON.stringify(eduDeletedPictureDetails);
        formData.append("deleted_academic_media", eduDPicDetail);
      }

      let finalExperienceDetails = [];
      let expPictureDetails = [];
      let expDeletedPictureDetails = [];

      experience.forEach((exp, index) => {
        const {
          experience_docs,
          media,
          uploadedMedia,
          updated_at,
          created_at,
          staff_id,
          ...finalExperience
        } = exp;
        finalExperienceDetails.push(finalExperience);
        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("experience_docs[]", uploadedMedia);
          expPictureDetails.push({ index });
          if (media && media.length > 0) {
            expDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
      });
      const experienceDetails = JSON.stringify(finalExperienceDetails);
      formData.append("experiences", experienceDetails);
      if (expPictureDetails.length !== 0) {
        const expPicDetail = JSON.stringify(expPictureDetails);
        formData.append("experience_docs_details", expPicDetail);
      }
      originalStaffData?.experience.forEach((oed) => {
        if (experience.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            expDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (expDeletedPictureDetails.length !== 0) {
        const expPicDetail = JSON.stringify(expDeletedPictureDetails);
        formData.append("deleted_academic_media", expPicDetail);
      }

      let finalTrainingDetails = [];
      let traPictureDetails = [];
      let traDeletedPictureDetails = [];

      training.forEach((tra, index) => {
        const {
          training_docs,
          media,
          uploadedMedia,
          updated_at,
          created_at,
          staff_id,
          ...finalTraining
        } = tra;

        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("training_docs[]", uploadedMedia);
          traPictureDetails.push({ index });
          if (media && media.length > 0) {
            traDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
        finalTrainingDetails.push(finalTraining);
      });
      var trainingDetails = JSON.stringify(finalTrainingDetails);
      formData.append("trainings", trainingDetails);
      if (traPictureDetails.length !== 0) {
        var traPicDetail = JSON.stringify(traPictureDetails);
        formData.append("training_docs_details", traPicDetail);
      }
      originalStaffData?.training.forEach((oed) => {
        if (training.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            traDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (traDeletedPictureDetails.length !== 0) {
        const traDPicDetail = JSON.stringify(traDeletedPictureDetails);
        formData.append("deleted_academic_media", traDPicDetail);
      }

      let finalSkillDetails = [];
      skill.forEach((edu) => {
        const { updated_at, created_at, id, staff_id, ...finalSkill } = edu;
        finalSkillDetails.push(finalSkill);
      });
      var skillDetails = JSON.stringify(finalSkillDetails);
      formData.append("skills", skillDetails);

      setSubmitSpinner(true);
      axiosPost(
        URL.updateDepartmentStaff,
        formData,
        (res) => {
          if (res.data.success) {
            setSubmitSpinner(false);
            toggleModal();
            getDepartmentStaff();
            swal("Success", "Department staff updated successfully", "success");
          } else {
            setSubmitSpinner(false);
            displayErrorAlert(res);
          }
        },
        (err) => {
          displayErrorAlert(err);
          setSubmitSpinner(false);
        }
      );
    }
  };

  const handleSubmit = () => {
    if (step === 5) {
      if (!editParam?.isEdit) {
        let reqValue, isAllNotEmpty, isEmpty;
        if (addProfile) {
          isEmpty = trainings.some(
            (dft) =>
              dft.trainingName === null ||
              dft.trainingInstitute === null ||
              dft.duration === null ||
              dft.completedDate === null ||
              dft.training_docs === null
          );
        } else {
          isAllNotEmpty = trainings.some(
            (dft) =>
              dft.trainingName === "" ||
              dft.trainingInstitute === "" ||
              dft.duration === "" ||
              dft.completedDate === "" ||
              dft.training_docs === "null"
          );
        }

        const checkStatus = addProfile ? isEmpty : isAllNotEmpty;
        if (checkStatus) {
          for (let tra of trainings) {
            reqValue = checkIfEmpty(tra);
          }
          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `${msg} field missing`, "warning");
            return;
          } else {
            // setStep(prevState => prevState + 1);
          }
        } else {
          // setStep(prevState => prevState + 1);
        }
      }
    }

    let formData = new FormData();
    const {
      citizenship,
      panCard,
      staffImage,
      digitalSignature,
      isAllFieldRequired,
      roles,
      ...finalStaff
    } = staff;

    if (isAllFieldRequired) {
      formData.append("citizenship[]", citizenship);
      formData.append("pan_card[]", panCard);

      formData.append("profile_picture[]", staffImage);
      formData.append("signature[]", digitalSignature);

      var StaffDetails = JSON.stringify({
        ...finalStaff,
        needToBeFilled: isAllFieldRequired ? 1 : 0,
      });
      formData.append("staff", StaffDetails);
    } else {
      formData.append("profile_picture[]", staffImage);
      const keyNeededToBeFiltered = [
        "firstName",
        "lastName",
        "department",
        "jobTitle",
        "officeEmail",
        "personalEmail",
      ];
      const FilteredStaff = Object.fromEntries(
        Object.entries(finalStaff).filter(([key]) =>
          keyNeededToBeFiltered.includes(key)
        )
      );
      let finalFilteredStaff;
      finalFilteredStaff = {
        ...FilteredStaff,
        middleName: finalStaff?.middleName ? finalStaff.middleName : null,
        needToBeFilled: isAllFieldRequired ? 1 : 0,
        personal_number: null,
        corporate_number: null,
        d_o_b: null,
        weekly_hours: null,
        gender: null,
        marital_status: null,
        status: null,
        // citizenship: null,
        citizenship_no: null,
        // panCard: null,
        pan_no: null,
        contract_start_date: null,
        contract_end_date: null,
        start_work_hour: null,
        end_work_hour: null,
        city: null,
        country: null,
      };

      var StaffDetails = JSON.stringify(finalFilteredStaff);
      formData.append("staff", StaffDetails);
    }

    //filter the academic_Docs from educationalDetails arr
    let finalEducationDetails = [];
    let eduPictureDetails = [];
    [...defaultEduacationDetails].forEach((edu, id) => {
      const { academics_docs, ...finalEducation } = edu;
      finalEducationDetails.push(finalEducation);
      if (academics_docs !== null) {
        formData.append("academic_docs[]", academics_docs);
        eduPictureDetails.push({ index: id });
      }
    });
    var eduacationalDetails = JSON.stringify(finalEducationDetails);
    formData.append("education", eduacationalDetails);

    const eduPicDetail = JSON.stringify(eduPictureDetails);
    formData.append("academic_docs_details", eduPicDetail);

    //check if  experience is empty
    const isExpEmpty = experience.some(
      (dft) =>
        dft.organisationName === null ||
        dft.natureOfOrganisation === null ||
        dft.organisationLocation === null ||
        dft.startDate === null ||
        dft.endDate === null ||
        dft.jobTitle === null ||
        dft.jobLevel === null ||
        dft.duties === null ||
        dft?.experience_docs === null
    );
    let finalExperienceDetails = [];
    let expPictureDetails = [];

    experience.forEach((exp, id) => {
      const { experience_docs, ...finalExperience } = exp;
      if (!isExpEmpty) {
        formData.append("experience_docs[]", experience_docs);
        expPictureDetails.push({ index: id });
      }
      finalExperienceDetails.push(finalExperience);
    });

    const experienceDetails = JSON.stringify(finalExperienceDetails);
    formData.append("experiences", experienceDetails);

    const expPicDetail = JSON.stringify(expPictureDetails);
    formData.append("experience_docs_details", expPicDetail);

    const isTraEmpty = trainings.some(
      (dft) =>
        dft.trainingName === null ||
        dft.trainingInstitute === null ||
        dft.duration === null ||
        dft.completedDate === null ||
        dft.training_docs === null
    );
    let finalTrainingDetails = [];
    let traPictureDetails = [];
    trainings.forEach((tra, id) => {
      const { training_docs, ...finalTraning } = tra;
      if (!isTraEmpty) {
        formData.append("training_docs[]", training_docs);
        traPictureDetails.push({ index: id });
      }
      finalTrainingDetails.push(finalTraning);
    });

    var training = JSON.stringify(finalTrainingDetails);
    formData.append("trainings", training);

    var traPicDetail = JSON.stringify(traPictureDetails);
    formData.append("training_docs_details", traPicDetail);

    const isSklEmpty = skills.some(
      (dft) =>
        dft.skillName === "" ||
        dft.skillLevel === "" ||
        dft.experienceDuration === "" ||
        dft.description === ""
    );

    // if (!isSklEmpty) {
    let finalSkillDetails = [];
    skills.forEach((tra) => {
      finalSkillDetails.push(tra);
    });
    var skill = JSON.stringify(skills);
    formData.append("skills", skill);

    let finalRoles = [];
    roles.forEach((rol) => {
      const { value } = rol;
      finalRoles.push({ id: value });
    });
    var role = JSON.stringify(finalRoles);
    formData.append("roles", role);
    setSubmitSpinner(true);
    axiosPost(
      URL.insertDepartmentStaff,
      formData,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          getDepartmentStaff();
          // resetForm();
          swal("Success", "Department staff created successfully", "success");
        }
      },
      (err) => {
        displayErrorAlert(err);
        setSubmitSpinner(false);
      }
    );
  };

  const handleSubmitProfile = () => {
    const reqTraToValidate = userInfo.training;
    let isError = false;
    let isAllEmpty = [...userInfo.training].every(
      (dft) =>
        dft.training_name === null &&
        dft.training_institute === null &&
        dft.duration === null &&
        dft.completed_date === null &&
        dft.media.length === 0
    );
    if (!isAllEmpty) {
      reqTraToValidate.forEach((tra) => {
        const {
          updated_at,
          created_at,
          id,
          staff_id,
          media,
          ...finalTraToValidate
        } = tra;
        let reqValue = checkIfEmpty(finalTraToValidate);
        if (reqValue?.isEmpty) {
          isError = true;
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `${msg} field missing`, "warning");
          return;
        }
      });
    }

    if (!isError) {
      let formData = new FormData();
      if (!staff) {
        const {
          created_at,
          user_id,
          user,
          skill,
          country,
          city,
          updated_at,
          joining_date,
          company,
          need_to_be_filled,
          education,
          experience,
          training,
          department_staff_roles,
          media,
          ...finalStaff
        } = userInfo;
        let finalRoles = [];
        user.roles.forEach((rol) => {
          // const { value } = rol;
          finalRoles.push({ id: rol.id });
        });
        var role = JSON.stringify(finalRoles);
        formData.append("roles", role);
        const staffDetails = JSON.stringify({
          ...finalStaff,
          id: userInfo?.id,
          department: userInfo?.department,
        });
        formData.append("staff", staffDetails);
      } else {
        // look for the changes in staff Details data
        const {
          created_at,
          user_id,
          user,
          skill,
          country,
          city,
          updated_at,
          joining_date,
          company,
          need_to_be_filled,
          education,
          experience,
          training,
          department_staff_roles,
          media,
          ...finalStaff
        } = userInfo;
        let finalRoles = [];
        user.roles.forEach((rol) => {
          // const { value } = rol;
          finalRoles.push({ id: rol.id });
        });
        var role = JSON.stringify(finalRoles);
        formData.append("roles", role);

        const { citizenship, panCard, staffImage, ...reqStaff } = staff;
        citizenship && formData.append("citizenship[]", citizenship);
        panCard && formData.append("pan_card[]", panCard);
        staffImage && formData.append("profile_picture[]", staffImage);

        for (var key in reqStaff) {
          if (!!key.match(/^([a-z]+)(([A-Z]([a-z]+))+)$/)) {
            let finalKey = key
              .replace(/([a-z])([A-Z])/g, "$1_$2")
              .toLowerCase();
            delete Object.assign(reqStaff, { [finalKey]: reqStaff[key] })[key];
          }
        }

        const staffDetails = JSON.stringify({
          ...finalStaff,
          ...reqStaff,
          id: userInfo?.id,
          department: userInfo?.department,
        });
        formData.append("staff", staffDetails);
      }

      let finalEducationDetails = [];
      let eduPictureDetails = [];
      let eduDeletedPictureDetails = [];

      userInfo?.education.forEach((edu, index) => {
        const {
          academics_docs,
          uploadedMedia,
          media,
          updated_at,
          created_at,
          staff_id,
          ...finalEducation
        } = edu;
        finalEducationDetails.push(finalEducation);
        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("academic_docs[]", uploadedMedia);
          eduPictureDetails.push({ index });
          if (media && media.length > 0) {
            eduDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
      });
      const eduacationalDetails = JSON.stringify(finalEducationDetails);
      formData.append("education", eduacationalDetails);
      if (eduPictureDetails.length !== 0) {
        const eduPicDetail = JSON.stringify(eduPictureDetails);
        formData.append("academic_docs_details", eduPicDetail);
      }
      originalUserInfo?.education.forEach((oed) => {
        if (userInfo.education.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            eduDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (eduDeletedPictureDetails.length !== 0) {
        const eduDPicDetail = JSON.stringify(eduDeletedPictureDetails);
        formData.append("deleted_academic_media", eduDPicDetail);
      }

      let finalExperienceDetails = [];
      let expPictureDetails = [];
      let expDeletedPictureDetails = [];

      userInfo?.experience.forEach((exp, index) => {
        const {
          experience_docs,
          media,
          uploadedMedia,
          updated_at,
          created_at,
          staff_id,
          ...finalExperience
        } = exp;
        finalExperienceDetails.push(finalExperience);
        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("experience_docs[]", uploadedMedia);
          expPictureDetails.push({ index });
          if (media && media.length > 0) {
            expDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
      });
      const experienceDetails = JSON.stringify(finalExperienceDetails);
      formData.append("experiences", experienceDetails);

      if (expPictureDetails.length !== 0) {
        const expPicDetail = JSON.stringify(expPictureDetails);
        formData.append("experience_docs_details", expPicDetail);
      }

      originalUserInfo?.experience.forEach((oed) => {
        if (userInfo.experience.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            expDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (expDeletedPictureDetails.length !== 0) {
        const expPicDetail = JSON.stringify(expDeletedPictureDetails);
        formData.append("deleted_experience_media", expPicDetail);
      }

      let finalTrainingDetails = [];
      let traPictureDetails = [];
      let traDeletedPictureDetails = [];

      userInfo?.training.forEach((tra, index) => {
        const {
          training_docs,
          media,
          uploadedMedia,
          updated_at,
          created_at,
          staff_id,
          ...finalTraining
        } = tra;
        finalTrainingDetails.push(finalTraining);
        if (
          uploadedMedia &&
          !Array.isArray(uploadedMedia) &&
          uploadedMedia !== ""
        ) {
          formData.append("training_docs[]", uploadedMedia);
          traPictureDetails.push({ index });
          if (media && media.length > 0) {
            traDeletedPictureDetails.push({ id: media[0]?.id });
          }
        }
      });
      const training = JSON.stringify(finalTrainingDetails);
      formData.append("trainings", training);
      if (traPictureDetails.length !== 0) {
        var traPicDetail = JSON.stringify(traPictureDetails);
        formData.append("training_docs_details", traPicDetail);
      }

      originalUserInfo?.training.forEach((oed) => {
        if (userInfo.training.findIndex((ued) => ued.id === oed.id) === -1) {
          if (oed.media.length > 0) {
            traDeletedPictureDetails.push({ id: oed.media[0]?.id });
          }
        }
      });
      if (traDeletedPictureDetails.length !== 0) {
        const traDPicDetail = JSON.stringify(traDeletedPictureDetails);
        formData.append("deleted_training_media", traDPicDetail);
      }

      let finalSkillDetails = [];
      userInfo.skill.forEach((skl) => {
        const { updated_at, created_at, id, staff_id, ...finalSkill } = skl;
        finalSkillDetails.push(finalSkill);
      });
      const skill = JSON.stringify(finalSkillDetails);
      formData.append("skills", skill);
      setSubmitSpinner(true);
      axiosPost(
        URL.updateDepartmentStaff,
        formData,
        (res) => {
          if (res.data.success) {
            setSubmitSpinner(false);
            toggleModal();
            getDepartmentStaff();

            swal({
              title: "Success",
              text: "Profile updated successfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result) {
                changeImageError();
                // window.location.reload();
              }
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
          setSubmitSpinner(false);
        }
      );
    }
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <StaffDetails
            handleNext={handleNext}
            staff={staff}
            setStaff={setStaff}
            departmentStaff={departmentStaff}
            editParam={editParam}
            setDepartmentStaff={setDepartmentStaff}
            updatedFiles={updatedFiles}
            setUpdatedFiles={setUpdatedFiles}
            department={department}
            roles={roles}
            setRoles={setRoles}
            defaultRoles={defaultRoles}
            country={country}
            cities={cities}
            addProfile={addProfile}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            editableDptStf={editableDptStf}
            setEditableDptStf={setEditableDptStf}
            fileName={fileName}
            setFileName={setFileName}
            handleRemoveFile={handleRemoveFile}
            userInfoFile={userInfoFile}
          />
        );

      case 2:
        return (
          <EduacationalDetails
            staff={staff}
            handleNext={handleNext}
            defaultEduacationDetails={defaultEduacationDetails}
            setDefaultEduacationDetails={setDefaultEduacationDetails}
            handlePrevious={handlePrevious}
            departmentStaff={departmentStaff}
            editParam={editParam}
            setDepartmentStaff={setDepartmentStaff}
            updatedFiles={updatedFiles}
            setUpdatedFiles={setUpdatedFiles}
            addProfile={addProfile}
            editableDptStf={editableDptStf}
            setEditableDptStf={setEditableDptStf}
            fileName={fileName}
            handleRemoveFile={handleRemoveFile}
            setFileName={setFileName}
            userInfoFile={userInfoFile}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        );

      case 3:
        return (
          <Experience
            handleNext={handleNext}
            experience={experience}
            setExperience={setExperience}
            handlePrevious={handlePrevious}
            editParam={editParam}
            departmentStaff={departmentStaff}
            setDepartmentStaff={setDepartmentStaff}
            updatedFiles={updatedFiles}
            setUpdatedFiles={setUpdatedFiles}
            addProfile={addProfile}
            editableDptStf={editableDptStf}
            fileName={fileName}
            setEditableDptStf={setEditableDptStf}
            handleRemoveFile={handleRemoveFile}
            userInfoFile={userInfoFile}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        );
      case 4:
        return (
          <Skill
            handleNext={handleNext}
            skills={skills}
            setSkills={setSkills}
            handlePrevious={handlePrevious}
            editParam={editParam}
            departmentStaff={departmentStaff}
            setDepartmentStaff={setDepartmentStaff}
            addProfile={addProfile}
            editableDptStf={editableDptStf}
            setEditableDptStf={setEditableDptStf}
            fileName={fileName}
            userInfoFile={userInfoFile}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        );
      case 5:
        return (
          <Training
            handleNext={handleNext}
            trainings={trainings}
            setTrainings={setTrainings}
            handlePrevious={handlePrevious}
            editParam={editParam}
            departmentStaff={departmentStaff}
            setDepartmentStaff={setDepartmentStaff}
            updatedFiles={updatedFiles}
            setUpdatedFiles={setUpdatedFiles}
            handleSubmit={handleSubmit}
            addProfile={addProfile}
            handleSubmitProfile={handleSubmitProfile}
            fileName={fileName}
            editableDptStf={editableDptStf}
            setEditableDptStf={setEditableDptStf}
            handleRemoveFile={handleRemoveFile}
            userInfoFile={userInfoFile}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            updateDepartmentStaff={updateDepartmentStaff}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="container" style={{ padding: "1rem", height: "100vh" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-md-offset-3">
              <form id="msform">
                <ul id="progressbar">
                  <li
                    className={`${step === 1 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(1);PF
                    // }}
                  >
                    Staff Details
                  </li>
                  <li
                    className={`${step === 2 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(2);
                    // }}
                  >
                    Education
                  </li>
                  <li
                    className={`${step === 3 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(3);
                    // }}
                  >
                    Experience
                  </li>
                  <li
                    className={`${step === 4 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(4);
                    // }}
                  >
                    Skills
                  </li>

                  <li
                    className={`${step === 5 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(5);
                    // }}
                  >
                    Trainings
                  </li>
                </ul>
                {/* /* Switch the components as rerquired  */}
                <fieldset>{getComponent()}</fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentStaffForm;
