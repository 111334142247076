import React, { useState, useEffect, useCallback } from "react";
import CommonModal from "../../components/common/Modal";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import DepartmentList from "./DepartmentList";
import "../../assets/scss/departmentStaff.scss";
import Select from "react-select";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkIfEmpty } from "../../utils/Validation";
import DepartmentView from "./DepartmentView";
import {
  checkRolePermissions,
  checkModulePermission,
} from "../../utils/PermissionChecker";
import Pagination from "../../utils/Pagination";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const Department = (props) => {
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [department, setDepartment] = useState(null);
  const [permission, setPermission] = useState(null);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [isViewDepartment, setIsViewDepartment] = useState({
    isView: false,
    selectedDepartment: null,
  });
  const [storePermission, setStorePermission] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(6);

  const { isView, selectedDepartment } = isViewDepartment;

  useEffect(() => {
    // checkPermission();
    let value = checkModulePermission("department");
    if (value?.view) {
      getDepartment();
    }
    let store = checkRolePermissions("store", moduleName.department);
    setStorePermission(store);
    setAssignedPermission(value);
    getAllPermission();
  }, []);

  const getDepartment = () => {
    setIsLoading(true);
    // axiosPost(
    //   URL.getDepartment,
    //   {
    //     pageNumber,
    //     limit: 4
    //   },
    //   response => {
    //     setIsLoading(false);
    //     if (response.data.success) {
    //       let modules = response.data.data.items;
    //       setDepartment(modules);
    //     }
    //   },
    //   err => {
    //     setIsLoading(false);
    //     displayErrorAlert(err);
    //   }
    // );
    axiosGet(
      URL.getDepartment,

      (response) => {
        setIsLoading(false);
        if (response.data.success) {
          let modules = response.data.data.items;
          modules = modules.sort();
          setDepartment(modules);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    getDepartment();
  }, [pageNumber]);

  const getAllPermission = () => {
    axiosGet(
      URL.getAllPermissions,
      (response) => {
        if (response.data.success) {
          const p = response.data.data.values;
          setPermission(p);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const toggleModal = () => {
    if (modal && isViewDepartment.isView) {
      setIsViewDepartment((prevState) => ({ ...prevState, isView: false }));
    }
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartmentDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePermissionChange = (selectedPrm) => {
    setSelectedPermission(selectedPrm);
  };

  const resetForm = () => {
    setDepartmentDetails(null);
    showDptName();
    setSelectedPermission(null);
  };
  useEffect(() => {
    if (modal) {
      setDepartmentDetails(null);
      showDptName();
      setSelectedPermission(null);
    }
  }, [modal]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!departmentDetails) {
      swal("Error", "Department details reqiured", "error");
      return;
    }

    if (Object.keys(departmentDetails).length < 2) {
      swal("Error", "Department name or role field is missing", "error");
      return;
    }

    let reqValue = checkIfEmpty(departmentDetails);
    if (reqValue?.isEmpty) {
      swal(
        "Error",
        `${reqValue?.emptyKey.toLocaleUpperCase()} field is missing`,
        "error"
      );
      return;
    }
    if (selectedPermission === null || selectedPermission.length === 0) {
      swal("Error", `Permission field is missing`, "error");
      return;
    }

    let params = {
      name: departmentDetails.name,
      role: departmentDetails.name + " " + departmentDetails.role,
      permissions: selectedPermission.map((sPrm, id) => ({
        permission_id: sPrm.value,
      })),
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.insertDepartment,
      params,
      (response) => {
        if (response && response.status === 200) {
          toggleModal();
          setSubmitSpinner(false);
          resetForm();
          getDepartment();
          swal("Success", "Department added successfully", "success");
        }
      },
      (err) => {
        setSubmitSpinner(false);
        swal(err.response.statusText, err.response.data.message);
        return;
      }
    );
  };

  const handleViewDepartment = (e, dptId) => {
    const dptTobeViewed = department[dptId];
    setIsViewDepartment((prevState) => ({
      ...prevState,
      isView: true,
      selectedDepartment: dptTobeViewed,
    }));
    toggleModal();
  };

  const showDptName = () => {
    //check dpt name
    if (
      departmentDetails === null ||
      (departmentDetails.name === "" &&
        (departmentDetails.name === undefined ||
          departmentDetails.name === null))
    ) {
      return "Department";
    } else if (departmentDetails?.name === "") {
      return "Department";
    } else {
      return departmentDetails?.name;
    }
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleNextPage = () => {
    let nextPage = pageNumber + 1;
    if (nextPage > Math.ceil(totalCount / 4)) return;
    else {
      setPageNumber(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber !== 1) setPageNumber((prevState) => prevState - 1);
  };

  const formattedPermissions = permission?.map(item => {
    return {
      value: item.name,
      label: item.name,
    };
  });

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Departments</div>
      </div>
      <div className="container-fluid m-0 p-0" style={{ height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}
        {storePermission ? (
          <div>
            <Button name="Add Department" onClick={toggleModal} />
          </div>
        ) : null}

        <DepartmentList
          department={department}
          handleView={handleViewDepartment}
          isLoading={isLoading}
          storePermission={storePermission}
          getDepartment={getDepartment}
          permission={permission}
        />
      </div>
      {/* {!isLoading && (
        <Pagination
          itemsCount={totalCount}
          pageSize={4}
          currentPage={pageNumber}
          onPageChange={handlePageChange}
          nextPage={handleNextPage}
          previousPage={handlePreviousPage}
        />
      )} */}
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size={isView && isView ? "xl" : "lg"}
        modalHeader={isView && isView ? "Add Role" : "Add a Department"}
        modalBody={
          isView && isView ? (
            <DepartmentView
              isViewDepartment={isViewDepartment}
              permission={permission}
              showDptName={showDptName}
              getDepartment={getDepartment}
              toggleModal={toggleModal}
              formattedPermissions={formattedPermissions}
            />
          ) : (
            <>
              <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                  <div className="col">
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Department Name"
                        name="name"
                        onChange={handleChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon3">
                          Department
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                          {showDptName()}
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="basic-url"
                        name="role"
                        aria-describedby="basic-addon3"
                        onChange={handleChange}
                        placeholder="Role"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <Select
                      classNamePrefix="select"
                      menuPosition={"fixed"}
                      placeholder="Permission"
                      isMulti={true}
                      isClearable={true}
                      value={selectedPermission}
                      options={formattedPermissions}
                      onChange={handlePermissionChange}
                      closeMenuOnSelect={false}
                      closeMenuOnScroll={false}
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <Button name="Submit" type="submit" />
                </div>
              </form>
            </>
          )
        }
      />
    </>
  );
};

// const mapStateToProps = (state, props) => ({
//   data: state.data,
// });

// const mapActionToProps = {
//   fetchAPI,
// };

export default Department;
