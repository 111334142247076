import React, { useState, useEffect } from "react";
import {
  checkModulePermission,
  checkRolePermissions
} from "../../utils/PermissionChecker";
import { moduleName, URL } from "../../utils/Constants";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import moment from "moment";
import swal from "sweetalert";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getElementsByTagName } from "domutils";

const OverTimeCardForm = ({
  setSubmitSpinner,
  fetchOverTimeCard,
  toggleModal
}) => {
  const [formData, setFormData] = useState({
    ot_date: "",
    time_from: "",
    time_to: "",
    ot_work_desc: null
  });

  const [allUSers, setAllUSers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleUserSelect = data => {
    setSelectedUsers(data);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitSpinner(true);

    let userIDs = selectedUsers.map(su => {
      return su.id;
    });

    const params = { ...formData, user_id: userIDs };
    if (
      params.user_id.length === 0 ||
      formData.ot_date === "" ||
      formData.time_to === "" ||
      formData.time_from === "" ||
      formData.ot_work_desc === ""
    ) {
      swal("Error", "Fill all the required fields");
      setSubmitSpinner(false);
    } else {
      axiosPost(
        URL.storeOverTimeCard,
        params,
        response => {
          if (response.status === 200) {
            toggleModal();
            fetchOverTimeCard();
            setFormData(null);
            swal("Success", "OverTime Card requested succesfully", "success");
            setSubmitSpinner(false);
          }
        },
        err => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
        }
      );
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = () => {
    axiosGet(URL.getOverTimeUsers, response => {
      // let newArr = [];
      if (response.status === 200) {
        let responseData = response.data;
        responseData?.forEach(element => {
          element.label =
            element.first_name +
            " " +
            element.last_name +
            " [" +
            element.email +
            "]";
          element.value = element.id;
        });
        // let incommingData = response.data?.department;
        // incommingData?.forEach((incd) => {
        //   newArr = [...newArr, ...incd.staff];
        // });
        // newArr?.forEach((element) => {
        //   element.label = element.first_name + " " + element.last_name;
        //   element.value = element.id;
        // });

        setAllUSers(responseData);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} id="msform">
      <div className="row mb-2">
        <div className="col text-start">
          {/* <label className="floating staff-select ">
            Staffs <span className="required ">*</span>
          </label> */}
          <Select
            className="form-control pb-3"
            closeMenuOnSelect={false}
            classNamePrefix="select"
            menuPosition={"fixed"}
            placeholder="Staffs"
            isMulti={true}
            isClearable={true}
            value={selectedUsers}
            options={allUSers}
            onChange={e => handleUserSelect(e)}
          />
        </div>

        <div className="col text-start">
          <label className="floating">
            Date<span className="required"> *</span>
          </label>
          <input
            type="date"
            name="ot_date"
            className="form-control"
            onChange={handleChange}
            value={formData?.ot_date}
            min={new Date().toLocaleDateString("en-ca")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col text-start">
          <label className="floating">
            Time From<span className="required"> *</span>
          </label>
          <input
            type="time"
            name="time_from"
            className="form-control"
            onChange={handleChange}
            value={formData?.time_from}
          />
        </div>
        <div className="col text-start">
          <label className="floating">
            Time To<span className="required"> *</span>
          </label>
          <input
            type="time"
            name="time_to"
            min={formData?.time_from}
            className="form-control"
            onChange={handleChange}
            value={formData?.time_to}
          />
        </div>
      </div>

      <div className="row">
        <div className="col  editor">
          <label className="floating">
            Description<span className="required"> *</span>
          </label>
          {/* <textarea
            name="ot_work_desc"
            value={formData?.ot_work_desc}
            onChange={handleChange}
            className="form-control"
            type="text"
          /> */}
          <CKEditor
            // data={formData?.ot_work_desc}
            editor={ClassicEditor}
            name="ot_work_desc"
            onChange={(event, editor) => {
              let data = editor?.getData();
              setFormData(prev => ({ ...prev, ot_work_desc: data }));
            }}
          />
        </div>
      </div>
      <div style={{ float: "right", marginTop: "0.5rem" }}>
        <Button name={"Submit"} type="submit" />
      </div>
    </form>
  );
};
const OverTimeCardList = ({
  overTimeCardData,
  isFetching,
  handleUpdateActualTime
}) => {
  return (
    <>
      <div className="row">
        <div className="mt-3">
          {/* <div className="pageTitle">Overtime created by me</div> */}
          <h3 className="sectionTitle mb-0">Overtimes assigned to me</h3>
        </div>
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4 mt-0">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN.</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Time From</th>
                  <th className="text-center">Time To</th>
                  <th className="text-center">Duration(Hours)</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Overtimers</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Approved By</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {overTimeCardData &&
                overTimeCardData.belongs_to_overtime.length > 0 ? (
                  overTimeCardData.belongs_to_overtime.map((gP, id) => (
                    <tr key={id}>
                      <td className="text-center">{id + 1}</td>
                      <td className="text-center">{gP.ot_date}</td>
                      <td className="text-center">
                        {moment(gP.time_from, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {moment(gP.time_to, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {`0${(gP.duration / 60) ^ 0}`.slice(-2) +
                          ":" +
                          ("0" + (gP.duration % 60)).slice(-2)}
                      </td>
                      <td className="text-center">{gP.ot_work_desc}</td>
                      <td className="text-center">
                        {gP.users.map((user, idx) => (
                          <React.Fragment key={idx}>
                            <span key={idx}>
                              {user.first_name} {user.last_name}
                            </span>
                            <br></br>
                          </React.Fragment>
                        ))}
                      </td>
                      <td className="text-center">{gP.approval_status}</td>
                      <td className="text-center">
                        {gP?.approve_by?.first_name ?? "-"}
                      </td>
                      <td>
                        <Button
                          name="Update Time"
                          onClick={() => handleUpdateActualTime(gP)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={9}>
                      {isFetching ? (
                        <div className="text-center">
                          <Spinner color="whiteSmoke" size="sm" />
                        </div>
                      ) : (
                        "No OverTime Card  found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="mt-3">
          {/* <div className="pageTitle">Overtime created by me</div> */}
          <h3 className="sectionTitle mb-0">Overtimes created by Me</h3>
        </div>
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4 mt-0">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN.</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Time From</th>
                  <th className="text-center">Time To</th>
                  <th className="text-center">Duration(Hours)</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Overtimers</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Approved By</th>
                </tr>
              </thead>
              <tbody>
                {overTimeCardData &&
                overTimeCardData.overtime_created.length > 0 ? (
                  overTimeCardData.overtime_created.map((gP, id) => (
                    <tr key={id}>
                      <td className="text-center">{id + 1}</td>
                      <td className="text-center">{gP.ot_date}</td>
                      <td className="text-center">
                        {moment(gP.time_from, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {moment(gP.time_to, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {`0${(gP.duration / 60) ^ 0}`.slice(-2) +
                          ":" +
                          ("0" + (gP.duration % 60)).slice(-2)}
                      </td>
                      <td className="text-center">{gP.ot_work_desc}</td>
                      <td className="text-center">
                        {gP.users.map((user, idx) => (
                          <React.Fragment key={idx}>
                            <span key={idx}>
                              {user.first_name} {user.last_name}
                            </span>
                            <br></br>
                          </React.Fragment>
                        ))}
                      </td>
                      <td className="text-center">{gP.approval_status}</td>
                      <td className="text-center">
                        {gP?.approve_by?.first_name ?? "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={10}>
                      {isFetching ? (
                        <div className="text-center">
                          <Spinner color="whiteSmoke" size="sm" />
                        </div>
                      ) : (
                        "No OverTime Card  found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const OverTimeCard = () => {
  const [modal, setModal] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [overTimeCardData, setOverTimeCardData] = useState(null);
  const [overTimeCardDate, setOverTimeCardDate] = useState(null);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const toggleUpdateModal = () => setIsOpenUpdate(!isOpenUpdate);
  const [updatedId, setUpdatedId] = useState();
  const [actualTimeData, setActualTimeData] = useState({
    actual_from_time: "",
    actual_to_time: "",
    remarks: ""
  });

  const handleUpdateActualTime = data => {
    setUpdatedId(data.id);
    toggleUpdateModal();
  };
  const handleSubmitActualTime = () => {
    let params = {
      ...actualTimeData,
      id: updatedId
    };
    axiosPost(URL.updateActualTime, params, response => {
      if (response.status === 200) {
        swal("success", "Record added Successfully");
        setIsOpenUpdate(false);
      }
    });
  };

  const handleActualTimeChange = e => {
    const { name, value } = e.target;
    setActualTimeData({
      ...actualTimeData,
      [name]: value
    });
  };

  useEffect(() => {
    fetchOverTimeCard();
    // let value = checkModulePermission(moduleName.OverTime);
    // setAssignedPermission(value);
  }, []);

  useEffect(() => {
    fetchOverTimeCard();
  }, [overTimeCardDate]);

  useEffect(() => {
    if (isOpenUpdate === false) {
      setActualTimeData({
        actual_from_time: "",
        actual_to_time: "",
        remarks: ""
      });
    }
  }, [isOpenUpdate]);

  const fetchOverTimeCard = () => {
    setIsFetching(true);
    axiosPost(
      URL.getRespectiveOverTimeCard,
      {
        pass_from: overTimeCardDate?.pass_from ?? null,
        pass_to: overTimeCardDate?.pass_to ?? null
      },
      res => {
        if (res.status === 200) {
          setIsFetching(false);
          let modules = res.data;
          setOverTimeCardData(modules);
        }
      },
      err => {
        setIsFetching(false);
      }
    );
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">OverTime Card</div>
      </div>
      <div
        className="container-fluid m-0 p-0"
        style={{ padding: "1rem", height: "100%" }}
      >
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        {/* {assignedPermission?.store && ( */}
        {checkRolePermissions("store", moduleName.overTime) ? (
          <div className="">
            <Button name="Add OverTime Card" onClick={toggleModal} />
          </div>
        ) : null}

        {/* )} */}
        {/* <div className="whiteContainer p-4">
          <div className="row m-0 justify-content-center">
            <h3 className="text-center sectionTitle pb-2">Search</h3>

            <div className="col text-start" style={{ position: "relative" }}>
              <label className="floating">From</label>
              <input
                type="date"
                style={myInput}
                className="form-control"
                name="pass_from"
                value={overTimeCardDate?.pass_from}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setOverTimeCardDate((prev) => ({ ...prev, [name]: value }));
                }}
              ></input>
            </div>

            <div className="col text-start" style={{ position: "relative" }}>
              <label className="floating">To</label>
              <input
                type="date"
                style={myInput}
                value={overTimeCardDate?.pass_to}
                name="pass_to"
                className="form-control"
                min={overTimeCardDate?.pass_from}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setOverTimeCardDate((prev) => ({ ...prev, [name]: value }));
                }}
              ></input>
            </div>
          </div>
        </div> */}
        <OverTimeCardList
          overTimeCardData={overTimeCardData}
          isFetching={isFetching}
          handleUpdateActualTime={handleUpdateActualTime}
        />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={"Add OverTime Card "}
        size="lg"
        modalBody={
          <div className="container p-0 m-0">
            <OverTimeCardForm
              setSubmitSpinner={setSubmitSpinner}
              fetchOverTimeCard={fetchOverTimeCard}
              toggleModal={toggleModal}
            />
          </div>
        }
      />

      <CommonModal
        modal={isOpenUpdate}
        toggleModal={toggleUpdateModal}
        modalHeader={"Enter Actual Time"}
        size="md"
        modalBody={
          <div className="container p-0 m-0">
            <div className="row">
              <div className="col-md-6 text-start">
                <label className="floating" style={{ position: "relative" }}>
                  Actual Time From<span className="required"> *</span>
                </label>
                <input
                  type="time"
                  name="actual_from_time"
                  className="form-control"
                  onChange={handleActualTimeChange}
                  value={actualTimeData?.actual_from_time}
                />
              </div>
              <div className="col-md-6 text-start">
                <label className="floating" style={{ position: "relative" }}>
                  Actual Time To<span className="required"> *</span>
                </label>
                <input
                  type="time"
                  name="actual_to_time"
                  className="form-control"
                  onChange={handleActualTimeChange}
                  value={actualTimeData?.actual_to_time}
                />
              </div>

              <div className="col-md-12 mt-2 text-start">
                <label className="floating">
                  Remarks<span className="required"> *</span>
                </label>
                <textarea
                  type="time"
                  name="remarks"
                  className="form-control"
                  onChange={handleActualTimeChange}
                  value={actualTimeData?.remarks}
                />
              </div>
            </div>
          </div>
        }
        modalFooter={
          <>
            <Button name="Submit" onClick={handleSubmitActualTime} />
            <Button name="Cancel" onClick={toggleUpdateModal} />
          </>
        }
      />
    </>
  );
};
const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px"
};

export default OverTimeCard;
