import React, { useState, useEffect } from "react";
import "../../assets/scss/companySetting.scss";
import { axiosPost } from "../../utils/AxiosApi";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { URL } from "../../utils/Constants";
import Button from "../../components/common/Button";
import Checkbox from "../../components/common/Checkbox";
import { axiosGet } from "../../utils/AxiosApi";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const CompanySetting = () => {
  const [companySetting, setCompanySetting] = useState(null);
  const [companySettingData, setCompanySettingData] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isloading, setIsloading] = useState(false);

  // const { is_guideline_required } = companySettingData;

  useEffect(() => {
    getCompanySetting();
  }, []);

  const getCompanySetting = () => {
    setIsloading(true);
    axiosGet(
      URL.getCompanySetting,
      (response) => {
        if (response.data.success) {
          setIsloading(false);

          let modules = response.data.data && response.data.data?.item;
          setCompanySettingData(modules);
        }
      },
      (err) => {
        setIsloading(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleChange = (e) => {
    const { name, checked, type } = e.target;
    if (companySettingData && companySettingData !== null) {
      if (type === "checkbox") {
        setCompanySettingData((prevState) => ({
          ...prevState,
          is_guideline_required: checked && checked ? 1 : 0,
        }));
      }
    }
    setCompanySetting((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { is_guideline_required } = companySetting;
    let params = {
      is_guideline_required:
        is_guideline_required && is_guideline_required ? 1 : 0,
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.insertCompanySetting,
      params,
      (res) => {
        if (res && res.data.success) {
          setSubmitSpinner(false);
          setCompanySetting((prevState) => ({
            ...prevState,
            is_guideline_required: false,
          }));
          swal("Success", "Company setting inserted succesfully", "success");
          return;
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Company Setting</div>
      </div>
      <div className="container" style={{ height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <div className="container mt-2">
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ul className="list list-inline">
                  <li className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <i className="fa fa-check-circle checkicon"></i>
                      <div className="ml-2">
                        <h6 className="mb-0">Company Guidelines</h6>
                        <div className="d-flex flex-row mt-1 text-black-50 date-time"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              {isloading ? (
                <LoadingAnimation spinner />
              ) : (
                <form className="form-group" onSubmit={handleSubmit}>
                  <div className="form-check">
                    <Checkbox
                      handleChange={handleChange}
                      checked={
                        companySettingData?.is_guideline_required === 1
                          ? true
                          : companySetting?.is_guideline_required
                      }
                      name="is_guideline_required"
                      id="exampleCheck1"
                    />

                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Staff must accept the company guidelines to use the
                      system.
                    </label>
                  </div>

                  <div style={{ float: "right" }}>
                    <Button type="submit" name="Submit" />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySetting;
