import React, { Component, lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Department from "../pages/Department/Department";
import Company from "../pages/Company/Company";
import DepartmentStaff from "../pages/DepartmentStaff/DepartmentStaff";
import TermsandCondition from "../pages/Company/TermsandCondition";
import Branch from "../pages/Company/Branch/Branch";
import CompanyGuideline from "../pages/CompanyGuideline/CompanyGuideline";
import CompanySetting from "../pages/Company/CompanySetting";
import AcceptCompanyGuideline from "../pages/CompanyGuideline/AcceptCompanyGuideline";
import AddLeaveCategory from "../pages/LeaveCategory/AddLeaveCategory";
import CreateLeave from "../pages/LeaveCategory/CreateLeave";
import LeaveConfiguration from "../pages/LeaveCategory/LeaveConfiguration";
import BirthdayCofiguration from "../pages/BirthdayConfiguration/BirthdayCofiguration";
import ViewRequestedLeaves from "../pages/LeaveCategory/ViewRequestedLeaves";
import { ViewLeaveRequest } from "../pages/LeaveCategory/ViewLeaveRequest";
import { GroupEmail } from "../pages/GroupEmail/GroupEmail";
import ReportProblem from "../pages/ReportProblem/ReportProblem";
import Feedback from "../pages/ReportProblem/Feedback";
import FAQ from "../pages/FAQ/FAQ";
import GatePass from "../pages/GatePass/GatePass";
import ViewAllGatePass from "../pages/GatePass/ViewAllGatePass";
import ViewRequestedGatePass from "../pages/GatePass/ViewRequestedGatePass";
import OverTimeCard from "../pages/OverTimeCard/OverTimeCard";
import ViewAllOverTimeCard from "../pages/OverTimeCard/ViewAllOverTimeCard";
import ViewRequestedOvertime from "../pages/OverTimeCard/ViewRequestedOvertime";
import Vendor from "../pages/Vendor/Vendor";
import Requistion from "../pages/Requistion/Requistion";
import Items from "../pages/Items/Items";
import Notices from "../pages/Notices/Notices";
import MyProfile from "../pages/MyProfile/MyProfile";
import Evaluation from "../pages/Evaluation/Evaluation";

class DashboardRoutes extends Component {
  render() {
    return (
      <div className="dashboardRouteDiv">
        <Switch>
          <Route path="/" element={<Dashboard />} />
          <Route path="/department" element={<Department />} />
          <Route path="/notices" element={<Notices />} />
          <Route path="/company" element={<Company />} />
          <Route path="/branch" element={<Branch />} />
          <Route path="/departmentStaff" element={<DepartmentStaff />} />
          <Route path="/companyGuideline" element={<CompanyGuideline />} />
          <Route
            path="/acceptCompanyGuideline"
            element={<AcceptCompanyGuideline />}
          />
          <Route path="/companySetting" element={<CompanySetting />} />
          <Route path="/myProfile" element={<MyProfile />} />
          <Route path="/addLeaveCategory" element={<AddLeaveCategory />} />
          <Route path="/createLeave" element={<CreateLeave />} />
          <Route path="/leaveConfiguration" element={<LeaveConfiguration />} />
          {/* <Route path="/viewLeave" element={<ViewLeaveRequest />} /> */}
          <Route path="/viewLeave" element={<ViewLeaveRequest />} />
          <Route path="/viewRequestedLeave" element={<ViewRequestedLeaves />} />
          <Route path="/birthdayConfig" element={<BirthdayCofiguration />} />
          <Route path="/groupEmail" element={<GroupEmail />} />
          <Route path="/reportProblem" element={<ReportProblem />} />
          <Route path="/feedbacks" element={<Feedback />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/requistion" element={<Requistion />} />
          <Route path="/items" element={<Items />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/gatePass" element={<GatePass />} />
          <Route path="/viewGatePass" element={<ViewAllGatePass />} />
          <Route
            path="/viewRequestedGatePass"
            element={<ViewRequestedGatePass />}
          />
          <Route path="/overTime" element={<OverTimeCard />} />
          <Route path="/viewOverTime" element={<ViewAllOverTimeCard />} />
          <Route
            path="/requestedOverTime"
            element={<ViewRequestedOvertime />}
          />
          <Route
            path="/evaluation"
            element={<Evaluation />}
          />
        </Switch>
      </div>
    );
  }
}

export default DashboardRoutes;
