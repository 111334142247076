import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import ListLeaveRequest from "./ListLeaveRequest";
import Button from "../../components/common/Button";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, moduleName, headers } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkModulePermission } from "../../utils/PermissionChecker";
import swal from "sweetalert";
import CommonModal from "../../components/common/Modal";
import { ViewLeaveDetails } from "./ViewLeaveDetails";
import axios from "axios";
import ListDepartmentLeave from "./ListDepartmentLeave";

export const ViewLeaveRequest = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [company, setCompany] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentStaff, setDepartmentStaff] = useState(null);
  const [leaveDate, setLeaveDate] = useState(null);
  const [leaveData, setLeaveData] = useState(null);
  const [leaveDepartmentData, setLeaveDepartmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [modalRemarks, setModalRemarks] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [selectedLeaveToView, setSelectedLeaveToView] = useState(null);
  const [modal, setModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const [ccUsers, setCCUsers] = useState([]);

  // const [pageLimit, setPageNumber] = useState(1);

  const resetData = () => {
    setIsFilter(false);
    setSelectedData(null);
    setLeaveDate(null);
    setLeaveData(null);
    setLeaveDepartmentData(null);
    setSelectedLeaveToView(null);
    isParentCompany && setDepartment(null);
  };

  const isParentCompany = JSON.parse(localStorage.getItem("parent_company"));

  useEffect(() => {
    getCompany();
    getAllUsers();
    // getDepartment();
    let value = checkModulePermission(moduleName?.leave);
    setAssignedPermission(value);
  }, []);

  // useEffect(() => {
  //   if (selectedData === null) {
  //     getDepartment();
  //   }
  // }, [selectedData]);

  const toggleModalRemarks = () => {
    setModalRemarks(!modalRemarks);
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleLeaveDateChange = (e) => {
    const { name, value } = e.target;
    setLeaveDate((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (selectedData?.departmentStaff === undefined) {
      if (leaveDate?.To !== undefined && leaveDate?.From !== undefined) {
        setLeaveDepartmentData(null);
        getDepartmentFilterLeave("filter");
      }
    } else {
      if (leaveDate?.To !== undefined && leaveDate?.From !== undefined) {
        setLeaveData(null);
        getLeaveRequest("filter");
      }
    }

    // return () => {
    //   setLeaveData(null);
    // };
  }, [leaveDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveDate(null);

    if (name === "department") {
      setSelectedData((prevState) => ({
        ...prevState,
        [name]: value,
        departmentStaff: undefined,
      }));
      setLeaveDepartmentData(null);
    } else if (name === "departmentStaff") {
      setSelectedData((prevState) => ({ ...prevState, [name]: value }));
      const leaveData = departmentStaff.find((ds) => ds.id === parseInt(value));
      leaveData && setLeaveData(leaveData.leave_report);
    } else {
      setSelectedData((prevState) => ({
        ...prevState,
        [name]: value,
        department: undefined,
        departmentStaff: undefined,
      }));
    }
  };

  const getAllUsers = () => {
    axiosGet(URL.getOverTimeUsers, (response) => {
      // let newArr = [];
      if (response.status === 200) {
        let responseData = response.data;
        responseData?.forEach((element) => {
          element.label = element.email;
          element.value = element.id;
        });
        setAllUsers(responseData);
      }
    });
  };

  const getCompany = () => {
    axiosGet(
      URL.getRespectiveCompany,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setCompany(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartment = () => {
    let url = !selectedData?.company
      ? URL.getDepartment
      : `${URL.getResDepartment}/${selectedData?.company}`;
    axiosGet(
      url,
      // {
      //   limit: null,
      //   pageNumber: null
      // },
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDepartment(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartmentStaff = () => {
    setIsLoading(true);
    axiosGet(
      `${URL.getDepartmentStaffLeave}/${selectedData?.department}`,
      // {
      //   limit: 4,
      //   pageNumber
      // },
      (response) => {
        if (response.data.success) {
          setIsLoading(false);
          let modules = response.data.data.items;
          setLeaveDepartmentData(modules);
          setDepartmentStaff(modules);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleCcToTeam = (e) => {
    const { options } = e.target;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setCCUsers(value);
  };

  useEffect(() => {
    if (selectedData?.department) {
      setLeaveDepartmentData(null);
      getDepartmentFilterLeave();
    }
  }, [selectedData?.department !== undefined]);

  const getDepartmentFilterLeave = (filter) => {
    let params;
    setIsLoading(true);
    if (filter === "filter") {
      params = {
        department_id: Number(selectedData?.department),
        from_date: leaveDate?.From,
        to_date: leaveDate?.To,
      };
    } else {
      params = {
        department_id: Number(selectedData?.department),
        from_date: null,
        to_date: null,
      };
    }
    axiosPost(
      URL.getDepartmentFilterLeave,
      params,
      (res) => {
        if (res.data.success) {
          setIsLoading(false);
          setLeaveDepartmentData(res?.data?.data?.items);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    if (selectedData?.company || !isParentCompany) {
      setDepartment(null);
      setLeaveDepartmentData(null);
      getDepartment();
    }
  }, [selectedData?.company]);

  useEffect(() => {
    if (selectedData?.department) {
      getDepartmentStaff();
    }
  }, [selectedData?.department]);

  const getLeaveRequest = (filter) => {
    setIsLoading(true);
    let params;
    if (filter === "filter") {
      params = {
        staff_id: selectedData?.departmentStaff,
        from_date: leaveDate?.From,
        to_date: leaveDate?.To,
      };
    } else {
      params = {
        staff_id: selectedData?.departmentStaff,
        from_date: null,
        to_date: null,
      };
    }
    axiosPost(
      URL.getDepartmentStaffFilterLeave,
      params,
      (res) => {
        if (res.data.success) {
          setIsLoading(false);
          setLeaveData(res?.data?.data);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleExportLeave = (status) => {
    let params, url, fullname, depName;
    if (status === "all") {
      params = {
        department_id: selectedData?.department,
        from_date: leaveDate?.From ?? null,
        to_date: leaveDate?.To ?? null,
      };
      url = URL.exportAllLeave;
      depName = department?.find(
        (dpt) => dpt?.id === Number(selectedData?.department)
      )?.name;
    } else {
      params = {
        staff_id: selectedData?.departmentStaff,
        from_date: leaveDate?.From ?? null,
        to_date: leaveDate?.To ?? null,
      };
      url = URL.exportIndividualLeave;
      const staffName = departmentStaff?.find(
        (dptstf) => dptstf.id === Number(selectedData?.departmentStaff)
      );

      fullname = `${staffName.first_name}_${
        staffName.middle_name && staffName.middle_name
      }_${staffName.last_name}`;
    }

    axios({
      url,
      method: "POST",
      responseType: "blob", // important
      headers: headers,
      data: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            status === "all" ? depName : fullname
          }-Leave${new Date().toISOString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        displayErrorAlert(err);
      });
  };

  const handleAcceptLeave = (e, id, status) => {
    setModalRemarks(true);
    setLeaveStatus((prevState) => ({ ...prevState, id: id, status: status }));
  };
  const handleSubmitLeave = (e) => {
    e.preventDefault();
    //as remarks is optional field no need pof validation
    let params;
    if (leaveStatus?.status === "accept") {
      params = {
        status: "Accepted",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else if (leaveStatus?.status === "reject") {
      params = {
        status: "Rejected",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else if (leaveStatus?.status === "revoke") {
      params = {
        status: "Revoked",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else {
      params = {
        status: "Breached",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    }

    setSubmitSpinner(true);
    axiosPost(
      URL.acceptLeave,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModalRemarks();
          setRemarks(null);
          swal(
            "Success",
            leaveStatus?.status === "accept"
              ? "Leave request accepted successfully"
              : leaveStatus?.status === "reject"
              ? "Leave request rejected successfully"
              : leaveStatus?.status === "revoke"
              ? "Leave request revoked successfully"
              : leaveStatus?.status === "breeze" &&
                "Leave request breezed successfully ",
            "success"
          );
          getLeaveRequest();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        toggleModalRemarks();
        displayErrorAlert(err);
      }
    );
  };
  const handleChangeRemarks = (e) => {
    setRemarks(e.target.value);
  };
  const handleViewLeave = (e, id) => {
    setModal(true);
    const selectedLeave = leaveData?.leaves.find(
      (leav) => leav?.id === Number(id)
    );
    setSelectedLeaveToView(selectedLeave);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">View Leaves </div>
        {submitSpinner ? (
          <div
            className="container"
            style={{ padding: "1rem", height: "100%" }}
          >
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Submitting ...
              </div>
            </div>
          </div>
        ) : null}

        <div className="whiteContainer p-4">
          <div className="row justify-content-center d-flex">
            {isParentCompany ? (
              <div className="col" style={{ position: "relative" }}>
                <label className="floating">Company</label>
                <select
                  className="formSelect"
                  name="company"
                  onChange={handleChange}
                  value={selectedData ? selectedData.company : ""}
                >
                  <option value="" disabled selected>
                    Choose Company
                  </option>
                  {company?.map((cmp, idx) => (
                    <option value={cmp?.id} key={idx}>
                      {cmp?.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department</label>
              <select
                className="formSelect"
                name="department"
                onChange={handleChange}
                value={
                  selectedData?.department === undefined
                    ? ""
                    : selectedData.department
                }
                disabled={false}
              >
                <option value="" disabled selected>
                  Choose Department
                </option>
                {department?.map((dpt, idx) => (
                  <option value={dpt?.id} key={idx}>
                    {dpt?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department staff</label>
              <select
                className="formSelect"
                name="departmentStaff"
                onChange={handleChange}
                value={
                  selectedData?.departmentStaff === undefined
                    ? ""
                    : selectedData.departmentStaff
                }
                disabled={selectedData?.department === undefined ? true : false}
              >
                <option value="" disabled selected>
                  Choose DepartmentStaff
                </option>
                {departmentStaff?.map((dptStf, idx) => (
                  <option value={dptStf?.id} key={idx}>
                    {dptStf?.first_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {isFilter && (
            <div className="row">
              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">From</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="From"
                  // min={new Date().toLocaleDateString("en-ca")}
                  onChange={handleLeaveDateChange}
                  value={leaveDate ? leaveDate.From : ""}
                ></input>
              </div>

              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">To</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="To"
                  min={
                    leaveDate?.From ?? new Date().toLocaleDateString("en-ca")
                  }
                  value={leaveDate ? leaveDate.To : ""}
                  onChange={handleLeaveDateChange}
                ></input>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col text-end">
              <Button name="Filter" onClick={() => setIsFilter(!isFilter)} />
              {/* <Button
                name="Submit"
                onClick={getLeaveRequest}
                className="ms-2"
              /> */}
              {selectedData?.department && selectedData?.departmentStaff ? (
                <Button
                  name="Export"
                  onClick={() => handleExportLeave("individual")}
                  className="ms-2"
                />
              ) : (
                selectedData?.department &&
                selectedData?.departmentStaff === undefined && (
                  <Button
                    name="Export All"
                    onClick={() => handleExportLeave("all")}
                    className="ms-2"
                  />
                )
              )}
              <Button
                name="Reset"
                onClick={() => resetData()}
                className="ms-2"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center d-flex text-center">
          <div className="mt-2">
            {!selectedData?.departmentStaff ? (
              <>
                <ListDepartmentLeave
                  dataLeave={leaveDepartmentData}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <ListLeaveRequest
                allUsers={allUsers}
                ccUsers={ccUsers}
                handleCcToTeam={handleCcToTeam}
                viewLeave
                toggleModal={toggleModalRemarks}
                modal={modalRemarks}
                // leaveData={createdLeaveData}
                assignedPermission={assignedPermission}
                handleAcceptLeave={handleAcceptLeave}
                handleChange={handleChangeRemarks}
                handleSubmit={handleSubmitLeave}
                remarks={remarks}
                setRemarks={setRemarks}
                leaveDataFiltered={leaveData}
                leaveStatus={leaveStatus}
                handleViewLeave={handleViewLeave}
                noPagination={true}
              />
            )}
          </div>
        </div>
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="lg"
        modalHeader="Leave Details"
        modalBody={
          <ViewLeaveDetails selectedLeaveToView={selectedLeaveToView} />
        }
      />
    </>
  );
};

const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px",
};
