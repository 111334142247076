import React, { useState } from "react";
import CompanyForm from "./CompanyForm/CompanyForm";
import CommonModal from "../../components/common/Modal";
import { URL } from "../../utils/Constants";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";
// import { Spinner } from "reactstrap";
const CompanyList = (prop) => {
  const { companyData, handleDelete, handleEdit, handleView, isLoading } = prop;
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  // if (isLoading) {
  //   return (
  //     <div className="text-center">
  //       <LoadingAnimation spinner />
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="row ">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Contact Number</th>
                  <th>Website</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companyData && companyData.length > 0 ? (
                  companyData.map((cmp, id) => {
                    return (
                      <React.Fragment key={cmp?.id}>
                        <tr>
                          <td className="text-center">{id + 1}</td>
                          <td>
                            {cmp.name}
                            <br />[ {cmp.short_name} ]
                          </td>
                          <td>{cmp.address_line}</td>
                          <td>{cmp.email_address}</td>
                          <td>{cmp.contact_number}</td>
                          <td className="text-right">
                            {" "}
                            {cmp.domain_name === ""
                              ? "Not available"
                              : cmp.domain_name}
                          </td>
                          <td className="td-actions text-right">
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-primary rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="View"
                              onClick={(e) => handleView(e, id)}
                            >
                              <i className="material-symbols-outlined">
                                visibility
                              </i>
                            </button>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-danger rounded-circle btn-sm me-2"
                              data-original-title=""
                              title={
                                cmp.is_enabled === 0
                                  ? "Enable Company"
                                  : "Disable Company"
                              }
                              onClick={(e) =>
                                handleDelete(e, cmp.id, cmp.is_enabled)
                              }
                            >
                              <i className="material-symbols-outlined">
                                {cmp.is_enabled === 0
                                  ? "domain"
                                  : "domain_disabled"}
                              </i>
                            </button>

                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-secondary rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="Edit"
                              onClick={(e) => handleEdit(e, id)}
                            >
                              <i className="material-symbols-outlined">edit</i>
                            </button>

                            <a
                              href={URL.downloadCompanyZip + "/" + cmp.id}
                              target="_blank"
                            >
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-info rounded-circle btn-sm me-2"
                                data-original-title=""
                                title="Download"
                              >
                                <i className="material-symbols-outlined">
                                  file_download
                                </i>
                              </button>
                            </a>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    {isLoading ? (
                      <td colSpan={7}>
                        <LoadingAnimation spinner />
                      </td>
                    ) : (
                      <td colSpan={7}>No Company found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="xl"
        modalHeader="Edit Company"
        modalBody={
          <>
            <CompanyForm />
          </>
        }
      />
    </>
  );
};

export default CompanyList;
