import React, { useState } from "react";
import { Spinner, Label } from "reactstrap";
import Button from "../../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { checkIsValidPassword } from "../../../utils/Validation";
import swal from "sweetalert";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";
import { useNavigate } from "react-router";

const PasswordChange = ({ toggleModal, Header }) => {
  let navigate = useNavigate();
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [inputType, setInputType] = useState({
    userPassword: "password",
    userCPassword: "password"
  });
  const [userPwd, setUserPwd] = useState(null);
  const [capsOn, setCapsOn] = useState({ pwdCaps: false, cpwdCaps: false });
  const [errors, setErrors] = useState({
    isErrorPwd: false,
    isErrorCpwd: false,
    validationMsg: null
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setUserPwd(prevState => ({ ...prevState, [name]: value }));
    if (name === "password") {
      if (value !== null && value !== "") {
        if (!checkIsValidPassword(value)) {
          setErrors({
            ...errors,
            isErrorPwd: true,
            validationMsg:
              "Password must contain Special characters, Numbers, Uppercase and Lowercase letter"
          });
        } else if (value.length < 8) {
          setErrors({
            ...errors,
            isErrorPwd: true,
            validationMsg: "Password must be 8 characters long"
          });
        } else {
          setErrors({
            ...errors,
            isErrorPwd: false,
            validationMsg: null
          });
        }
      }

      if (name === "confirmPassword") {
        if (value !== userPwd?.password) {
          setErrors({
            ...errors,
            isErrorCpwd: true,
            validationMsg: "Password don't match"
          });
        } else {
          setErrors({
            ...errors,
            isErrorCpwd: false,
            validationMsg: null
          });
        }
      }
    }
  };

  const handleKeydown = (e, status) => {
    // if (e.key === "Enter") {
    //   //   handleSubmit();
    // }

    if (status === "pwd") {
      if (e.getModifierState("CapsLock")) {
        setCapsOn(prevState => ({ ...prevState, pwdCaps: true }));
      } else {
        setCapsOn(prevState => ({ ...prevState, pwdCaps: false }));
      }
    } else {
      if (e.getModifierState("CapsLock")) {
        setCapsOn(prevState => ({ ...prevState, cpwdCaps: true }));
      } else {
        setCapsOn(prevState => ({ ...prevState, cpwdCaps: false }));
      }
    }
  };

  const handleInputTypeChange = status => {
    if (status === "pwd") {
      if (inputType?.userPassword === "password") {
        setInputType(prevState => ({ ...prevState, userPassword: "text" }));
      } else {
        setInputType(prevState => ({
          ...prevState,
          userPassword: "password"
        }));
      }
    } else {
      if (inputType?.userCPassword === "password") {
        setInputType(prevState => ({ ...prevState, userCPassword: "text" }));
      } else {
        setInputType(prevState => ({
          ...prevState,
          userCPassword: "password"
        }));
      }
    }
  };

  const handleConfirmChange = e => {
    const { name, value } = e.target;

    setUserPwd(prevState => ({ ...prevState, [name]: value }));
    if (value !== userPwd?.password) {
      setErrors({
        ...errors,
        isErrorCpwd: true,
        validationMsg: "Password don't match"
      });
    } else {
      setErrors({
        ...errors,
        isErrorCpwd: false,
        validationMsg: null
      });
    }
  };

  const handleChangePassword = e => {
    e.preventDefault();
    if (userPwd === null) {
      swal("Warning", "Enter password", "warning");
      return;
    }

    if (userPwd?.password !== userPwd?.confirmPassword) {
      swal("Warning", "Password don't match", "warning");
      return;
    }
    let params = {
      password: userPwd?.password
    };
    setSubmitSpinner(true);

    axiosPost(
      URL.passwordChange,
      params,
      res => {
        if (res.status === 200) {
          setSubmitSpinner(false);
          !Header && localStorage.setItem("isPasswordChanged", true);
          toggleModal();
          swal("Success", "Password changed successfully", "success");
          localStorage.clear();
          navigate("/");
        }
      },
      err => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  return (
    <>
      <div className="container" style={{ padding: "0.75rem", height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <form onSubmit={handleChangePassword}>
          <div className="row ">
            <div className="col p-1">
              <label style={{ display: "contents" }}>Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputType?.userPassword}
                  className="form-control"
                  placeholder="xxxxxxx"
                  name="password"
                  onChange={handleChange}
                  onKeyDown={e => handleKeydown(e, "pwd")}
                />
                <span>
                  <FontAwesomeIcon
                    icon={
                      inputType?.userPassword === "password"
                        ? faEye
                        : faEyeSlash
                    }
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "13px"
                    }}
                    onClick={() => handleInputTypeChange("pwd")}
                  />
                </span>
              </div>
              {userPwd?.password && errors?.isErrorPwd ? (
                <small
                  style={{
                    color: "red",
                    marginTop: "5px",
                    display: "block"
                  }}
                >
                  {errors?.validationMsg}
                </small>
              ) : null}

              {capsOn?.pwdCaps ? (
                <div>
                  <strong>Caps Lock is On</strong>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col p-1">
              <label style={{ display: "contents" }}> Confirm Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputType?.userCPassword}
                  className="form-control"
                  placeholder="xxxxxxx"
                  name="confirmPassword"
                  onChange={handleConfirmChange}
                  onKeyDown={e => handleKeydown(e, "cPwd")}
                />
                {userPwd?.confirmPassword && errors?.isErrorCpwd ? (
                  <small
                    style={{
                      color: "red",
                      marginTop: "5px",
                      display: "block"
                    }}
                  >
                    {errors?.validationMsg}
                  </small>
                ) : null}
                <span>
                  <FontAwesomeIcon
                    icon={
                      inputType?.userCPassword === "password"
                        ? faEye
                        : faEyeSlash
                    }
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "13px"
                    }}
                    onClick={() => handleInputTypeChange("cPwd")}
                  />
                </span>
              </div>

              {capsOn?.cpwdCaps ? (
                <div>
                  <strong>Caps Lock is On</strong>
                </div>
              ) : null}
            </div>
          </div>

          <div className="row mt-2">
            <div className="col text-end p-2">
              <Button name="Submit" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PasswordChange;
