export const ViewDepartmentLeaveDetails = ({ selectedLeaveToView }) => {
  return (
    <div className="container mt-3">
      <h5 className="text-center">
        Total Accepted Leave:{selectedLeaveToView?.total_accepted_days}
      </h5>
      <div className="row">
        <div className="col-md-8">
          <table className="table">
            {selectedLeaveToView?.leaves?.length === 0
              ? "NO Leave Requested yet "
              : selectedLeaveToView?.map((sleav, idx) => (
                  <tbody key={idx}>
                    <tr>
                      <td>
                        <strong>Subject</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.subject}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Duration</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.duration} days </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Leave From</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.from_date}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Leave To</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.to_date}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Reason</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.reason}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Status</strong>
                      </td>
                      <td>:</td>
                      <td>{sleav.status}</td>
                    </tr>
                  </tbody>
                ))}
          </table>
        </div>
      </div>
    </div>
  );
};
