import React, { useState } from "react";
import { Spinner } from "reactstrap";
import Button from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import BranchForm from "./BranchForm";
import BranchList from "./BranchList";
import { checkRolePermissions } from "../../../utils/PermissionChecker";
import { moduleName } from "../../../utils/Constants";

const Branch = () => {
  const [modal, setModal] = useState(false);
  const [branch, setBranch] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);

  //require api to fetch branch data and populate the branchData state
  const [branchData, setBranchData] = useState(null);

  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Branch</div>
      </div>
      <div className="container" style={{ height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}
        {checkRolePermissions("store", moduleName.branch) ? (
          <div className="text-end">
            <Button name="Add Branch" onClick={toggleModal} />
          </div>
        ) : null}
        <div className="container" style={{ padding: "1rem" }}>
          <div className="row justify-content-center d-flex text-center">
            <div className="mt-2">
              <BranchList branchData={branchData} />
            </div>
          </div>
        </div>
        <CommonModal
          modal={modal}
          toggleModal={toggleModal}
          size="xl"
          modalHeader="Add a Branch"
          modalBody={
            <>
              <BranchForm toggleModal={toggleModal} />
            </>
          }
        />
      </div>
    </>
  );
};

export default Branch;
