import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { ListLeaveConfiguration } from "./ListLeaveConfiguration";
import { emailValidate } from "../../utils/Validation";
import { checkRolePermissions } from "../../utils/PermissionChecker";

const defaultConfigToData = [{ to: "" }];
const defaultConfigCCData = [{ cc: "" }];

const LeaveConfiguration = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [configToData, setConfigToData] = useState(defaultConfigToData);
  const [configCCData, setConfigCCData] = useState(defaultConfigCCData);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [mailAddress, setMailAddress] = useState(null);
  const [originalLeaveConfigData, setOriginaLeaveConfigData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getMailAddress();
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    axiosGet(
      URL.fetchAllUsersOfCompany,
      (res) => {
        if (res.status === 200) {
          let users = res.data.data;
          setUsers(users);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getMailAddress = () => {
    setIsLoading(true);
    axiosGet(
      URL.getMailAddress,
      (res) => {
        if (res.data.success) {
          let modules = res?.data?.data;
          setMailAddress(modules);
          setOriginaLeaveConfigData(JSON.parse(JSON.stringify(modules)));
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const handleChange = (e, id, status) => {
    const { name, value } = e.target;
    if (status === "To") {
      const cloneDefaultToConfig = [...configToData];
      cloneDefaultToConfig[id][name] = value;
      setConfigToData(cloneDefaultToConfig);
    } else {
      const cloneDefaultCCConfig = [...configCCData];
      cloneDefaultCCConfig[id][name] = value;
      setConfigCCData(cloneDefaultCCConfig);
    }
  };

  const handleAdd = (status) => {
    if (status === "CC") {
      const cloneDefaultCConfig = [...configCCData];
      const checkEmpty = cloneDefaultCConfig.some((f) => f.cc === "");
      if (checkEmpty) {
        swal("Warning", "Field mustn't be empty", "warning");
        return;
      }
      cloneDefaultCConfig?.push({ cc: "" });
      setConfigCCData(cloneDefaultCConfig);
    } else {
      const cloneDefaultToConfig = [...configToData];
      const checkEmpty = cloneDefaultToConfig.some((f) => f.to === "");
      if (checkEmpty) {
        swal("Warning", "Field mustn't be empty", "warning");
        return;
      }
      cloneDefaultToConfig?.push({ to: "" });
      setConfigToData(cloneDefaultToConfig);
    }
  };

  const handleRemove = (id, status) => {
    if (status === "CC") {
      const cloneDefaultCCConfig = [...configCCData];
      cloneDefaultCCConfig.splice(id, 1);
      setConfigCCData(cloneDefaultCCConfig);
    } else {
      const cloneDefaultToConfig = [...configToData];
      cloneDefaultToConfig.splice(id, 1);
      setConfigToData(cloneDefaultToConfig);
    }
  };

  const resetFormData = () => {
    const defaultToData = [{ to: "" }];
    const defaultCCData = [{ cc: "" }];
    setConfigToData(defaultToData);
    setConfigCCData(defaultCCData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isToEmpty = configToData.some((cD) => cD.to === "");
    const isCCEmpty = configCCData.some((cD) => cD.cc === "");
    if (isToEmpty && isCCEmpty) {
      swal("Warning", "Email to or Mandatory cc is required", "warning");
      return;
    }
    const to = configToData?.map((cD) => cD?.to);
    const cc = configCCData?.map((cD) => cD?.cc);
    let params = {};
    if (!isToEmpty) {
      for (let i = 0; i < configToData.length; i++) {
        if (!emailValidate(configToData[i].to)) {
          swal("Warning", `Invalid email to `, "warning");
          return;
        }
      }

      params.to = to;
      additionalDetails && (params.additionalDetails = additionalDetails);
    }
    if (!isCCEmpty) {
      for (let i = 0; i < configCCData.length; i++) {
        if (!emailValidate(configCCData[i].cc)) {
          swal("Warning", `Invalid mandatory CC email  `, "warning");
          return;
        }
      }
      params.cc = cc;
      additionalDetails && (params.additionalDetails = additionalDetails);
    }
    setSubmitSpinner(true);
    axiosPost(
      URL.insertCC,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          resetFormData();
          getMailAddress();
          swal("Success", "Leave configuration added successfully", "success");
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleAdditionalDetails = (e) => {
    setAdditionalDetails(e.target.checked);
  };

  return (
    <div className="mt-2">
      <div className="pageTitle">Leave Configuration</div>
      {submitSpinner ? (
        <div style={{ padding: "1rem", height: "100%" }}>
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        </div>
      ) : null}

      {checkRolePermissions("store", moduleName.leaveConfiguration) ||
      checkRolePermissions("update", moduleName.leaveConfiguration) ? (
        <div className="whiteContainer p-4">
          <label
            htmlFor="To"
            style={{ display: "contents" }}
            className="newTextColor"
          >
            Email To:
          </label>
          {configToData &&
            configToData.map((cD, id) => (
              <div className="row align-items-center" key={id}>
                <div className="col-10 mb-1">
                  <select
                    name="to"
                    className="form-select"
                    value={cD?.to}
                    onChange={(e) => handleChange(e, id, "To")}
                  >
                    <option value="" selected disabled>
                      Enter Email Address
                    </option>

                    {Object.keys(users).length > 0 ? (
                      Object.keys(users).map((el, id) => (
                        <optgroup label={el} key={id}>
                          {Object.values(users)[id].map((user, idx) => (
                            <option value={user.email} key={idx}>
                              {user.email}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    ) : (
                      <option value="no-user" disabled>
                        No Users available
                      </option>
                    )}
                  </select>
                </div>
                <div className="col-2">
                  <div className="">
                    {id !== 0 ? (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        onClick={() => handleRemove(id, "To")}
                        className="removeListBtn"
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                    {id === configToData.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        onClick={() => handleAdd("To")}
                        className="addListBtn"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

          <br></br>
          <label
            htmlFor="To"
            style={{ display: "contents" }}
            className="newTextColor"
          >
            Mandatory CC To:
          </label>
          {configCCData &&
            configCCData.map((cD, id) => (
              <div className="row align-items-center" key={id}>
                <div className="col-10 mb-1">
                  <select
                    name="cc"
                    className="form-select"
                    value={cD?.cc}
                    onChange={(e) => handleChange(e, id, "CC")}
                  >
                    <option value="" selected disabled>
                      Enter CC Email Address
                    </option>
                    {Object.keys(users).length > 0 ? (
                      Object.keys(users).map((el, id) => (
                        <optgroup label={el} key={id}>
                          {Object.values(users)[id].map((user, idx) => (
                            <option value={user.email} key={idx}>
                              {user.email}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    ) : (
                      <option value="no-user" disabled>
                        No Users available
                      </option>
                    )}
                  </select>
                </div>
                <div className="col-2">
                  <div className="">
                    {id !== 0 ? (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        onClick={() => handleRemove(id, "CC")}
                        className="removeListBtn"
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                    {id === configCCData.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        onClick={() => handleAdd("CC")}
                        className="addListBtn"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

          {mailAddress && mailAddress.additional_description === null && (
            <div className="mt-4" style={{ position: "relative" }}>
              <input
                type="checkbox"
                name="additionDetails"
                onChange={handleAdditionalDetails}
              />
              <label htmlFor="To" className="newTextColor">
                Required Additional Details
              </label>
            </div>
          )}

          {/* {checkRolePermissions("store", moduleName.leaveConfiguration) ? ( */}
          <div className="mt-4">
            <Button name="Submit" type="submit" onClick={handleSubmit} />
          </div>
          {/* ) : null} */}
        </div>
      ) : null}

      <ListLeaveConfiguration
        leaveConfigData={mailAddress}
        isLoading={isLoading}
        getMailAddress={getMailAddress}
        setMailAddress={setMailAddress}
        originalLeaveConfigData={originalLeaveConfigData}
      />
    </div>
  );
};
export default LeaveConfiguration;
