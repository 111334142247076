import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const defaultConfigToData = [{ to: "" }];

const BirthdayCofiguration = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [configToData, setConfigToData] = useState(defaultConfigToData);
  const [message, setMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedCompanyId, setCompanyId] = useState("");
  const [storePermission, setStorePermission] = useState(false);
  const [updatePermission, setUpdatePermissions] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const getPermissions = () => {
    setStorePermission(
      checkRolePermissions("store", moduleName.birthdayConfiguration)
    );
    setUpdatePermissions(
      checkRolePermissions("update", moduleName.birthdayConfiguration)
    );
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const cloneDefaultToConfig = [...configToData];
    cloneDefaultToConfig[id][name] = value;
    setConfigToData(cloneDefaultToConfig);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleAdd = (status) => {
    const cloneDefaultToConfig = [...configToData];
    const checkEmpty = cloneDefaultToConfig.some((f) => f.to === "");
    if (checkEmpty) {
      swal("Warning", "Field mustn't be empty", "warning");
      return;
    }
    cloneDefaultToConfig?.push({ to: "" });
    setConfigToData(cloneDefaultToConfig);
  };
  const handleRemove = (id, status) => {
    const cloneDefaultToConfig = [...configToData];
    cloneDefaultToConfig.splice(id, 1);
    setConfigToData(cloneDefaultToConfig);
  };

  const resetFormData = () => {
    const defaultToData = [{ to: "" }];
    setConfigToData(defaultToData);
    setMessage("");
  };

  const getBirthdayConfig = () => {
    setIsloading(true);
    axiosGet(
      URL.getBirthdayConfig,
      (res) => {
        if (res.status === 200) {
          setIsloading(false);
          let datum = res.data.data;
          if (datum.email.length > 0) {
            setEdit(true);
            setCompanyId(datum.email[0].company_id);
            let tempData = [];
            datum.email.forEach((el) => {
              tempData.push({
                to: el.email,
              });
            });
            setConfigToData(tempData);
          }
          if (datum.message.length > 0) {
            setEdit(true);
            setCompanyId(datum.message[0].company_id);
            setMessage(datum.message[0].message);
          }
        }
      },
      (err) => {
        setIsloading(false);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isToEmpty = configToData.some((cD) => cD.to === "");
    if (isToEmpty) {
      swal("Warning", "Email field required", "warning");
      return;
    }

    const to = configToData?.map((cD) => cD?.to);
    let params = {
      email: to,
      message: message,
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.insertBirthdayConfig,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          resetFormData();
          getBirthdayConfig();
          swal(
            "Success",
            "Birthday configuration added successfully",
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const isToEmpty = configToData.some((cD) => cD.to === "");
    if (isToEmpty) {
      swal("Warning", "Email field required", "warning");
      return;
    }

    const to = configToData?.map((cD) => cD?.to);
    let params = {
      email: to,
      message: message,
      company_id: selectedCompanyId,
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.updateBirthdayConfig,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          resetFormData();
          getBirthdayConfig();
          swal(
            "Success",
            "Birthday configuration updated successfully",
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    getBirthdayConfig();
    getPermissions();
  }, []);

  return (
    <div className="mt-2">
      <div className="pageTitle">Birthday Configuration</div>

      {submitSpinner ? (
        <div className="container" style={{ padding: "1rem", height: "100%" }}>
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        </div>
      ) : null}

      {isloading ? (
        <LoadingAnimation spinner />
      ) : (
        <div className="whiteContainer p-4  ">
          <div>
            <label style={{ display: "contents" }} className="newTextColor">
              Birthday Message:
            </label>
            <textarea
              className="form-control"
              name="message"
              value={message}
              onChange={handleMessageChange}
              placeholder="Type your message here"
            />
          </div>
          <div className="mt-3">
            <label
              htmlFor="To"
              style={{ display: "contents" }}
              className="newTextColor"
            >
              Reminder Email To:
            </label>
            {configToData &&
              configToData.map((cD, id) => (
                <div className="row align-items-center" key={id}>
                  <div className="col-10">
                    <input
                      type="email"
                      className="form-control mb-2"
                      id="basic-url"
                      name="to"
                      aria-describedby="basic-addon3"
                      placeholder="Enter EmailTo"
                      value={cD.to}
                      onChange={(e) => handleChange(e, id, "To")}
                    />
                  </div>
                  <div className="col-2">
                    {storePermission || updatePermission ? (
                      <div className="">
                        {id === configToData.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            onClick={() => handleAdd("To")}
                            className="addListBtn me-2"
                          />
                        ) : null}
                        {id !== 0 ? (
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            onClick={() => handleRemove(id, "To")}
                            className="removeListBtn"
                            style={{ cursor: "pointer" }}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-4">
            {edit ? (
              updatePermission ? (
                <Button name="Update" type="submit" onClick={handleUpdate} />
              ) : null
            ) : storePermission ? (
              <Button name="Submit" type="submit" onClick={handleSubmit} />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default BirthdayCofiguration;
