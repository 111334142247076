import React, { Component } from "react";

import Sidebar from "../components/Sidebar";
import DashboardRoutes from "../routes/DashboardRoutes";
import { checkRolePermissions } from "../utils/PermissionChecker";
import { moduleName, URL } from "../utils/Constants";
import Header from "../components/Header";
import { axiosGet } from "../utils/AxiosApi";
import { displayErrorAlert } from "../utils/DisplayMessage";

const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

class DashboardTemplate extends Component {
  state = {
    userInfo: null,
    menus: [
      {
        icon: <span className="material-symbols-outlined">dashboard</span>,
        menuName: "Dashboard",
        path: "/dashboard",
        display: true,
      },
      {
        icon: <span className="material-symbols-outlined">account_circle</span>,
        menuName: "My Profile",
        path: "myProfile",
        // display: checkRolePermissions("viewOwn", moduleName.myProfile)
        display: true,
      },

      {
        icon: <span className="material-symbols-outlined">corporate_fare</span>,
        menuName: "Company",
        path: "company",
        // display: true
        display: checkRolePermissions("view", moduleName.company),
      },
      // {
      //   icon: <FontAwesomeIcon icon={faCodeBranch} />,
      //   menuName: "Branch",
      //   path: "branch",
      //   display: checkRolePermissions("view", moduleName.branch)
      // },

      {
        icon: (
          <span className="material-symbols-outlined">room_preferences</span>
        ),
        menuName: "Departments",
        path: "department",
        display: checkRolePermissions("view", moduleName.department),
      },
      {
        icon: <span className="material-symbols-outlined">person_add</span>,
        menuName: "Department Staff",
        path: "departmentStaff",
        display: checkRolePermissions("view", moduleName.departmentStaff),
      },
      {
        icon: (
          <span className="material-symbols-outlined">developer_guide</span>
        ),
        menuName: "Guideline/Manual",
        path: "companyGuideline",
        display: checkRolePermissions("view", moduleName.companyGuideline),
      },
      {
        icon: <span className="material-symbols-outlined">Newspaper</span>,
        menuName: "Notice/Circular",
        path: "notices",
        display:
          checkRolePermissions("view", moduleName.notice) ||
          checkRolePermissions("viewAll", moduleName.notice),
      },
      {
        icon: (
          <span className="material-symbols-outlined">
            settings_applications
          </span>
        ),
        menuName: "Company Setting",
        path: "companySetting",
        display: checkRolePermissions("view", moduleName.companySetting),
      },
      {
        icon: <span className="material-symbols-outlined">cake</span>,
        menuName: "Birthday Configuration",
        path: "birthdayConfig",
        display: checkRolePermissions("view", moduleName.birthdayConfiguration),
      },

      {
        icon: <span className="material-symbols-outlined">mail</span>,
        menuName: "Group Email",
        path: "groupEmail",
        display: checkRolePermissions("view", moduleName.groupEmail),
      },
      {
        icon: (
          <span className="material-symbols-outlined">assignment_late</span>
        ),
        menuName: isAdmin ? "Leave Settings" : "Leave Request",
        path: "",
        display: true,
        subMenus: [
          {
            // icon: <span className="material-symbols-outlined">rule_folder</span>,
            menuName: "Leave Configuration",
            path: "leaveConfiguration",
            // display: true
            display: checkRolePermissions(
              "view",
              moduleName.leaveConfiguration
            ),
          },
          {
            // icon: <span className="material-symbols-outlined">hive</span>,
            menuName: "Leave Category",
            path: "addLeaveCategory",
            display: checkRolePermissions("view", moduleName.leaveCategory),
          },
          {
            // icon: <span className="material-symbols-outlined">note_add</span>,
            menuName: "Leave Form",
            path: "createLeave",
            display: true,
            // display: checkRolePermissions("store", moduleName.leave)
          },
          {
            // icon: <span className="material-symbols-outlined">preview</span>,
            menuName: "View All Leaves",
            path: "viewLeave",
            display: checkRolePermissions("view", moduleName.allLeaves),
          },
          {
            // icon: <span className="material-symbols-outlined">preview</span>,
            menuName: "View Requested Leaves",
            path: "viewRequestedLeave",
            display: checkRolePermissions("view", moduleName.requestedLeaves),
          },
        ],
      },
      {
        icon: (
          <span className="material-symbols-outlined">lab_profile</span>
        ),
        menuName: "Evaluation",
        path: "evaluation",
        display: true,
      },

      {
        icon: <span className="material-symbols-outlined">bug_report</span>,
        menuName: "Feedback / Complain",
        path: "",
        display: true,
        subMenus: [
          {
            menuName: "Give a Feedback / Complain",
            path: "reportProblem",
            display: true,
          },
          {
            menuName: "List",
            path: "feedbacks",
            display: checkRolePermissions("view", moduleName.feedbackComplain),
            // display: true,
          },
        ],
      },

      // {
      //   icon: <span className="material-symbols-outlined">gate</span>,
      //   menuName: "Gate Pass",
      //   path: "",
      //   display: true,
      //   subMenus: [
      //     {
      //       menuName: "Create GatePass",
      //       path: "gatePass",
      //       display: true,
      //     },

      //     {
      //       menuName: "View All GatePass",
      //       path: "viewGatePass",
      //       display: checkRolePermissions("view", moduleName.allGatePass),
      //       // display: true,
      //     },
      //     {
      //       menuName: "View Requested GatePass",
      //       path: "viewRequestedGatePass",
      //       display: checkRolePermissions("view", moduleName.requestedGatePass),
      //     },
      //   ],
      // },

      // {
      //   icon: <span className="material-symbols-outlined">add_alarm</span>,
      //   menuName: "OverTime Card",
      //   path: "",
      //   display: true,
      //   subMenus: [
      //     {
      //       menuName: "My OverTime Card",
      //       path: "overTime",
      //       display: true,
      //     },
      //     {
      //       menuName: "View All OverTime",
      //       path: "viewOverTime",
      //       display: checkRolePermissions("view", moduleName.overTimeCard),
      //       // display: true,
      //     },
      //     {
      //       menuName: "View Requested Overtimes",
      //       path: "requestedOverTime",
      //       display: checkRolePermissions("view", moduleName.requestedOvertime),
      //     },
      //   ],
      // },
      // {
      //   icon: <span className="material-symbols-outlined">Inventory</span>,
      //   menuName: "Inventory Management",
      //   path: "",
      //   display: true,
      //   subMenus: [
      //     {
      //       menuName: "Vendor",
      //       path: "vendor",
      //       display: true,
      //     },
      //     {
      //       menuName: "Requistion",
      //       path: "requistion",
      //       display: true,
      //     },
      //     {
      //       menuName: "Items",
      //       path: "items",
      //       display: true,
      //     },
      //   ],
      // },
      {
        icon: <span className="material-symbols-outlined">quiz</span>,
        menuName: "FAQ",
        path: "FAQ",
        // display:
        //   checkRolePermissions("view", moduleName.faq) ||
        //   CryptoJS.AES.decrypt(localStorage.getItem("roles"), cryptoSecretKey)
        //     .toString(CryptoJS.enc.Utf8)
        //     .includes("Developer Admin"),
        display: true,
      },

      {
        icon: <span className="material-symbols-outlined">logout</span>,
        menuName: "Log Out",
        path: "/",
        display: true,
      },
    ],
  };

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = () => {
    axiosGet(
      URL.userInfo,
      (response) => {
        if (response.data.success) {
          // localStorage.setItem(
          //   "needsPasswordChange",
          //   response.data.data.item.need_to_be_filled
          // );
          this.setState({ userInfo: response.data.data.item });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar menus={this.state.menus} />
        <div style={{ flex: 1 }}>
          <Header userInfo={this.state.userInfo}></Header>
          <DashboardRoutes></DashboardRoutes>
        </div>
      </div>
    );
  }
}

export default DashboardTemplate;
