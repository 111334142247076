import React from "react";
import Select from "react-select";
import Checkbox from "../../components/common/Checkbox";

const CreateNoticeForm = (props) => {
  const {
    handleChange,
    selectedData,
    company,
    department,
    departmentStaff,
    type,
    handleTextChange,
    title,
    description,
    noticeType,
    handleStaffChange,
    staffValue,
    sendAll,
    setSendAll,
  } = props;

  return (
    <>
      <div className="whiteContainer p-4">
        <div className="row">
          <div className="col">
            <Checkbox
              handleChange={() => setSendAll(!sendAll)}
              name="sendAll"
              checked={sendAll}
            />
            <label className="form-check-label" htmlFor="checkMe">
              Send All
            </label>
          </div>
        </div>
        <div className="row ">
          {/* {isParentCompany ? ( */}
          <div className="col-md-4" style={{ position: "relative" }}>
            <label className="floating">Company</label>
            <select
              className="formSelect"
              name="company"
              onChange={handleChange}
              value={selectedData ? selectedData.company : ""}
              disabled={sendAll}
            >
              <option value="" disabled selected>
                Choose Company
              </option>
              {company?.map((cmp, idx) => (
                <option value={cmp?.id} key={idx}>
                  {cmp?.name}
                </option>
              ))}
            </select>
          </div>
          {/* ) : null} */}

          <div className="col-md-4" style={{ position: "relative" }}>
            <label className="floating">Department</label>
            <select
              className="formSelect"
              name="department"
              onChange={handleChange}
              disabled={sendAll}
              value={
                selectedData?.department === undefined
                  ? ""
                  : selectedData.department
              }
            >
              <option value="" disabled selected>
                Choose Department
              </option>
              {department?.map((dpt, idx) => (
                <option value={dpt?.id} key={idx}>
                  {dpt?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4" style={{ position: "relative" }}>
            <label className="floating">Department staff</label>
            <Select
              isMulti={true}
              className="formSelect"
              name="departmentStaff"
              onChange={(e) => handleStaffChange(e)}
              value={staffValue}
              options={departmentStaff}
              isDisabled={sendAll}
            ></Select>
          </div>

          <div className="col-md-4 text-start" style={{ position: "relative" }}>
            <label className="floating">
              Title<span className="required">*</span>{" "}
            </label>
            <input
              type="text"
              style={myInput}
              className="form-control"
              name="title"
              value={title}
              onChange={handleTextChange}
            ></input>
          </div>

          <div className="col-md-4 text-start" style={{ position: "relative" }}>
            <label className="floating">
              Type<span className="required">*</span>{" "}
            </label>
            <select
              type="text"
              style={myInput}
              className="formSelect"
              name="type"
              value={noticeType}
              onChange={handleTextChange}
            >
              <option value="" disabled selected>
                Choose Type
              </option>
              {type &&
                type.map((items, index) => (
                  <option value={items.value} key={index}>
                    {items.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-4 text-start" style={{ position: "relative" }}>
            <label className="floating">
              Upload
              {/* <span className="required">*</span>{" "} */}
            </label>
            <input
              type="file"
              style={myInput}
              className="form-control"
              name="files"
              onChange={handleTextChange}
            ></input>
          </div>

          <div className="col-md-6 text-start" style={{ position: "relative" }}>
            <label className="floating">
              Description <span className="required">*</span>{" "}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="description"
              style={myInput}
              onChange={handleTextChange}
              value={description}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px",
};
export default CreateNoticeForm;
