import React, { useState, useEffect } from "react";
import CommonModal from "../../components/common/Modal";
import "../../assets/scss/btn.scss";
import Button from "../../components/common/Button";
import CompanyForm from "./CompanyForm/CompanyForm";
import CompanyList from "./CompanyList";
import { axiosDelete, axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { URL, moduleName } from "../../utils/Constants";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import CompanyView from "./CompanyView";
import { checkRolePermissions } from "../../utils/PermissionChecker";

const Company = () => {
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [companyToBeViewed, setCompanyToBeViewed] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  // const [industryData, setIndustryData] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [editableCmp, setEditableCmp] = useState(null);
  const [companyFiles, setCompanyFiles] = useState(null);
  const [editParam, setEditParam] = useState({
    selectedIndex: null,
    isEdit: false,
  });

  useEffect(() => {
    if (companyData !== null) {
      const data = JSON.parse(JSON.stringify(companyData));
      setEditableCmp(data);
    }
  }, [companyData]);

  useEffect(() => {
    if (editParam.isEdit === true) {
      const reg = editableCmp[editParam.selectedIndex]?.media?.find(
        (el) => el.collection_name === "company_registration"
      );
      reg &&
        setCompanyFiles((prevState) => ({ ...prevState, registration: reg }));
    }
  }, [editParam && editParam?.isEdit]);

  const toggleModal = () => {
    const { isEdit } = editParam;
    if (modal && isEdit) {
      setEditParam({ selectedIndex: null, isEdit: false });
      setCompanyFiles(null);
      const data = JSON.parse(JSON.stringify(companyData));
      setEditableCmp(data);
    }
    if (modal && isView) {
      setIsView(false);
    }

    setModal(!modal);
  };
  useEffect(() => {
    getCompany();
    // getIndustry();
  }, []);

  // const getIndustry = () => {
  //   axiosGet(
  //     URL.getAllIndustry,
  //     response => {
  //       if (response.data.success) {
  //         let modules = response.data.data.items;
  //         setIndustryData(modules);
  //       }
  //     },
  //     err => {
  //       displayErrorAlert(err);
  //     }
  //   );
  // };
  const getCompany = () => {
    setIsLoading(true);
    axiosGet(
      URL.getRespectiveCompany,
      (response) => {
        setIsLoading(false);
        if (response.data.success) {
          let modules = response.data.data.items;
          setCompanyData(modules);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleDeleteCompany = (e, index, enable) => {
    swal({
      title: enable === 0 ? "Enable Company?" : "Disable Company?",
      text:
        enable === 0
          ? "Are you sure, you want to enable this company? "
          : "Are you sure, you want to disable this company? Disabling the company will also disable all its users associated with it.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const params = {
          id: index,
        };
        setSubmitSpinner(true);
        //need to fix the provided api -to destroy the company
        axiosDelete(
          URL.deleteCompany,
          { data: params },
          (response) => {
            if (response.data.success) {
              setSubmitSpinner(false);
              if (enable === 0) {
                swal("Success", "Company enabled successfully", "success");
              } else {
                swal("Success", "Company disabled successfully", "success");
              }
              getCompany();
            }
          },
          (err) => {
            displayErrorAlert(err);
            setSubmitSpinner(false);
          }
        );
      }
    });
  };

  const handleEditCompany = (e, index) => {
    setEditParam((prevState) => ({
      ...prevState,
      selectedIndex: index,
      isEdit: true,
    }));
    toggleModal();
  };

  const handleViewCompany = (e, index) => {
    let companyToBeViewed = companyData[index];
    let arrCmp = [companyToBeViewed];
    setCompanyToBeViewed(arrCmp);
    setIsView(true);
    toggleModal();
  };

  const handleRemoveCmpFile = (e, status) => {
    e.preventDefault();
    const cloneFileName = { ...companyFiles };
    status in cloneFileName && delete cloneFileName[status];
    setCompanyFiles(cloneFileName);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Company</div>
      </div>
      <div className="container-fluid m-0 p-0" style={{ height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}
        {checkRolePermissions("store", moduleName.company) ? (
          <div className="">
            <Button name="Add Company" onClick={toggleModal} />
          </div>
        ) : null}

        <CompanyList
          companyData={companyData}
          handleDelete={handleDeleteCompany}
          handleEdit={handleEditCompany}
          handleView={handleViewCompany}
          isLoading={isLoading}
        />
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="xl"
        modalHeader={
          editParam && editParam?.isEdit
            ? "Edit a Company"
            : isView && isView
            ? " Company Details "
            : "Add a Company"
        }
        modalBody={
          <>
            {isView && isView ? (
              <CompanyView companyToBeViewed={companyToBeViewed} />
            ) : (
              <CompanyForm
                toggleModal={toggleModal}
                getCompany={getCompany}
                companyData={companyData}
                setCompanyData={setCompanyData}
                editParam={editParam}
                // industryData={industryData}
                editableCmp={editableCmp}
                setEditableCmp={setEditableCmp}
                companyFiles={companyFiles}
                handleRemoveCmpFile={handleRemoveCmpFile}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default Company;
