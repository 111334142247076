import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faTimesCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

const BranchList = (prop) => {
  const { branchData } = prop;
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 ml-auto mr-auto">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">SN.</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Contact Number</th>
                    <th className="text-right">Website</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {branchData && branchData.length > 0 ? (
                    branchData.map((cmp, id) => {
                      return (
                        <tr key={id}>
                          <td className="text-center">{id + 1}</td>
                          <td>{cmp.name}</td>
                          <td>{cmp.email_address}</td>
                          <td>{cmp.contact_number}</td>
                          <td className="text-right">
                            {" "}
                            {cmp.domain_name === ""
                              ? "Not available"
                              : cmp.domain_name}
                          </td>
                          <td className="td-actions text-right">
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-success btn-just-icon btn-sm"
                              data-original-title=""
                              title=""
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                //   onClick={e => handleEdit(e, id)}
                              />
                            </button>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-danger btn-just-icon btn-sm ml-2"
                              style={{ marginLeft: "0.75rem" }}
                              data-original-title=""
                              title=""
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                //   onClick={e => handleDelete(e, cmp.id)}
                              />
                            </button>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-primary btn-just-icon btn-sm ml-2"
                              style={{ marginLeft: "0.75rem" }}
                              data-original-title=""
                              title=""
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                //   onClick={e => handleView(e, id)}
                              />
                            </button>

                            <a
                              href={URL.downloadCompanyZip + "/" + cmp.id}
                              target="_blank"
                            >
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-primary btn-just-icon btn-sm ml-2"
                                style={{ marginLeft: "0.75rem" }}
                                data-original-title=""
                                title=""
                              >
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={7}>No Branch Found </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchList;
