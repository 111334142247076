import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import { Spinner } from "reactstrap";
import CommonModal from "../../components/common/Modal";
import { checkModulePermission } from "../../utils/PermissionChecker";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import swal from "sweetalert";
import { URL } from "../../utils/Constants";
import moment from "moment";
import Pagination from "react-js-pagination";

const GatePass = () => {
  const [modal, setModal] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [gatePassData, setGatePassData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [gatePassDate, setGatePassDate] = useState(null);
  // const [activeModule, setActiveModule] = useState("main");

  useEffect(() => {
    fetchGatePass();
    // return () => {};
  }, [gatePassDate]);

  useEffect(() => {
    fetchGatePass();
    let value = checkModulePermission("gatePass");
    setAssignedPermission(value);
  }, []);
  const fetchGatePass = (pageNumber = 1) => {
    setIsFetching(true);
    setGatePassData(null);
    axiosPost(
      URL.gatePassFilterByDate + "?" + "page=" + pageNumber,
      {
        pass_from: gatePassDate?.pass_from ?? null,
        pass_to: gatePassDate?.pass_to ?? null,
      },
      (res) => {
        if (res.status === 200) {
          setIsFetching(false);
          let modules = res.data;
          setGatePassData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
      }
    );
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const GatePassList = () => {
    if (gatePassData) {
      var { current_page, per_page, total } = gatePassData;
    }
    return (
      <>
        <div className="row">
          <div className="col-lg-12 col-md-12 ml-auto mr-auto">
            <div className="table-responsive whiteContainer p-4">
              <table className="table table-sm table-custom">
                <thead>
                  <tr>
                    <th className="text-center">SN.</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Time From</th>
                    <th className="text-center">Time To</th>
                    <th className="text-center">Reason</th>
                    <th className="text-center">Duration(Hours)</th>
                    <th className="text-center">Approved By</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {gatePassData?.data && gatePassData?.data?.length > 0 ? (
                    gatePassData.data.map((gP, id) => (
                      <tr key={id}>
                        <td className="text-center">{id + 1}</td>
                        <td className="text-center">{gP.pass_date}</td>
                        <td className="text-center">
                          {moment(gP.time_from, "HH:mm:ss").format("hh:mm A")}
                        </td>
                        <td className="text-center">
                          {moment(gP.time_to, "HH:mm:ss").format("hh:mm A")}
                        </td>
                        <td className="text-center">{gP.reason}</td>
                        <td className="text-center">
                          {`0${(gP.duration / 60) ^ 0}`.slice(-2) +
                            ":" +
                            ("0" + (gP.duration % 60)).slice(-2)}
                        </td>
                        <td className="text-center">
                          {gP?.approve_by?.first_name ?? "-"}
                        </td>
                        <td className="text-center">{gP.approval_status}</td>
                        {/* <td className="text-center">
                            {gP.approved_by === 0 ? gP.user?.first_name : "-"}
                          </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={8}>
                        {isFetching ? (
                          <div className="text-center">
                            <Spinner color="whiteSmoke" size="sm" />
                          </div>
                        ) : (
                          "No Gate Pass found"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {gatePassData?.total <= 5 ? (
                <></>
              ) : (
                <div className="paginationContainer">
                  <Pagination
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={total}
                    onChange={(pageNumber) => fetchGatePass(pageNumber)}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const GatePassForm = () => {
    const [formData, setFormData] = useState(null);
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitSpinner(true);
      axiosPost(
        URL.gatePass,
        formData,
        (response) => {
          if (response.status === 200) {
            toggleModal();
            fetchGatePass();
            setFormData(null);
            swal("Success", "Gate Pass requested succesfully", "success");
            setSubmitSpinner(false);
          }
        },
        (err) => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
        }
      );
    };
    return (
      <>
        <form onSubmit={handleSubmit} id="msform">
          <div className="row">
            <div className="col text-start">
              <label className="floating">Date</label>
              <input
                required
                type="date"
                name="pass_date"
                className="form-control"
                onChange={handleChange}
                value={formData?.pass_date}
                min={new Date().toLocaleDateString("en-ca")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-start">
              <label className="floating">Time From</label>
              <input
                required
                type="time"
                name="time_from"
                className="form-control"
                onChange={handleChange}
                value={formData?.time_from}
              />
            </div>
            <div className="col text-start">
              <label className="floating">Time To</label>
              <input
                required
                type="time"
                name="time_to"
                min={formData?.time_from}
                className="form-control"
                onChange={handleChange}
                value={formData?.time_to}
              />
            </div>
          </div>

          <div className="row">
            <div className="col text-start">
              <label className="floating">Reason</label>
              <textarea
                required
                name="reason"
                value={formData?.reason}
                onChange={handleChange}
                className="form-control"
                type="text"
              />
            </div>
          </div>
          <div style={{ float: "right", marginTop: "0.5rem" }}>
            <Button name={"Submit"} type="submit" />
          </div>
        </form>
      </>
    );
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setGatePassDate((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Gate Pass</div>
      </div>
      <div
        className="container-fluid m-0 p-0"
        style={{ padding: "1rem", height: "100%" }}
      >
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        {/* {assignedPermission?.store && ( */}
        <div className="">
          <Button name="Add Gate Pass" onClick={toggleModal} />
        </div>
        {/* )} */}
        <div className="whiteContainer p-4">
          <div className="row m-0 justify-content-center">
            <h3 className="text-center sectionTitle pb-2">Search</h3>

            <div className="col text-start" style={{ position: "relative" }}>
              <label className="floating">From</label>
              <input
                type="date"
                style={myInput}
                className="form-control"
                name="pass_from"
                value={gatePassDate?.pass_from}
                onChange={handleDateChange}
              ></input>
            </div>

            <div className="col text-start" style={{ position: "relative" }}>
              <label className="floating">To</label>
              <input
                type="date"
                style={myInput}
                value={gatePassDate?.pass_to}
                name="pass_to"
                className="form-control"
                min={gatePassData?.pass_from}
                onChange={handleDateChange}
              ></input>
            </div>
          </div>
        </div>
        <GatePassList />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        // modalHeader={isUpdate?.faqToBeUpdated ? "Update FAQ" : "Add FAQ"}
        modalHeader={"Add Gate Pass"}
        size="lg"
        modalBody={
          <div className="container p-0 m-0">
            <GatePassForm />
          </div>
        }
      />
    </>
  );
};
const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px",
};

export default GatePass;
