import React from "react";
import {
  faEdit,
  faArrowAltCircleDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "reactstrap";

const ItemsList = props => {
  const {
    itemsList,
    toggleSubRow,
    toggleItemsRow,
    setIsEdit,
    toggleModal,
    setIsEditCategory,
    setIsEditSubCategory,
    setIsEditSubItems,
   
  } = props;

  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {itemsList && itemsList.length > 0
              ? itemsList?.map((items, id) => {
                  return (
                    <>
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{items.name ? items.name : "-"}</td>

                        <td>
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-secondary btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="update"
                            onClick={e => {
                              setIsEdit(true);
                              setIsEditCategory(true);
                              setIsEditSubCategory(false);
                              setIsEditSubItems(false);
                              toggleModal(items);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-secondary btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="update"
                            onClick={() => {
                              toggleSubRow(id);
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowAltCircleDown} />
                          </button>
                        </td>
                      </tr>
                      <tr colSpan={3}>
                        <td colSpan={3}>
                          {items.sub_categories && items.sub_categories.length
                            ? items.sub_categories.map((item, idx) => {
                                return (
                                  <>
                                    <Collapse isOpen={items.collapse}>
                                      <div className="px-4">
                                        <table className="table  table-sm table-custom ">
                                          <thead>
                                            <th>S.N.</th>
                                            <th>Sub Category</th>
                                            <th>Actions</th>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>{idx + 1}</td>
                                              <td>{item.name}</td>

                                              <td>
                                                <button
                                                  type="button"
                                                  rel="tooltip"
                                                  className="btn btn-secondary btn-just-icon btn-sm"
                                                  style={{
                                                    marginLeft: "0.75rem"
                                                  }}
                                                  data-original-title=""
                                                  title="update"
                                                  onClick={e => {
                                                    setIsEdit(true);
                                                    setIsEditSubCategory(true);
                                                    setIsEditCategory(false);
                                                    setIsEditSubItems(false);
                                                    toggleModal(items);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEdit}
                                                  />
                                                </button>
                                                <button
                                                  type="button"
                                                  rel="tooltip"
                                                  className="btn btn-secondary btn-just-icon btn-sm"
                                                  style={{
                                                    marginLeft: "0.75rem"
                                                  }}
                                                  data-original-title=""
                                                  title="update"
                                                  onClick={() => {
                                                    toggleItemsRow({ id, idx });
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faArrowAltCircleDown}
                                                  />
                                                </button>
                                              </td>
                                            </tr>
                                            <tr colSpan="3">
                                              <td colSpan="3">
                                                <Collapse
                                                  isOpen={item.collapse}
                                                >
                                                  {item?.inventory_items &&
                                                  item?.inventory_items
                                                    ?.length > 0
                                                    ? item?.inventory_items?.map(
                                                        (values, index) => {
                                                          return (
                                                            <>
                                                              <div className="px-5">
                                                                <table className="table  table-sm table-custom">
                                                                  <thead>
                                                                    <th>
                                                                      S.N.
                                                                    </th>
                                                                    <th>
                                                                      Sub
                                                                      Category
                                                                    </th>
                                                                    <th>
                                                                      Actions
                                                                    </th>
                                                                  </thead>
                                                                  <tbody>
                                                                    <tr>
                                                                      <td>
                                                                        {index +
                                                                          1}
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          values.name
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <button
                                                                          type="button"
                                                                          rel="tooltip"
                                                                          className="btn btn-secondary btn-just-icon btn-sm"
                                                                          style={{
                                                                            marginLeft:
                                                                              "0.75rem"
                                                                          }}
                                                                          data-original-title=""
                                                                          title="update"
                                                                          onClick={e => {
                                                                            setIsEdit(
                                                                              true
                                                                            );
                                                                            setIsEditSubItems(
                                                                              true
                                                                            );
                                                                            setIsEditCategory(
                                                                              false
                                                                            );
                                                                            setIsEditSubCategory(
                                                                              false
                                                                            );
                                                                            toggleModal(
                                                                              items
                                                                            );
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            icon={
                                                                              faEdit
                                                                            }
                                                                          />
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    : "-"}
                                                </Collapse>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </Collapse>
                                  </>
                                );
                              })
                            : ""}
                        </td>
                      </tr>
                    </>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ItemsList;
