import React from "react";
import FileViewer from "react-file-viewer";
import ErrorFallback from "../../utils/ErrorFallback";

function CustomFileViewer(props) {
  const imgTypes = ["png", "jpeg", "jpg", "tiff"];
  return (
    <div className="col text-center">
      {imgTypes.includes(props.fileExtension) ? (
        <img
          src={props.filePath}
          style={{ position: "relative", width: "41vw" }}
        />
      ) : props.fileExtension && props.filePath ? (
        <FileViewer
          fileType={props.fileExtension}
          filePath={props.filePath}
          errorComponent={ErrorFallback}
        />
      ) : (
        // <embed
        //   src={props.filePath}
        //   width="900"
        //   height="475"
        //   type={`application/${props.fileExtension}`}
        // ></embed>
        // <iframe src="https://docs.google.com/viewer?url={props.filePath}&embedded=true"></iframe>
        // <iframe
        //   src={`https://docs.google.com/viewer?url=${props.filePath}&embedded=true`}
        // ></iframe>
        // <iframe src={props.filePath} width="300" height="200"></iframe>
        // <object data={props.filePath} width="300" height="200"></object>
        <div>Loading...</div>
      )}
    </div>
  );
}

export default CustomFileViewer;
