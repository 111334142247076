import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import swal from "sweetalert";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

export const ListLeaveConfiguration = ({
  leaveConfigData,
  isLoading,
  getMailAddress,
  setMailAddress,
  originalLeaveConfigData,
}) => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [status, setStatus] = useState(false);
  const checkboxRef = useRef(null);

  // const originalLeaveConfigData = JSON.parse(JSON.stringify(leaveConfigData));

  useEffect(() => {
    let update = checkRolePermissions("update", moduleName.leaveConfiguration);
    setUpdatePermission(update);
  }, []);

  const handleDelete = (leave, status) => {
    setSubmitSpinner(true);
    let url = status === "To" ? URL.deleteSendToMail : URL.deleteCCMail;
    axiosPost(
      url,
      { id: leave.id },
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          getMailAddress();
          swal(
            "Success",
            `${
              status === "To"
                ? "Configured sendTo mail deleted successfully"
                : "Configured CC mail deleted successfully"
            }`,
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleUpdateDescription = (desc) => {
    setSubmitSpinner(true);
    let params = { description: desc.has_additional_description };
    axiosPost(
      URL.updateDescription,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          getMailAddress();
          checkboxRef.current.disabled = true;
          setStatus(false);
          swal(
            "Success",
            "Additional description  updated successfully",
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  const handleCheckboxChange = (e) => {
    const cloneLeaveConfigData = { ...leaveConfigData };
    cloneLeaveConfigData.additional_description.has_additional_description =
      e.target.checked;
    setMailAddress(cloneLeaveConfigData);
  };

  return (
    <div>
      {submitSpinner ? (
        <div style={{ padding: "1rem", height: "100%" }}>
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        </div>
      ) : null}
      {leaveConfigData && leaveConfigData?.additional_description ? (
        <div className="row">
          <div className="col-12">
            <div className="whiteContainer p-4">
              <div className="row">
                <div className="col">
                  <input
                    ref={checkboxRef}
                    type="checkbox"
                    name="additionDetails"
                    disabled
                    checked={
                      leaveConfigData.additional_description
                        .has_additional_description
                    }
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="newTextColor ml-2"
                    style={{ position: "relative", top: "-8px" }}
                  >
                    Required Additional Description
                  </label>
                </div>

                {updatePermission && !status && (
                  <div className="col-2 table-custom">
                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-outline-secondary rounded-circle btn-sm me-2"
                      data-original-title=""
                      title="Edit"
                      onClick={() => {
                        setStatus(true);
                        checkboxRef.current.autoFocus = true;
                        checkboxRef.current.disabled = false;
                        // checkboxRef.current.checked = false;
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </button>
                    {/* <Button
                      name="Edit"
                      onClick={() => {
                        setStatus(true);
                        checkboxRef.current.autoFocus = true;
                        checkboxRef.current.disabled = false;
                        // checkboxRef.current.checked = false;
                      }}
                    /> */}
                  </div>
                )}

                {updatePermission && status && (
                  <>
                    <div className="col-2">
                      <Button
                        name="Cancel"
                        onClick={() => {
                          setMailAddress(originalLeaveConfigData);
                          setStatus(false);
                          checkboxRef.current.disabled = true;
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        name="Submit"
                        onClick={() =>
                          handleUpdateDescription(
                            leaveConfigData.additional_description
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-lg-6 col-md-6 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th style={{ width: "80px" }}>SN.</th>
                  <th>Email To</th>
                  {updatePermission && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {leaveConfigData && leaveConfigData?.to?.length > 0 ? (
                  leaveConfigData?.to?.map((leave, id) => {
                    return (
                      <React.Fragment key={id}>
                        <tr>
                          <td>{id + 1}</td>
                          <td>{leave.send_to_mail}</td>
                          {updatePermission ? (
                            <td>
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-danger rounded-circle btn-sm me-2"
                                data-original-title=""
                                title="Delete"
                                onClick={() => handleDelete(leave, "To")}
                              >
                                <i className="material-symbols-outlined">
                                  delete
                                </i>
                              </button>
                              {/* <Button
                                name="Delete"
                                onClick={() => handleDelete(leave, "To")}
                              /> */}
                            </td>
                          ) : null}
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={3}>
                      {isLoading ? (
                        <LoadingAnimation spinner />
                      ) : (
                        "No Leave Configuration found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th style={{ width: "80px" }}>SN.</th>
                  <th>Mandatory CC </th>
                  {updatePermission && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {leaveConfigData && leaveConfigData?.cc?.length > 0 ? (
                  leaveConfigData?.cc?.map((leave, id) => {
                    return (
                      <React.Fragment key={id}>
                        <tr>
                          <td>{id + 1}</td>
                          <td>{leave.send_cc_to_mail}</td>
                          {updatePermission ? (
                            <td>
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-danger rounded-circle btn-sm me-2"
                                data-original-title=""
                                title="Delete"
                                onClick={() => handleDelete(leave, "CC")}
                              >
                                <i className="material-symbols-outlined">
                                  delete
                                </i>
                              </button>
                              {/* <Button
                                name="Delete"
                                onClick={() => handleDelete(leave, "CC")}
                              /> */}
                            </td>
                          ) : null}
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={3}>
                      {isLoading ? (
                        <LoadingAnimation spinner />
                      ) : (
                        "No Leave Configuration found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
