import React, { useState, useEffect } from "react";
import CompanyGuidelineList from "./CompanyGuidelineList";
import CommonModal from "../../components/common/Modal";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import CompanyGuidelineForm from "./CompanyGuidelineForm";
import Button from "../../components/common/Button";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CompnayGuidelineView from "./CompnayGuidelineView";
import { checkModulePermission } from "../../utils/PermissionChecker";

const CompanyGuideline = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [companyGuideline, setCompanyGuideline] = useState(null);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [type, setType] = useState("Company Guideline");

  const toggleModal = () => {
    // if (modal && isView) {
    //   setIsView(false);
    // }
    setModal(!modal);
  };

  const toggleModalView = () => {
    setIsView(!isView);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    let value = checkModulePermission("companyGuideline");
    if (value?.view) {
      getCompanyGuideline("Company Guideline");
    }
    setAssignedPermission(value);
  }, []);

  const getCompanyGuideline = (type) => {
    setisLoading(true);
    const params = {
      type: type,
    };
    axiosPost(
      URL.getCompanyGuideline,
      params,
      (response) => {
        setisLoading(false);
        if (response.data.success) {
          setCompanyGuideline(response.data.data.items);
        }
      },
      (err) => {
        setisLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    setisLoading(false);
    setCompanyGuideline(null);
    getCompanyGuideline(type);
  }, [type]);

  const handleViewCompanyGuideline = () => {
    setIsView(true);
    // toggleModal();
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Company Guideline/Manual</div>
      </div>
      <div className="container-fluid m-0 p-0" style={{ height: "100%" }}>
        {/* {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null} */}

        {assignedPermission && assignedPermission?.store ? (
          <div className="">
            <Button
              name="Add Company Guideline/Manual "
              onClick={toggleModal}
            />
          </div>
        ) : null}

        <div className="row no-gutters cborder p-4">
          <div className=" col-12">
            <div className="p-0 border-bottom-0">
              <Nav tabs fill>
                <NavItem>
                  <NavLink
                    // activeTab={activeTab}
                    active={activeTab == "1"}
                    onClick={() => {
                      toggle("1");
                      setType("Company Guideline");
                      // getCompanyGuideline("Company Guideline");
                    }}
                    className="tab-text"
                    style={{ cursor: "pointer", color: "#495057" }}
                  >
                    Company GuideLine
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab == "2"}
                    onClick={() => {
                      toggle("2");
                      setType("HR Manual");
                      // getCompanyGuideline("HR Manual");
                    }}
                    className="tab-text"
                    style={{ cursor: "pointer", color: "#495057" }}
                  >
                    HR Manual
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <CompanyGuidelineList
                  companyGuideline={companyGuideline}
                  // handleDelete={handleDeleteCompany}
                  handleView={handleViewCompanyGuideline}
                  isLoading={isLoading}
                  type="Company Guideline"
                />
              </TabPane>
              <TabPane tabId="2">
                <CompanyGuidelineList
                  companyGuideline={companyGuideline}
                  // handleDelete={handleDeleteCompany}
                  handleView={handleViewCompanyGuideline}
                  isLoading={isLoading}
                  type="HR Manual"
                />
              </TabPane>
            </TabContent>
          </div>
        </div>

        <CommonModal
          modal={modal}
          toggleModal={toggleModal}
          size="xl"
          modalHeader={"Add a Company Guideline"}
          modalBody={
            <CompanyGuidelineForm
              toggleModal={toggleModal}
              getCompanyGuideline={getCompanyGuideline}
              companyGuideline={companyGuideline}
              type={type}
              toggle={toggle}
            />
          }
        />

        <CommonModal
          modal={isView}
          toggleModal={toggleModalView}
          size="xl"
          modalHeader={"Company Guideline"}
          modalBody={
            <>
              <CompnayGuidelineView companyGuideline={companyGuideline} />
            </>
          }
        />
      </div>
    </>
  );
};

export default CompanyGuideline;
