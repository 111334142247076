import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import {
  bytesToMegaBytes,
  URL,
  cryptoSecretKey,
  GUIDELINE_TYPE,
} from "../../utils/Constants";
import swal from "sweetalert";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkIfEmpty, capitalizeFirstLetter } from "../../utils/Validation";
import parse from "html-react-parser";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";
import { fileUrl } from "../../utils/Constants";
import { faUpload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AcceptCompanyGuidelines = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [companyGuideline, setCompanyGuideline] = useState(null);
  const [acceptData, setAcceptData] = useState(null);
  const [signature, setSignature] = useState(null);

  //roles

  const guideLineRequire = JSON.parse(
    localStorage.getItem("isGuidelineRequired")
  );
  const isAllStaffInfoFilled = JSON.parse(
    localStorage.getItem("isAllStaffInfoFilled")
  );
  const data = localStorage.getItem("roles");
  const userRole = ["Company Admin"];
  const roles = JSON.parse(
    CryptoJS.AES.decrypt(data, cryptoSecretKey).toString(CryptoJS.enc.Utf8)
  );

  const handleCancel = () => {
    localStorage.setItem("guideLineAccepted", true);
    if (!isAllStaffInfoFilled) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/myProfile`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
  };

  useEffect(() => {
    getCompanyGuideline();
    /* Setting the state of signature. */
    getSignature();
  }, []);

  const getSignature = () => {
    axiosGet(
      URL.getDigitalSignature,
      (response) => {
        let modules = null;
        if (response.data.data.items.length > 0) {
          modules = response?.data?.data?.items;
        }
        setSignature(modules);
      },
      (err) => {}
    );
  };

  const getCompanyGuideline = () => {
    setIsLoading(true);
    axiosPost(
      URL.getCompanyGuideline,
      { type: GUIDELINE_TYPE.COMPANY },
      (response) => {
        setIsLoading(false);
        if (response?.data?.data === null) {
          navigate(`/contactSupport`);
        }
        let modules = response.data.data.items;
        if (modules) {
          modules = modules.find((m) => m.is_active === 1);
        }
        setCompanyGuideline(modules);
      },
      (err) => {
        setIsLoading(false);
        navigate(`/contactSupport`);
        // displayErrorAlert(err);
      }
    );
  };
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
        swal(
          "warning",
          "File size must be equal or less than 4 MB.",
          "warning"
        );
        return;
      }
      if (files && files[0]) {
        setAcceptData((prevState) => ({ ...prevState, [name]: files[0] }));
      }
    } else {
      setAcceptData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!acceptData) {
      swal("Warning", "Field's are empty", "warning");
      return;
    }
    if (!userRole.includes(roles[0])) {
      if (signature === null && Object.keys(acceptData).length < 2) {
        swal("Warning", "All field's are mandatory", "warning");
        return;
      }

      //check the required field is present or not
      const keyCheck = checkIfEmpty(acceptData);
      if (keyCheck?.isEmpty) {
        let msg = capitalizeFirstLetter(keyCheck?.emptyKey);
        swal("Warning", `${msg} field missing`, "warning");
        return;
      }

      if (!acceptData?.accepted) {
        swal(
          "Warning",
          "You must accept the company Guidelines to move forward",
          "warning"
        );
        return;
      }
    } else {
      if (!acceptData?.accepted) {
        swal(
          "Warning",
          "You must accept the company Guidelines to move forward",
          "warning"
        );
        return;
      }
    }

    let formData = new FormData();

    let guideline = {
      accepted: acceptData && acceptData?.accepted ? 1 : 0,
      companyGuidelineId: companyGuideline && companyGuideline.id,
    };

    const guidelineParam = JSON.stringify(guideline);
    formData.append("guideline", guidelineParam);

    {
      signature !== null
        ? formData.append("signature", null)
        : formData.append("signature", acceptData?.dSignature);
    }

    // if (signature === null) {
    //   formData.append("signature", acceptData?.dSignature);
    // }

    setSubmitSpinner(true);

    let params, url;
    if (!userRole.includes(roles[0])) {
      params = formData;
      url = URL.insertDepartmentStaffGuideline;
    } else {
      params = guideline;
      url = URL.acceptGuideline;
    }
    axiosPost(
      url,
      params,
      (response) => {
        if (response.status === 200) {
          setSubmitSpinner(false);
          swal(
            "Success",
            "Company guidelines accepeted successfully",
            "success"
          );
          localStorage.setItem("guideLineAccepted", true);
          const isInfoFilled = JSON.parse(
            localStorage.getItem("isAllStaffInfoFilled")
          );
          if (isInfoFilled) {
            navigate(`/dashboard`);
          } else {
            navigate(`/dashboard/myProfile`);
          }
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  return (
    <>
      <div className="container" style={{ padding: "0.75rem", height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="container mt-4">
            <div className="col-12">
              <div className="mt-3">
                <ul className="list list-inline">
                  <li className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <i className="fa fa-check-circle checkicon"></i>
                      <div className="ml-2">
                        <h6 className="mb-0">Accept Company Guideline</h6>
                        <div className="d-flex flex-row mt-1 text-black-50 date-time"></div>
                      </div>
                    </div>
                    <div className="text-right">
                      <h6
                        className="mb-0 text-right"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/");
                        }}
                      >
                        LogOut
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="jumbotron">
              <div className="container">
                {isLoading ? (
                  <div className="mt-2">
                    <Spinner
                      style={{
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="mt-2">
                      <h5 className="newTextColor">
                        {companyGuideline?.header}
                      </h5>
                    </div>
                    <hr />
                    <div className="newTextColor">
                      {parse(`${companyGuideline?.subject}`)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {!isLoading && (
          <div className="row">
            <div className="col">
              <div>
                <div className="container">
                  <hr></hr>
                  <form onSubmit={handleSubmit} className="mt-2">
                    <div className="mt-2">
                      <input
                        id="acceptTerms"
                        type="checkbox"
                        name="accepted"
                        onChange={handleChange}
                        // ceheked={
                        //   acceptCompanyGuideline && acceptCompanyGuideline?.accepeted
                        // }
                      ></input>
                      <label
                        htmlFor="acceptTerms"
                        className="acceptGuidelineLabel"
                      >
                        I acknowledge that I have read, and do hereby accept the
                        terms and conditions mentioned above.
                      </label>
                    </div>

                    {!userRole.includes(roles[0]) ? (
                      <>
                        {signature !== null ? (
                          <>
                            {/* <div className="d-flex"> */}
                            <div className="mt-3 pr-2">
                              <label
                                style={{ display: "contents" }}
                                className="acceptGuidelineLabel"
                              >
                                Digital Signature
                              </label>
                            </div>
                            <div className="signatureHolder mt-2">
                              <img
                                src={
                                  fileUrl +
                                  "/" +
                                  signature[0].id +
                                  "/" +
                                  signature[0].file_name
                                }
                              />
                            </div>
                            {/* </div> */}
                          </>
                        ) : (
                          <>
                            <div className="mt-2">
                              <div className="d-flex">
                                <div>
                                  <label
                                    style={{ display: "contents" }}
                                    className="acceptGuidelineLabel"
                                  >
                                    Upload Signature
                                  </label>
                                </div>
                                <div style={{ marginLeft: "1rem" }}>
                                  <label className="btn btn-secondary btn-sm">
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      name="dSignature"
                                      onChange={handleChange}
                                      accept="image/png,image/jpeg,image/jpg"
                                    ></input>
                                    <FontAwesomeIcon icon={faFileUpload} />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}

                    {acceptData?.dSignature && (
                      <div
                        style={{
                          marginTop: "1rem",
                          fontSize: "small",
                        }}
                      >
                        FileName:{acceptData?.dSignature.name}
                      </div>
                    )}

                    <div style={{ float: "right", marginLeft: "1rem" }}>
                      <Button name="submit" type="submit" />
                    </div>
                  </form>
                  {!guideLineRequire || guideLineRequire === 0 ? (
                    <div style={{ float: "right" }}>
                      <Button name="Cancel" onClick={handleCancel} />
                    </div>
                  ) : null}{" "}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AcceptCompanyGuidelines;
