//Define URL and constants here
export const siteName = "HRM";
export const headers = {
  Authorization: `Bearer ${getAccessToken()}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function getAccessToken() {
  return localStorage.getItem("token");
}
  export const serverLocation = "https://hrm-backend.techarttrekkies.com",
  fileUrl = serverLocation + "/storage",
  cryptoSecretKey = "techarthrm@itsbest",
  loginUrl = `${serverLocation}/api/login`,
  URL = {
    insertDepartment: `${serverLocation}/api/department`,
    getDepartment: `${serverLocation}/api/department/own/dep`,
    insertCompany: `${serverLocation}/api/company`,
    getCompany: `${serverLocation}/api/company/all-company`,
    getRespectiveCompany: `${serverLocation}/api/company/respective-company`,
    deleteCompany: `${serverLocation}/api/company/destroy`,
    insertDepartmentStaff: `${serverLocation}/api/department-staff`,
    updateDepartmentStaff: `${serverLocation}/api/department-staff/update`,
    getDepartmentStaff: `${serverLocation}/api/department-staff/own-department-staff`,
    deleteDepartmentStaff: `${serverLocation}/api/department-staff/destroy`,
    downloadCompanyZip: `${serverLocation}/downloadCompanyZip`,
    downloadStaffZip: `${serverLocation}/downloadStaffZip`,
    insertCompanyGuideline: `${serverLocation}/api/company-guideline`,
    getCompanyGuideline: `${serverLocation}/api/company-guideline/getGuideline`,
    getAllPermissions: `${serverLocation}/api/department-staff/all-permissions`,
    getAllRoles: `${serverLocation}/api/department-staff/all-roles`,
    insertCompanySetting: `${serverLocation}/api/company-settings`,
    getAllIndustry: `${serverLocation}/api/industry/get-all`,
    acceptGuideline: `${serverLocation}/api/company-guideline/accept-company-admin-guideline`,
    userInfo: `${serverLocation}/api/company/user-info`,
    getCompanySetting: `${serverLocation}/api/company-settings/get-setting`,
    addDepartmentRoles: `${serverLocation}/api/department/roles-permissions`,
    getCities: `${serverLocation}/api/country/getCities/1`,
    getCountry: `${serverLocation}/api/country/getCountry`,
    insertBranch: `${serverLocation}/api/branch`,
    insertLeaveCategory: `${serverLocation}/api/leave-category`,
    getLeaveCategory: `${serverLocation}/api/leave-category/get-all`,
    insertDepartmentStaffGuideline: `${serverLocation}/api/company-guideline/acceptGuideline`,
    insertLeave: `${serverLocation}/api/leaves`,
    getLeave: `${serverLocation}/api/leaves/get-all`,
    getLeaveOwn: `${serverLocation}/api/leaves/get-own`,
    getFilteredLeave: `${serverLocation}/api/leaves/search-all`,
    getFilteredLeaveOwn: `${serverLocation}/api/leaves/search-own`,
    acceptLeave: `${serverLocation}/api/leaves/action`,
    insertCC: `${serverLocation}/api/leaves/send-to`,
    getMailAddress: `${serverLocation}/api/leaves/get-respective-mail`,
    deleteLeaveCategory: `${serverLocation}/api/leave-category/delete-category`,
    updateLeaveCategory: `${serverLocation}/api/leave-category/update-category`,
    getDepartmentStaffLeave: `${serverLocation}/api/department-leave/get-staff`,
    getDepartmentStaffFilterLeave: `${serverLocation}/api/department-leave/leave-filter`,
    getDepartmentFilterLeave: `${serverLocation}/api/department-leave/filter-all`,
    exportIndividualLeave: `${serverLocation}/api/excel-export/individual-export`,
    exportAllLeave: `${serverLocation}/api/excel-export/all-export`,
    insertBirthdayConfig: `${serverLocation}/api/birthday/store`,
    getDigitalSignature: `${serverLocation}/api/department-staff/get-signature`,
    passwordChange: `${serverLocation}/api/department-staff/change-password`,
    getResDepartment: `${serverLocation}/api/department/catdep`,
    getAllTeam: `${serverLocation}/api/department/all-users`,

    //Charts
    getMyAllAttendance: `${serverLocation}/api/echart/dynamic_chart_all`,
    getMyMonthlyAttendance: `${serverLocation}/api/echart/dynamic_chart_month`,
    fetchAllUsersOfCompany: `${serverLocation}/api/leaves/get-respective-users`,
    getRequestedLeaves: `${serverLocation}/api/leaves/pending-leaves`,

    //delete configured sendTo && CC mail address

    deleteSendToMail: `${serverLocation}/api/leaves/delete-send-to`,
    deleteCCMail: `${serverLocation}/api/leaves/delete-send-cc`,
    updateDescription: `${serverLocation}/api/leave-settings/update-description`,

    //BirthdayConfig
    getBirthdayConfig: `${serverLocation}/api/birthday/all-configs`,
    updateBirthdayConfig: `${serverLocation}/api/birthday/update`,

    //UPDATE COMPNAY
    updateCompany: `${serverLocation}/api/company/update`,
    //update Permission
    getRoleBasedPermission: `${serverLocation}/api/permission/all-permissions`,
    updateRoleandPermission: `${serverLocation}/api/permission/update-permissions`,

    //get all department staff
    getAllDepartmentStaff: `${serverLocation}/api/department-staff/all-staff`,
    //insertgroupMail
    insertGroupMail: `${serverLocation}/api/group-email`,
    getGroupMail: `${serverLocation}/api/group-email/view`,
    getGroupMailCC: `${serverLocation}/api/group-email`,

    //forgotPassword
    forgotPasswordURL: `${serverLocation}/api/forgot-password`,
    resetPassword: `${serverLocation}/api/reset-password`,

    //modules
    getModules: `${serverLocation}/api/modules`,

    // feedback/complains

    storeFeedbacks: `${serverLocation}/api/feedback-complains`,
    getFeedbacks: `${serverLocation}/api/feedback-complains`,
    getAssociatedFeedbacks: `${serverLocation}/api/feedback-complains/associated`,
    respondToFeedback: `${serverLocation}/api/feedback-complains/feedback`,
    //insertFAQ
    FAQ: `${serverLocation}/api/faqs`,
    updateFAQ: `${serverLocation}/api/faqs/update`,
    deleteFAQ: `${serverLocation}/api/faqs/delete`,
    devFAQ: `${serverLocation}/api/get-dev-faq`,
    gatePass: `${serverLocation}/api/gatepass`,
    gatePassFilter: `${serverLocation}/api/gatepass/filter`,
    acceptRejectGatePass: `${serverLocation}/api/gatepass/changestatus`,
    gatePassFilterByDate: `${serverLocation}/api/gatepass/filter-by-date`,
    gatePassUpdateActualTime: `${serverLocation}/api/gatepass/update-actual-time`,
    gatePassRequested: `${serverLocation}/api/gatepass/requested`,
    getPassFilterByDepartment: `${serverLocation}/api/gatepass/filter-by-department`,

    //routes For OverTimeCard
    storeOverTimeCard: `${serverLocation}/api/overtime`,
    updateOverTimeCard: `${serverLocation}/api/overtime/changestatus`,
    getOverTimeCard: `${serverLocation}/api/overtime/getAll`,
    getRespectiveOverTimeCard: `${serverLocation}/api/overtime/getCompanyOvertime`,
    getRequestedOvertime: `${serverLocation}/api/overtime/getRequestedOverTime`,
    getOvertimeFilterByDepartment: `${serverLocation}/api/overtime/filter-by-department`,
    getOverTimeUsers: `${serverLocation}/api/overtime/getUsers`,
    updateActualTime: `${serverLocation}/api/overtime/update-actual-time`,

    //Contract
    getMyContract: `${serverLocation}/api/contract/getContract`,
    storeContract: `${serverLocation}/api/contract`,

    //vendor
    createNewVendor: `${serverLocation}/api/vendor`,
    getAllVendor: `${serverLocation}/api/vendor/getVendor`,
    deleteVendor: `${serverLocation}/api/vendor/delete`,
    editVendor: `${serverLocation}/api/vendor/update`,
    getRequestedOverTimeCard: `${serverLocation}/api/overtime/getRequestedOverTime`,

    //Requistion
    getParticulars: `${serverLocation}/api/requisition/createRequisition`,
    createNewRequistion: `${serverLocation}/api/requisition`,
    getAllRequistion: `${serverLocation}/api/requisition/getRequisition`,
    deleteRequistion: `${serverLocation}/api/requisition/delete`,
    updateRequistion: `${serverLocation}/api/requisition/update`,
    approveRequistion: `${serverLocation}/api/requisition/approveRequisition`,
    getOwnRequistion: `${serverLocation}/api/requisition/ownRequisition`,

    //Category
    getAllItemsCategory: `${serverLocation}/api/category/getcategory`,
    deleteItemsCategory: `${serverLocation}/api/category/deletecategory`,
    createItemsCategory: `${serverLocation}/api/category`,

    //Notices
    createNotices: `${serverLocation}/api/notice/store-notice`,
    getAllNotices: `${serverLocation}/api/notice/getAllNotice`,
    getAllCircular: `${serverLocation}/api/notice/getAllCircular`,
    getOwnNotices: `${serverLocation}/api/notice/getOwnNotice`,
    getOwnCircular: `${serverLocation}/api/notice/getOwnCircular`,
    deleteNoticesAndCircular: `${serverLocation}/api/notice/deleteNotice`,

    // evaluation api 
    getEvaluation: `${serverLocation}/api/company/user-login`
  };

export const moduleName = {
  allLeaves: "ViewAllLeaves",
  birthdayConfiguration: "BirthdayConfiguration",
  branch: "Branch",
  company: "Company",
  companyGuideline: "CompanyGuideline",
  companySetting: "CompanySetting",
  contactPeople: "ContactPeople",
  department: "Department",
  departmentStaff: "DepartmentStaff",
  leave: "Leave",
  leaveCategory: "LeaveCategory",
  leaveConfiguration: "LeaveConfiguration",
  myProfile: "MyProfile",
  requestedLeaves: "ViewRequestedLeaves",
  feedbackComplain: "FeedbackOrComplain",
  groupEmail: "GroupEmail",
  faq: "FAQs",
  gatePass: "Gatepass",
  allGatePass: "ViewAllGatepass",
  requestedGatePass: "ViewRequestedGatepass",
  overTimeCard: "ViewAllOvertime",
  overTime: "Overtime",
  requestedOvertime: "ViewRequestedOvertime",
  notice: "Notice",
  evaluation: "Evaluation"
};

export const GUIDELINE_TYPE = {
  COMPANY: "Company Guideline",
  MANUAL: "HR Manual",
};

export const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);