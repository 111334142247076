import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../assets/scss/termsandconditions.scss";
import swal from "sweetalert";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import Checkbox from "../../components/common/Checkbox";
import { displayErrorAlert } from "../../utils/DisplayMessage";

const TermsandCondition = (props) => {
  const { isCG, toggleModal, getCompanyGuideline, toggle } = props;
  let navigate = useNavigate();
  const [noticeType, setNoticeType] = useState(null);
  const [file, setFile] = useState(null);
  const [termsandconditions, setTermsandconditions] = useState({
    header: "",
    subject: "",
    is_guideline_required: 0,
  });
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [companySettingData, setCompanySettingData] = useState(null);
  const [uploadFile, setUploadFile] = useState(false);
  const [type] = useState([
    {
      name: "Company Guideline",
      value: "Company Guideline",
    },
    {
      name: "HR Manual",
      value: "HR Manual",
    },
  ]);

  useEffect(() => {
    getCompanySetting();
  }, []);

  const getCompanySetting = () => {
    axiosGet(
      URL.getCompanySetting,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data && response.data.data?.item;
          setCompanySettingData(modules);
          setTermsandconditions((prevState) => ({
            ...prevState,
            is_guideline_required: modules
              ? modules.is_guideline_required
              : null,
          }));
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const handleChange = (e) => {
    const { name, value, checked, type, files } = e.target;
    if (name === "type") {
      setNoticeType(value);
    } else if (name === "isFileupload") {
      setUploadFile(checked);
    } else if (name === "files") {
      setFile(files[0]);
    }
    if (
      companySettingData &&
      companySettingData !== null &&
      name === "is_guideline_required"
    ) {
      if (type === "checkbox") {
        setCompanySettingData((prevState) => ({
          ...prevState,
          is_guideline_required: checked && checked ? 1 : 0,
        }));
      }
    }

    setTermsandconditions((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked && checked ? 1 : 0) : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isEmpty = 0;
    if (termsandconditions.header === "") {
      isEmpty++;
    }
    if (
      termsandconditions.subject === "" &&
      termsandconditions.isFileUpload == 0
    ) {
      isEmpty++;
    }
    if (termsandconditions.type === "") {
      isEmpty++;
    }
    if (
      termsandconditions.files === "" &&
      termsandconditions.isFileUpload == 1
    ) {
      isEmpty++;
    }
    let formData = new FormData();
    formData.append("header", termsandconditions.header);
    formData.append("subject", JSON.stringify(termsandconditions.subject));
    formData.append(
      "is_guideline_required",
      termsandconditions.is_guideline_required
    );
    formData.append("type", noticeType);
    if (uploadFile === true) {
      formData.append("files", file);
    }
    setSubmitSpinner(true);
    if (isEmpty == 0) {
      axiosPost(
        URL.insertCompanyGuideline,
        formData,
        (response) => {
          if (response && response.data.success) {
            setSubmitSpinner(false);
            toggleModal();
            swal(
              "Success",
              `${
                isCG && isCG
                  ? "Company guideline inserted successfully"
                  : "Terms and conditions inserted successfully"
              }`,

              "success"
            );
            toggle(noticeType === "Company Guideline" ? "1" : "2");
            getCompanyGuideline(noticeType);
            setTermsandconditions({
              header: "",
              subject: "",
            });
            {
              (isCG && isCG) ?? navigate(`${process.env.PUBLIC_URL}/dashboard`);
            }
          }
        },
        (err) => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
          return;
        }
      );
    } else {
      setSubmitSpinner(false);
      swal("Warning", "Please fill the required fields", "warning");
      return;
    }
  };
  return (
    <div className="container">
      {submitSpinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please Wait. Submitting ...
          </div>
        </div>
      ) : null}
      <div className="row mt-3">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {isCG && isCG
                ? "Company Guideline/Manual"
                : "Terms and Condition"}
            </div>
            <div className="card-body">
              <form className="form-group" onSubmit={handleSubmit}>
                <div className="row mb-2">
                  <div
                    className="col text-start"
                    style={{ position: "relative" }}
                  >
                    <label className="floating">
                      Heading<span className="required">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name="header"
                      placeholder="Heading"
                      className="form-control-new"
                      onChange={handleChange}
                      value={termsandconditions?.header}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col editor">
                    <CKEditor
                      data={termsandconditions?.subject}
                      editor={ClassicEditor}
                      name="subject"
                      // data=""
                      onChange={(event, editor) => {
                        let data = editor.getData();
                        setTermsandconditions((prevState) => ({
                          ...prevState,
                          subject: data,
                        }));
                      }}
                      placeholder="Enter description here"
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div
                    className="col text-start "
                    style={{ position: "relative" }}
                  >
                    <label className="floating">
                      Type<span className="required">*</span>{" "}
                    </label>
                    <select
                      type="text"
                      style={myInput}
                      className="formSelect"
                      name="type"
                      value={noticeType}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Type
                      </option>
                      {type &&
                        type.map((items, index) => (
                          <option value={items.value} key={index}>
                            {items.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col text-center d-flex align-items-center justify-content-center">
                    <div className="form-check">
                      <Checkbox
                        handleChange={handleChange}
                        name="isFileupload"
                        checked={uploadFile}
                      />
                      <label
                        className="form-check-label position-relative"
                        style={{ top: "-5px" }}
                        htmlFor="checkMe"
                      >
                        File Upload ?
                      </label>
                    </div>
                  </div>

                  {uploadFile ? (
                    <div
                      className="col text-start"
                      style={{ position: "relative" }}
                    >
                      <label className="floating">Upload</label>
                      <input
                        type="file"
                        style={myInput}
                        className="form-control"
                        name="files"
                        onChange={handleChange}
                      ></input>
                      <div>
                        <small style={{ fontSize: "9px" }}>
                          *File size should be less than 4MB and accepts only
                          pdf, jpg, jpeg
                        </small>
                      </div>
                    </div>
                  ) : null}
                </div>

                {isCG && isCG ? null : (
                  <button className="btn btn-primary submit" type="submit">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col mt-2">
          <div className="form-check">
            <Checkbox
              // onChange={handleChange}
              handleChange={handleChange}
              name="is_guideline_required"
              checked={
                companySettingData?.is_guideline_required === 1
                  ? true
                  : termsandconditions?.is_guideline_required
              }
            />
            <label className="form-check-label" htmlFor="checkMe">
              Staff must accept the company guidelines to use the system.
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col text-end">
          <Button name="Submit" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

const myInput = {
  padding: "25px 20px 12px 15px",
};

export default TermsandCondition;
