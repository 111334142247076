import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { bytesToMegaBytes, fileUrl } from "../../../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../../components/common/Checkbox";
import { phoneValidate } from "../../../utils/Validation";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import Button from "../../../components/common/Button";
import ErrorFallback from "../../../utils/ErrorFallback";
import FileViewer from "react-file-viewer";
import CommonModal from "../../../components/common/Modal";
import CustomFileViewer from "../../../components/common/CustomFileViewer";
import { original } from "immer";

const CompanyDetails = (props) => {
  const {
    company,
    setCompany,
    handleNext,
    companyData,
    setCompanyData,
    editParam,
    industryData,
    updatedMediaFiles,
    setUpdatedMediaFiles,
    editableCmp,
    setEditableCmp,
    companyFiles,
    handleRemoveCmpFile,
    companyInfo,
    updateCompanyProfile,
    SetCompanyProfileInfo,
    reg,
    setRegFile,
    handleRemove,
  } = props;

  const [modal, setModal] = useState({ fileModal: false, status: null });

  const reqCompanyDetails = editParam?.isEdit
    ? editableCmp[editParam.selectedIndex]
    : updateCompanyProfile
    ? companyInfo.company
    : company;

  const reqCompanyDetailsSetterFunc = editParam?.isEdit
    ? setEditableCmp
    : updateCompanyProfile
    ? SetCompanyProfileInfo
    : setCompany;

  const toggleModalFile = (e, status) => {
    status === undefined &&
      setModal((prevState) => ({
        ...prevState,
        fileModal: false,
        status: null,
      }));

    status === "initial" &&
      setModal((prevState) => ({
        ...prevState,
        fileModal: true,
        status,
      }));
    status === "uploaded" &&
      setModal((prevState) => ({
        ...prevState,
        fileModal: true,
        status,
      }));
    status === "existing" &&
      setModal((prevState) => ({
        ...prevState,
        fileModal: true,
        status,
      }));
  };

  const removeImage = () => {
    if (!editParam?.isEdit && !updateCompanyProfile) {
      const clonecmp = { ...company };
      clonecmp.logo = null;
      setCompany(clonecmp);
    } else {
      const cloneUpdatedMediaFiles = { ...updatedMediaFiles };
      cloneUpdatedMediaFiles.logo = null;
      setUpdatedMediaFiles(cloneUpdatedMediaFiles);
    }
  };

  const handlePhoneInputChange = (e) => {
    if (!editParam?.isEdit && !updateCompanyProfile) {
      setCompany((prevState) => ({ ...prevState, contactNumber: e }));
    } else if (editParam?.isEdit) {
      const cloneCompanyData = [...editableCmp];
      cloneCompanyData[editParam.selectedIndex].contact_number = e;
      setEditableCmp(cloneCompanyData);
    } else {
      const cloneCompanyData = { ...reqCompanyDetails };
      cloneCompanyData.contact_number = e;
      SetCompanyProfileInfo(cloneCompanyData);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type, files } = e.target;
    const fileName = ["logo", "registration"];
    if (!editParam?.isEdit && !updateCompanyProfile) {
      if (!fileName.includes(name)) {
        if (type === "checkbox" && name === "isDomain" && checked) {
          setCompany((prevState) => ({ ...prevState, domainName: "" }));
        }
      } else {
        if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }
        if (files && files[0]) {
          setCompany((prevState) => ({ ...prevState, [name]: files[0] }));
        }
      }
      if (type !== "file") {
        setCompany((prevState) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }));
      }
    } else if (editParam?.isEdit) {
      const cloneCompanyData = [...editableCmp];
      if (!fileName.includes(name)) {
        //REQUIRED isDomain key as a response from backend

        // if (name === "isDomain") {
        //   if ("isDomain" in cloneCompanyData[editParam.selectedIndex]) {
        //     if (cloneCompanyData[editParam.selectedIndex].isDomain) {
        //       cloneCompanyData[editParam.selectedIndex].domain_name = "";
        //     }
        //   }
        // }
        const settingsName = ["has_sister_concern", "can_have_branch"];
        if (settingsName.includes(name)) {
          cloneCompanyData[editParam.selectedIndex].setting[name] = checked;
        } else {
          cloneCompanyData[editParam.selectedIndex][name] = value;
        }
        // cloneCompanyData[editParam.selectedIndex].setting[name] = checked;
      } else {
        if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }

        if (files && files[0]) {
          setUpdatedMediaFiles((prevState) => ({
            ...prevState,
            [name]: files[0],
          }));
        }
      }
      setEditableCmp(cloneCompanyData);
    } else {
      const cloneCompanyData = { ...companyInfo.company };
      if (!fileName.includes(name)) {
        const settingsName = ["has_sister_concern", "can_have_branch"];
        if (settingsName.includes(name)) {
          cloneCompanyData.has_settings[name] = checked;
        } else {
          cloneCompanyData.has_settings[name] = value;
        }
      } else {
        if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }
        if (files && files[0]) {
          setUpdatedMediaFiles((prevState) => ({
            ...prevState,
            [name]: files[0],
          }));
          return;
        }
      }
      SetCompanyProfileInfo(cloneCompanyData);
    }
  };

  const getImage = () => {
    let companyLogo;
    if (editParam?.isEdit) {
      companyLogo = companyData[editParam.selectedIndex].media.find(
        (cmp) => cmp.collection_name === "company_logo"
      );
    }

    if (updateCompanyProfile) {
      companyLogo =
        reqCompanyDetails &&
        reqCompanyDetails.media &&
        reqCompanyDetails.media.find(
          (cmp) => cmp.collection_name === "company_logo"
        );
    }

    if (
      companyLogo !== null &&
      companyLogo !== undefined &&
      !updatedMediaFiles?.logo
    ) {
      const { id, file_name } = companyLogo;
      return fileUrl + "/" + id + "/" + file_name;
    }

    if (updatedMediaFiles?.logo) {
      return URL.createObjectURL(updatedMediaFiles?.logo);
    }
    if (company?.logo) {
      return URL.createObjectURL(company?.logo);
    }
    return "https://imgs.search.brave.com/fg2C8KWs0ztoktGIawgMn1N06rE_xSyD0Mmdu-ALL-8/rs:fit:250:250:1/g:ce/aHR0cDovL3FwbGV4/dXMuY29tL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDE2LzAyL2Rl/ZmF1bHQtbG9nby5w/bmc";
  };

  return (
    <>
      <h2 className="fs-title">Company Details</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>
      <div className="container border">
        <div className="row">
          {/* <div className="profile-img"> */}
          <div className="col profile-img1">
            <div>
              <img src={getImage()} alt="companyLogo" />
              {(company && company?.logo) ||
              (updatedMediaFiles && updatedMediaFiles?.logo) ? (
                <FontAwesomeIcon
                  className="deleteImg"
                  icon={faTimesCircle}
                  style={{ cursor: "pointer" }}
                  onClick={removeImage}
                ></FontAwesomeIcon>
              ) : null}

              <div className="file btn ">
                {editParam?.isEdit || updateCompanyProfile
                  ? "Edit Logo"
                  : "Upload Logo"}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                <input
                  accept="image/png,image/jpeg,image/jpg"
                  type="file"
                  name="logo"
                  onChange={handleChange}
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              Company Name<span className="required">*</span>{" "}
            </label>

            <input
              type="text"
              placeholder="Company Name"
              name="name"
              value={reqCompanyDetails?.name}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="floating">
              Short Name<span className="required">*</span>{" "}
            </label>
            <input
              type="text"
              placeholder="Short Name"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "short_name"
                  : "shortName"
              }
              value={
                reqCompanyDetails?.shortName ?? reqCompanyDetails?.short_name
              }
              onChange={handleChange}
            />
          </div>

          <div className="col">
            <label className="floating">
              Address<span className="required">*</span>{" "}
            </label>
            <input
              type="text"
              placeholder="Address"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "address_line"
                  : "addressLine"
              }
              value={
                reqCompanyDetails?.addressLine ??
                reqCompanyDetails?.address_line
              }
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <label className="floating">
              Email Address<span className="required">*</span>{" "}
            </label>
            <input
              type="email"
              placeholder="Email Address"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "email_address"
                  : "emailAddress"
              }
              value={
                reqCompanyDetails?.emailAddress ??
                reqCompanyDetails?.email_address
              }
              onChange={handleChange}
            />
          </div>

          <div className="col text-start" style={{ position: "relative" }}>
            <label
              className="floating"
              style={{ position: "absolute", left: "10.5%" }}
            >
              Contact Number<span className="required">*</span>{" "}
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              placeholder="Enter phone number"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "contact_number"
                  : "contactNumber"
              }
              value={
                reqCompanyDetails?.contactNumbers ??
                reqCompanyDetails?.contact_number
              }
              onChange={handlePhoneInputChange}
              defaultCountry="NP"
            />
            {reqCompanyDetails?.contactNumber ||
            reqCompanyDetails?.contact_number ? (
              isValidPhoneNumber(
                reqCompanyDetails?.contactNumber ||
                  reqCompanyDetails?.contact_number
              ) ? undefined : (
                <small
                  style={{
                    color: "red",
                    marginTop: "-20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Phone number is not valid
                </small>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <label className="floating">
              Industry<span className="required">*</span>{" "}
            </label>
            <select
              className="formSelect"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "industry_id"
                  : "industry"
              }
              value={
                reqCompanyDetails?.industry ?? reqCompanyDetails?.industry_id
              }
              onChange={handleChange}
              defaultValue={"Choose Industry"}
            >
              <option disabled>Choose Industry</option>

              {industryData &&
                industryData.map((ind, id) => {
                  return (
                    <option value={ind.id} key={ind?.id}>
                      {ind.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col text-center" style={{ marginBottom: "20px" }}>
            <input
              type="checkbox"
              name="isDomain"
              checked={
                reqCompanyDetails?.isDomain ??
                reqCompanyDetails?.domain_name === ""
              }
              onChange={handleChange}
              id="website"
            />
            <label className="checkboxLabel" htmlFor="website">
              Don't have website{" "}
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="floating">
              Website Name
              {(editParam?.isEdit &&
                companyData[editParam?.selectedIndex].domain_name === "") ||
              company?.isDomain ? (
                <span>*</span>
              ) : (
                <span className="required">*</span>
              )}
            </label>
            <input
              type="text"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "domain_name"
                  : "domainName"
              }
              placeholder="Website Name"
              disabled={
                reqCompanyDetails?.isDomain ??
                reqCompanyDetails?.domain_name === ""
                  ? true
                  : false
              }
              value={
                reqCompanyDetails?.domainName ?? reqCompanyDetails?.domain_name
              }
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row d-flex mb-4">
          {(companyFiles && !companyFiles?.registration) || reg === null ? (
            <div className="col-6">
              <label className="floating">
                Registration file<span className="required">*</span>{" "}
              </label>
              <input
                type="file"
                name="registration"
                onChange={handleChange}
                accept="image/png,image/jpeg,image/jpg,application/pdf"
              />
            </div>
          ) : (
            <div className="col-6 justify-content-start mt-4">
              <Button
                name="Remove Registration File "
                onClick={(e) =>
                  !updateCompanyProfile
                    ? handleRemoveCmpFile(e, "registration")
                    : handleRemove(e)
                }
              />
            </div>
          )}{" "}
          {reqCompanyDetails?.registration && (
            <div className="col">
              <Button
                name="Registration File"
                className="mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalFile(e, "initial");
                }}
              ></Button>
            </div>
          )}
          {reg && (
            <div className="col">
              <Button
                name="Registration File"
                className="mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  toggleModalFile(e, "existing");
                }}
              ></Button>
            </div>
          )}
          {(editParam?.isEdit || updateCompanyProfile) &&
            updatedMediaFiles?.registration && (
              <div className="col-4 mt-4">
                <Button
                  name="Registration File"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalFile(e, "uploaded");
                  }}
                ></Button>
              </div>
            )}
          {editParam?.isEdit &&
            updatedMediaFiles?.registration === undefined &&
            companyFiles?.registration && (
              <div className="col-4 mt-4">
                <Button
                  name="View Registration File"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalFile(e, "existing");
                  }}
                ></Button>
              </div>
            )}
        </div>

        <div className="row" style={{ marginBottom: "20px" }}>
          {props.rolePermission ? (
            <div className="col">
              <input
                type="checkbox"
                // name="hasSisterConcern"
                // checked={
                //   editParam?.isEdit
                //     ? companyData[editParam.selectedIndex]?.setting
                //         ?.has_sister_concern
                //     : company?.hasSisterConcern
                // }
                // name="hasSisterConcern"
                name={
                  editParam?.isEdit || updateCompanyProfile
                    ? "has_sister_concern"
                    : "hasSisterConcern"
                }
                checked={
                  reqCompanyDetails?.hasSisterConcern ??
                  reqCompanyDetails?.setting?.has_sister_concern
                }
                onChange={handleChange}
                id="checkbox1"
              />
              <label htmlFor="checkbox1" className="checkboxLabel">
                has sister concerns
              </label>
            </div>
          ) : null}

          <div className="col">
            <input
              type="checkbox"
              name={
                editParam?.isEdit || updateCompanyProfile
                  ? "can_have_branch"
                  : "canHaveBranch"
              }
              checked={
                reqCompanyDetails?.canHaveBranch ??
                reqCompanyDetails?.setting?.can_have_branch
              }
              onChange={handleChange}
              id="checkbox2"
            />
            <label htmlFor="checkbox2" className="checkboxLabel">
              has branches
            </label>
          </div>
        </div>
      </div>

      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={handleNext}
      />

      <CommonModal
        modal={
          modal.status === "initial"
            ? modal.fileModal
            : modal.status === "uploaded"
            ? modal.fileModal
            : modal.status === "existing" && modal.fileModal
        }
        modalHeader={"Registration File"}
        modalBody={
          modal.status === "initial" ? (
            <CustomFileViewer
              fileExtension={reqCompanyDetails?.registration?.name?.slice(-3)}
              filePath={window.URL.createObjectURL(
                reqCompanyDetails?.registration
              )}
            />
          ) : modal.status === "existing" ? (
            companyFiles?.registration ? (
              <CustomFileViewer
                fileExtension={companyFiles.registration?.file_name
                  ?.split(".")
                  .pop()}
                filePath={`${fileUrl}/${companyFiles.registration?.id}/${companyFiles.registration?.file_name}`}
              />
            ) : (
              updateCompanyProfile &&
              reg && (
                <CustomFileViewer
                  fileExtension={reg?.file_name?.split(".").pop()}
                  filePath={`${fileUrl}/${reg?.id}/${reg?.file_name}`}
                />
              )
            )
          ) : (
            modal.status === "uploaded" && (
              <CustomFileViewer
                fileExtension={updatedMediaFiles?.registration?.name?.slice(-3)}
                filePath={window.URL.createObjectURL(
                  updatedMediaFiles?.registration
                )}
              />
            )
          )
        }
        size="xl"
        toggleModal={toggleModalFile}
      />
    </>
  );
};

export default CompanyDetails;
