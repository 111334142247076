import React, { Component } from "react";
import Button from "../../components/common/Button";
class LeaveResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container">
        <form onSubmit={this.props.handleSubmit} className="mb-0" id="msform">
          <div className="row">
            <div className="col text-start">
              <label className="floating">Remarks:</label>
              <textarea
                type="text"
                className="form-control"
                value={this.props.remarks}
                onChange={(e) => this.props.setRemarks(e.target.value)}
              />
            </div>

            <div className="col">
              <label className="floating"> CC:</label>
              <select
                className="formSelect"
                multiple
                name="ccToTeam"
                onChange={this.props.handleCcToTeam}
                value={this.props.ccUsers}
              >
                {this.props.allUsers.map((addr, idx) => (
                  <option value={addr?.label} key={idx}>
                    {addr.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-end ">
              <Button
                name={
                  this.props.leaveStatus?.status === "accept"
                    ? "Accept"
                    : this.props.leaveStatus?.status === "reject"
                    ? "Reject"
                    : this.props.leaveStatus?.status === "revoke"
                    ? "Revoke"
                    : this.props.leaveStatus?.status === "breach" && "Breach"
                }
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default LeaveResponse;
