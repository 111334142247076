import React, { useState, useEffect } from "react";
import CommonModal from "../../components/common/Modal";
import Button from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import RequistionList from "./RequistionList";

const defaultDetails = [{ particular: "", quantity: "", remarks: "" }];

const Requistion = () => {
  const [isOpen, setIsopen] = useState(false);
  const [formDetails, setFormDetails] = useState(defaultDetails);
  const [particulars, setParticulars] = useState();
  const [requistionType,setRequistionType] = useState('myRequistion');
  const [myRequistion,setMyRequistion] = useState();
  const [requistionData, setRequistionData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const toggleCreateModal = items => {
    setIsopen(!isOpen);
    setEditId(items?.id);
    if (items?.inventory_items && items?.inventory_items.length > 0) {
      let ar = items?.inventory_items;
      ar?.forEach(value => {
        value.particular = value.id;
        value.quantity = value.quantity;
        value.remarks = value.remarks;
      });

      setFormDetails(ar);

      // {
      //   items?.inventory_items?.map(value =>
      //     setFormDetails([
      //       ...formDetails,
      //       {
      //         particular: value.id,
      //         quantity: value.quantity,
      //         remarks: value.remarks
      //       }
      //     ])
      //   );
      // }
    } else {
      // setIsopen(!isOpen);
      setFormDetails([
        {
          particular: "",
          quantity: "",
          remarks: ""
        }
      ]);
    }
  };

  const handleAdd = status => {
    const formData = [...formDetails];
    formData?.push({ particular: "", quantity: "", remarks: "" });
    setFormDetails(formData);
  };

  const handleRemove = (id, status) => {
    const formData = [...formDetails];
    formData.splice(id, 1);
    setFormDetails(formData);
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const formData = [...formDetails];
    formData[id][name] = value;
    setFormDetails(formData);
  };

  const handleRequistionChange=(e)=>{
    setRequistionType(e.target.value)
  }


  const handleSubmit = e => {
    e.preventDefault();
    let params;
    if (isEdit) {
      params = {
        inventory_items: formDetails,
        requisition_id: editId
      };
    } else {
      params = {
        inventory_items: formDetails
      };
    }
    const url =
      isEdit === false ? URL.createNewRequistion : URL.updateRequistion;
    axiosPost(url, params, response => {
      if (response.status === 200) {
        swal("Data created successfully");
      }
      toggleCreateModal();
      getAllRequistionData();
      setFormDetails([{ particular: "", quantity: "", remarks: "" }]);
    });
  };

  const getPaticularsValue = () => {
    axiosGet(URL.getParticulars, response => {
      if (response.status === 200) {
        setParticulars(response.data.data.items);
      }
    });
  };

  const getAllRequistionData = () => {
    axiosGet(URL.getAllRequistion, response => {
      if (response.status === 200) {
        setRequistionData(response.data.data.items);
      }
    });
  };

  const getOwnRequistionData = () => {
    axiosGet(URL.getOwnRequistion, response => {
      if (response.status === 200) {
        setMyRequistion(response.data.data.items);
      }
    });
  };

  useEffect(() => {
    getPaticularsValue();
    getAllRequistionData();
    getOwnRequistionData();
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setFormDetails([{ particular: "", quantity: "", remarks: "" }]);
    }
  }, []);
  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Requistion List</div>
        <div className="d-flex align-items-center justify-content-between">
        <div>
          <Button name="Add a Requistion" onClick={toggleCreateModal} />
          </div>
          <div>
          <select
            type="text"
            className="form-control"
            name="particular"
            onChange={(e)=>handleRequistionChange(e)}
            value={requistionType}
          >
            <option value='myRequistion' >My Requestion </option>
            <option value='allRequistion'>All Requestion </option>
          </select>
        </div>
        </div>
        {
          requistionType === 'myRequistion' || undefined ? (
            <RequistionList
            getAllRequistionData={getOwnRequistionData}
            requistionData={myRequistion}
            setIsEdit={setIsEdit}
            toggleModal={toggleCreateModal}
          />
          ) : (
            <RequistionList
            getAllRequistionData={getAllRequistionData}
            requistionData={requistionData}
            setIsEdit={setIsEdit}
            toggleModal={toggleCreateModal}
          />
          ) 
        }

        {/* <RequistionList
          getAllRequistionData={getAllRequistionData}
          requistionData={requistionData}
          setIsEdit={setIsEdit}
          toggleModal={toggleCreateModal}
        /> */}
      </div>
      <CommonModal
        modal={isOpen}
        toggleModal={toggleCreateModal}
        size={"lg"}
        modalHeader={isEdit ? "Edit a requisition" : "Add a requisition"}
        modalBody={
          <>
            <form onSubmit={e => handleSubmit(e)}>
              {formDetails &&
                formDetails.map((value, id) => (
                  <div className="row mb-2" key={id}>
                    <div className="col-md-3">
                      <div className="input-group ">
                        <select
                          type="text"
                          className="form-control"
                          name="particular"
                          onChange={e => handleChange(e, id, "particular")}
                          value={value.particular}
                        >
                          <option value="">Select items</option>
                          {particulars &&
                            particulars?.map(values => (
                              <option value={values.id}>{values.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="basic-url"
                          name="quantity"
                          placeholder="Quantity"
                          aria-describedby="basic-addon3"
                          value={value.quantity}
                          onChange={e => handleChange(e, id, "quantity")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="input-group">
                        <textarea
                          type="text"
                          className="form-control"
                          id="basic-url"
                          name="remarks"
                          aria-describedby="basic-addon3"
                          placeholder="Remarks"
                          value={value.remarks}
                          onChange={e => handleChange(e, id, "remarks")}
                        />
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="">
                        {id === formDetails.length - 1 ? (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            onClick={() => handleAdd("To")}
                            className="addListBtn me-2"
                          />
                        ) : null}
                        {id !== 0 ? (
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            onClick={() => handleRemove(id, "To")}
                            className="removeListBtn"
                            style={{ cursor: "pointer" }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}

              <div className="mt-2">
                <Button name="Submit" type="submit" />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
export default Requistion;
