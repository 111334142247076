import React from "react";
import parse from "html-react-parser";

const CompnayGuidelineView = ({ companyGuideline }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-light bg-light">
            <span className="navbar-brand mb-0 h1 " style={{ margin: "auto" }}>
              {companyGuideline.header}
            </span>
          </nav>
          <div className="mt-3">
            <div className="container mt-2">
              <div className="col">
                <h6>{parse(`${companyGuideline.subject}`)}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnayGuidelineView;
