import React, { useState, useEffect } from "react";
import CommonModal from "../../components/common/Modal";
import { Spinner } from "reactstrap";
import Button from "../../components/common/Button";
import ListLeaveRequest from "./ListLeaveRequest";
import swal from "sweetalert";
import { checkIfEmpty, capitalizeFirstLetter } from "../../utils/Validation";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import Checkbox from "../../components/common/Checkbox";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkModulePermission } from "../../utils/PermissionChecker";
import { ViewLeaveDetails } from "./ViewLeaveDetails";

const CreateLeave = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalRemarks, setModalRemarks] = useState(false);
  const [leaveMailerData, setLeaveMailerData] = useState(null);
  const [createdLeaveData, setCreatedLeaveData] = useState(null);
  const [LeaveDate, setLeaveDate] = useState(null);
  const [LeaveCategory, setLeaveCategory] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [mailAddress, setMailAddress] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [selectedLeaveToView, setSelectedLeaveToView] = useState(null);
  const [modalView, setModalView] = useState(null);
  const [differenceInDays, setDifferenceInDays] = useState(null);
  const [teams, setTeams] = useState([]);
  const [selectedCC, setSelectedCC] = useState([]);
  const [groupMail, setGroupMail] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [ccUsers, setCCUsers] = useState([]);
  const [activeModule, setActiveModule] = useState("main");

  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  useEffect(() => {
    setLeaveMailerData((prevState) => ({
      ...prevState,
      leaveTo: leaveMailerData?.leaveForm,
    }));
  }, [leaveMailerData?.leaveForm]);

  const getAllTeams = () => {
    axiosGet(URL.getAllTeam, (res) => {
      if (res.status === 200) {
        if (res.data.data) {
          setTeams(res.data.data.items || res.data.data.values);
        }
      }
    });
  };
  const getAllGroupMail = () => {
    axiosGet(URL.getGroupMailCC, (res) => {
      if (res.status === 200) {
        if (res.data.data) {
          setGroupMail(res.data.data.values);
        }
      }
    });
  };

  const getAllUsers = () => {
    axiosGet(URL.getOverTimeUsers, (response) => {
      // let newArr = [];
      if (response.status === 200) {
        let responseData = response.data;
        responseData?.forEach((element) => {
          element.label = element.email;
          element.value = element.id;
        });
        setAllUsers(responseData);
      }
    });
  };

  const getLeaveData = (pageNumber = 1) => {
    const role = ["Developer Admin", "Super Admin"];
    setIsLoading(true);
    let url = role.includes(userInfo?.first_name)
      ? URL.getLeave
      : URL.getLeaveOwn;
    axiosGet(
      url + "?" + "page=" + pageNumber,
      (res) => {
        if (res.data.success) {
          let modules = res?.data.data || res?.data.data?.items;
          setIsLoading(false);
          setCreatedLeaveData(modules);
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const getLeaveCategory = () => {
    axiosGet(
      URL.getLeaveCategory,
      (res) => {
        if (res.data.success) {
          let modules = res.data.data?.items;
          setLeaveCategory(modules);
        }
      },
      (err) => { }
    );
  };

  const getLeaveFilteredData = (pageNumber = 1) => {
    const role = ["Developer Admin", "Super Admin"];
    let url = role.includes(userInfo?.first_name)
      ? URL.getFilteredLeave
      : URL.getFilteredLeaveOwn;
    let params = {
      fromDate: LeaveDate?.From,
      toDate: LeaveDate?.To,
    };

    setIsLoading(true);

    axiosPost(
      url + "?" + "page=" + pageNumber,
      params,
      (res) => {
        if (res.data.success) {
          let modules = res?.data?.data;
          setIsLoading(false);
          setCreatedLeaveData(modules);
        }
      },
      (err) => { }
    );
  };

  const getMailAddress = () => {
    axiosGet(
      URL.getMailAddress,
      (res) => {
        if (res.data.success) {
          let modules = res?.data?.data;
          setMailAddress(modules);
        }
      },
      (err) => { }
    );
  };

  const getUserInfo = () => {
    axiosGet(
      URL.userInfo,
      (res) => {
        if (res.data.success) {
          let modules = res?.data.data?.item;
          setUserInfo(modules);
        }
      },
      (err) => { }
    );
  };

  const handleAcceptLeave = (e, id, status) => {
    setModalRemarks(true);
    setLeaveStatus((prevState) => ({ ...prevState, id: id, status: status }));
  };

  const handleCcToTeam = (e) => {
    const { options } = e.target;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedCC(value);
  };

  const handleCC = (e) => {
    const { options } = e.target;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setCCUsers(value);
  };

  // const handleSelectChange =(e) =>{

  // }
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    // [...e.target.selectedOptions].map(el=>el.value)
    setLeaveMailerData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleLeaveDateChange = (e) => {
    const { name, value } = e.target;
    setLeaveDate((prevState) => ({ ...prevState, [name]: value }));
    setActiveModule("search");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!leaveMailerData) {
      swal("Warning", "Field with asterisk are mandatory", "warning");
      return;
    }

    //check required field is filled or not
    if (Object.keys(leaveMailerData).length < 6) {
      swal("Warning", "Field with asterisk(*) are mandatory", "warning");
      return;
    }

    const additionalDetailsRequired =
      mailAddress && mailAddress.additional_description
        ? mailAddress.additional_description.has_additional_description === 1
          ? true
          : false
        : false;

    let mandatoryData;
    if (additionalDetailsRequired) {
      const {
        toHalfLeave,
        fromHalfType,
        toHalfType,
        fromHalfLeave,
        ...reqData
      } = leaveMailerData;
      mandatoryData = reqData;
    } else {
      const {
        additionalDetails,
        toHalfLeave,
        fromHalfType,
        toHalfType,
        fromHalfLeave,
        ...reqData
      } = leaveMailerData;
      mandatoryData = reqData;
    }
    const valueNeededToBeChecked = additionalDetailsRequired ? 7 : 6;
    if (Object.keys(mandatoryData).length < Number(valueNeededToBeChecked)) {
      swal("Warning", "Field with asterisk(*) are mandatory", "warning");
      return;
    }
    if (leaveMailerData.fromHalfLeave) {
      if (!leaveMailerData.fromHalfType || leaveMailerData.fromHalfType == "") {
        swal("Warning", "Please select half leave type", "warning");
        return;
      }
    }
    if (leaveMailerData.toHalfLeave) {
      if (!leaveMailerData.toHalfType || leaveMailerData.toHalfType == "") {
        swal("Warning", "Please select half leave type", "warning");
        return;
      }
    }
    let reqValue = checkIfEmpty(mandatoryData);
    if (reqValue?.isEmpty) {
      let msg = capitalizeFirstLetter(reqValue?.emptyKey);
      swal("Warning", `Missing ${msg} field `, "warning");
      return;
    }
    setSubmitSpinner(true);
    let cc = [];
    //changes required here
    if (mailAddress.cc.length > 0) {
      cc = mailAddress?.cc?.map((addr) => addr.id);
    } else {
      cc = null;
    }
    let ccToTeam = [];
    if (selectedCC.length > 0) {
      selectedCC.forEach((el) => {
        ccToTeam.push(parseInt(el));
      });
    } else {
      ccToTeam = null;
    }

    //required mandatory cc key as well according to the leave configuration

    let params = {
      subject: leaveMailerData?.subject,
      reason: leaveMailerData?.reasonForLeave,
      fromDate: leaveMailerData?.leaveForm,
      toDate: leaveMailerData?.leaveTo,
      leaveTypeId: leaveMailerData?.leaveType,
      fromHalfLeave: leaveMailerData?.fromHalfLeave ? 1 : 0,
      from_half_type: leaveMailerData?.fromHalfLeave
        ? leaveMailerData.fromHalfType
        : "",
      toHalfLeave: leaveMailerData?.toHalfLeave ? 1 : 0,
      to_half_type: leaveMailerData?.toHalfLeave
        ? leaveMailerData.toHalfType
        : "",
      cc,
      to: leaveMailerData?.sendTo,
      cc_to_group: leaveMailerData?.ccToGrp,
      additionalDescription:
        leaveMailerData.additionalDetails &&
          leaveMailerData.additionalDetails !== null
          ? leaveMailerData.additionalDetails
          : "",
    };

    if (ccToTeam && ccToTeam.length > 0) {
      params.ccToTeam = ccToTeam;
    }
    axiosPost(
      URL.insertLeave,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          swal("Success", "Leave Request created Successfully", "success");
          getLeaveData();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleSubmitLeave = (e) => {
    e.preventDefault();
    //as remarks is optional field no need pof validation
    let params;
    if (leaveStatus?.status === "accept") {
      params = {
        status: "Accepted",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else if (leaveStatus?.status === "reject") {
      params = {
        status: "Rejected",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else if (leaveStatus?.status === "revoke") {
      params = {
        status: "Revoked",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    } else {
      params = {
        status: "Breached",
        leaveId: Number(leaveStatus?.id),
        remarks: remarks,
        cc: ccUsers,
      };
    }
    setSubmitSpinner(true);
    axiosPost(
      URL.acceptLeave,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModalRemarks();
          setRemarks(null);
          swal(
            "Success",
            leaveStatus?.status === "accept"
              ? "Leave request accepted successfully"
              : leaveStatus?.status === "reject"
                ? "Leave request rejected successfully"
                : leaveStatus?.status === "revoke"
                  ? "Leave request revoked"
                  : leaveStatus?.status === "breach" && "Leave request breached",
            "success"
          );
          getLeaveData();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        toggleModalRemarks();
        displayErrorAlert(err);
        setRemarks(null);
      }
    );
  };

  const handleViewLeave = (e, id) => {
    setModalView(true);
    const selectedLeave = createdLeaveData.data.find(
      (leav) => leav?.id === Number(id)
    );
    setSelectedLeaveToView(selectedLeave);
  };

  const toggleModal = () => {
    setModal(!modal);
    setLeaveMailerData(null);
  };
  const toggleModalRemarks = () => {
    setModalRemarks(!modalRemarks);
  };

  const toggleModalView = () => {
    setModalView(!modalView);
    // setLeaveMailerData(null);
  };

  useEffect(() => {
    setLeaveMailerData((prevState) => ({
      ...prevState,
      leaveTo: leaveMailerData?.leaveForm,
    }));
  }, [leaveMailerData?.leaveForm]);

  useEffect(() => {
    //check the duration of leave date
    if (leaveMailerData?.leaveForm && leaveMailerData?.leaveTo) {
      const from = new Date(leaveMailerData?.leaveForm);
      const To = new Date(leaveMailerData?.leaveTo);

      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = To.getTime() - from.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);
      setDifferenceInDays(diffInDays);
    }
  }, [leaveMailerData]);

  useEffect(() => {
    setLeaveDate((prevState) => ({
      ...prevState,
      To: "",
    }));
  }, [LeaveDate?.From]);

  useEffect(() => {
    getAllUsers();
    getUserInfo();
    getAllGroupMail();
    getLeaveCategory();
    getMailAddress();
    if (!isAdmin) {
      getAllTeams();
    }
    // checkPermission();
    let value = checkModulePermission(moduleName?.leave);
    setAssignedPermission(value);
  }, []);

  useEffect(() => {
    getLeaveData();
  }, [userInfo?.first_name]);

  useEffect(() => {
    if (LeaveDate?.To !== undefined && LeaveDate?.From !== undefined) {
      getLeaveFilteredData();
    }
  }, [LeaveDate]);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Leave Form</div>
      </div>
      {submitSpinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please Wait. Submitting ...
          </div>
        </div>
      ) : null}

      {/* {checkRolePermissions("store", moduleName.leave) ? (
        <div className="">
          <Button name="Create Leave Request" onClick={toggleModal} />
        </div>
      ) : null} */}
      <Button name="Create Leave Request" onClick={toggleModal} />

      <div className="whiteContainer p-4">
        <div className="row m-0 justify-content-center">
          <h3 className="text-center sectionTitle pb-2">Search</h3>

          <div className="col text-start" style={{ position: "relative" }}>
            <label className="floating">From</label>
            <input
              type="date"
              style={myInput}
              className="form-control"
              name="From"
              value={LeaveDate?.From}
              onChange={handleLeaveDateChange}
            ></input>
          </div>

          <div className="col text-start" style={{ position: "relative" }}>
            <label className="floating">To</label>
            <input
              type="date"
              style={myInput}
              value={LeaveDate?.To}
              name="To"
              className="form-control"
              min={LeaveDate?.From}
              onChange={handleLeaveDateChange}
            ></input>
          </div>
        </div>
      </div>

      <div className="mt-2 text-center">
        <ListLeaveRequest
          allUsers={allUsers}
          ccUsers={ccUsers}
          handleCcToTeam={handleCC}
          toggleModal={toggleModalRemarks}
          modal={modalRemarks}
          leaveData={createdLeaveData}
          assignedPermission={assignedPermission}
          handleAcceptLeave={handleAcceptLeave}
          // handleChange={handleChangeRemarks}
          handleSubmit={handleSubmitLeave}
          remarks={remarks}
          setRemarks={setRemarks}
          isLoading={isLoading}
          leaveStatus={leaveStatus}
          handleViewLeave={handleViewLeave}
          // handleExportLeave={handleExportLeave}
          getLeaveData={getLeaveData}
          getLeaveFilteredData={getLeaveFilteredData}
          activeModule={activeModule}
        />
      </div>

      <CommonModal
        modal={modalView}
        toggleModal={toggleModalView}
        size="lg"
        modalHeader="Leave Details"
        modalBody={
          <ViewLeaveDetails selectedLeaveToView={selectedLeaveToView} />
        }
      />

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={"Create Leave Request"}
        size="xl"
        modalBody={
          <>
            <div className="container  mt -3">
              <form onSubmit={handleSubmit} id="msform">
                <div className="row">
                  <div className="col text-start">
                    {/* //input field value will be default and comes from api */}
                    <label className="floating"> Staff:</label>
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="Staff"
                      value={`${userInfo?.first_name}${userInfo?.middle_name ? userInfo?.middle_name : ""
                        } ${userInfo?.last_name ? userInfo?.last_name : ""}`}
                      disabled
                    ></input>
                  </div>
                  <div className="col text-start">
                    <label className="floating">
                      Subject <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Subject For Leave"
                    ></input>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col">
                    <label className="floating">
                      Leave Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="formSelect"
                      name="leaveType"
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Leave Type
                      </option>
                      {LeaveCategory?.map((leave, idx) => (
                        <option value={leave?.id} key={idx}>
                          {leave?.leave_type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col">
                    <label className="floating">
                      Reason For Leave <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      className="textArea"
                      placeholder="Reason for Leave"
                      name="reasonForLeave"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col">
                    <label className="floating">
                      Additional Details{" "}
                      {mailAddress &&
                        mailAddress.additional_description &&
                        mailAddress.additional_description
                          .has_additional_description === 1 ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : null}
                    </label>
                    <textarea
                      className="textArea"
                      placeholder="Additional Details"
                      name="additionalDetails"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col text-start">
                    <label className="floating">
                      Leave From <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      name="leaveForm"
                      className="form-control"
                      onChange={handleChange}
                      value={leaveMailerData?.leaveForm}
                      placeholder="Leave Form"
                      min={new Date().toLocaleDateString("en-ca")}
                    ></input>
                    <div className="row">
                      {!leaveMailerData?.leaveForm ? null : Number(
                        differenceInDays
                      ) === 0 && leaveMailerData?.toHalfLeave ? null : (
                        <div
                          className="col-md-3"
                          style={{ marginTop: "0.75rem" }}
                        >
                          <Checkbox
                            handleChange={handleChange}
                            name="fromHalfLeave"
                          />
                          <label htmlFor="Half LEave ">Half Day</label>
                        </div>
                      )}

                      {!leaveMailerData?.fromHalfLeave ? null : Number(
                        differenceInDays
                      ) === 0 && leaveMailerData?.toHalfLeave ? null : (
                        <div className="col">
                          <label className="floating">
                            Half Leave <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="formSelect"
                            // name="halfLeave"
                            name="fromHalfType"
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Choose Half Leave Type
                            </option>
                            <option value="before">Before Half</option>
                            <option value="after">After Half</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col text-start">
                    <label className="floating">
                      Leave To <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      name="leaveTo"
                      className="form-control"
                      min={leaveMailerData?.leaveForm}
                      onChange={handleChange}
                      value={leaveMailerData?.leaveTo}
                      placeholder="Leave To"
                    ></input>
                    <div className="row">
                      {!leaveMailerData?.leaveTo ? null : Number(
                        differenceInDays
                      ) === 0 && leaveMailerData?.fromHalfLeave ? null : (
                        <div
                          className="col-md-3"
                          style={{ marginTop: "0.75rem" }}
                        >
                          <Checkbox
                            handleChange={handleChange}
                            name="toHalfLeave"
                          />
                          <label htmlFor="Half LEave ">Half Day</label>
                        </div>
                      )}

                      {!leaveMailerData?.toHalfLeave ? null : Number(
                        differenceInDays
                      ) === 0 && leaveMailerData?.fromHalfLeave ? null : (
                        <div className="col">
                          <label className="floating">
                            Half Leave <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="formSelect"
                            // name="halfLeave"
                            name="toHalfType"
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Choose Half Leave Type
                            </option>
                            <option value="before">Before Half</option>
                            <option value="after">After Half</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col">
                    <label className="floating">
                      Send To <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <select
                      // type="data-list"
                      className="formSelect mb-0"
                      // multiple
                      name="sendTo"
                      onChange={(e) => {
                        let value = [...e.target.selectedOptions].map(
                          (el) => el.value
                        );
                        setLeaveMailerData((prevState) => ({
                          ...prevState,
                          [e.target.name]: value,
                        }));
                      }}
                    >
                      <option value="" disabled selected>
                        Choose User
                      </option>
                      {mailAddress?.to?.map((addr, idx) => (
                        <option value={addr?.id} key={idx}>
                          {addr?.send_to_mail}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  {mailAddress?.cc.length > 0 && (
                    <div className="col">
                      <label className="floating">Mandatory CC to:</label>
                      <select
                        type="data-list"
                        className="formSelect"
                        multiple
                        disabled
                      >
                        {mailAddress?.cc?.map((addr, idx) => (
                          <option value={addr?.id} selected key={idx}>
                            {addr?.send_cc_to_mail}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {groupMail?.length > 0 && (
                    <div className="col">
                      <label className="floating">CC To Group:</label>
                      <select
                        type="data-list"
                        className="formSelect"
                        multiple
                        name="ccToGrp"
                        onChange={(e) => {
                          let value = [...e.target.selectedOptions].map(
                            (el) => el.value
                          );
                          setLeaveMailerData((prevState) => ({
                            ...prevState,
                            [e.target.name]: value,
                          }));
                        }}
                      >
                        {groupMail?.map((addr, idx) => (
                          <option value={addr?.id} key={idx}>
                            {addr?.email}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {!isAdmin && (
                    <div className="col">
                      <label className="floating"> CC To Team:</label>
                      <select
                        className="formSelect"
                        multiple
                        name="ccToTeam"
                        onChange={handleCcToTeam}
                      >
                        {teams.map((addr, idx) => (
                          <option value={addr?.id} key={idx}>
                            {addr.office_email}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="text-start newTextColor">
                      <p>
                        Hold <kbd>Ctrl</kbd> to select multiple email
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-end mt-2">
                  <Button name="Create" type="submit" />
                </div>
              </form>
            </div>
            {/* </div> */}
          </>
        }
      />
    </>
  );
};

const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px",
};

export default CreateLeave;
