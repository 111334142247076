import React from "react";
const NotFound = () => {
  return (
    <div className="notfoundcontainer">
      <div className="container">
        <div className="notfound">
          <h1>404 Not Found</h1>

          <div id="eye-container">
            <div className="eyes" id="left-eye">
              <div className="pupils" id="left-pupil" />
            </div>
            <div className="eyes" id="right-eye">
              <div className="pupils" id="right-pupil" />
            </div>
          </div>
          <p>Page You are looking for isn't here!</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
