import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import {
  checkModulePermission,
  checkRolePermissions
} from "../../utils/PermissionChecker";
import { moduleName, URL } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import moment from "moment";
import swal from "sweetalert";
import OvertimeDepartmentList from "./OvertimeDepartmentList";

const OverTimeCardList = ({
  overTimeData,
  isFetching,
  setSubmitSpinner,
  fetchOverTimeCard,
  updatePermission
}) => {
  const handleApprove = (e, id) => {
    setSubmitSpinner(true);
    let params = { id, approval_status: "Accepted" };
    axiosPost(
      URL.updateOverTimeCard,
      params,
      response => {
        if (response.status === 200) {
          swal("Success", "Gate Pass approved successfully", "success");
          fetchOverTimeCard();
          setSubmitSpinner(false);
        }
      },
      err => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  const handleReject = (e, id) => {
    setSubmitSpinner(true);
    let params = { id, approval_status: "Rejected" };
    axiosPost(
      URL.updateOverTimeCard,
      params,
      response => {
        if (response.status === 200) {
          swal("Success", "Gate Pass rejected succesfully", "success");
          fetchOverTimeCard();
          setSubmitSpinner(false);
        }
      },
      err => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN.</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Time From</th>
                  <th className="text-center">Time To</th>
                  <th className="text-center">Duration(Hours)</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Status</th>

                  <th className="text-center">Approved By</th>
                  {updatePermission ? (
                    <th className="text-center">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {overTimeData && overTimeData.length > 0 ? (
                  overTimeData.map((oT, id) => (
                    <tr key={id}>
                      <td className="text-center">{id + 1}</td>
                      <td className="text-center">{oT.ot_date}</td>
                      <td className="text-center">
                        {moment(oT.time_from, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {moment(oT.time_to, "HH:mm:ss").format("hh:mm A")}
                      </td>
                      <td className="text-center">
                        {`0${(oT.duration / 60) ^ 0}`.slice(-2) +
                          ":" +
                          ("0" + (oT.duration % 60)).slice(-2)}
                      </td>
                      <td className="text-center">{oT.ot_work_desc}</td>
                      <td className="text-center">{oT.approval_status}</td>
                      <td className="text-center">
                        {oT?.approve_by?.first_name ?? "-"}
                      </td>
                      {updatePermission ? (
                        <td className="text-center">
                          {oT.approval_status === "Accepted" ? null : (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-info rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="Approve"
                              onClick={e => handleApprove(e, oT.id)}
                            >
                              <i className="material-symbols-outlined">
                                done_all
                              </i>
                            </button>
                          )}

                          {oT.approval_status === "Rejected" ? null : (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-danger rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="Reject"
                              onClick={e => handleReject(e, oT.id)}
                            >
                              <i className="material-symbols-outlined">
                                remove_done
                              </i>
                            </button>
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={updatePermission ? 9 : 8}>
                      {isFetching ? (
                        <div className="text-center">
                          <Spinner color="whiteSmoke" size="sm" />
                        </div>
                      ) : (
                        "No OverTime Card found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const ViewAllOverTimeCard = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [company, setCompany] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentStaff, setDepartmentStaff] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [overTimeData, setOverTimeData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [overtimeDepartmentData, setOvertimeDepartmentData] = useState(false);

  const isParentCompany = JSON.parse(localStorage.getItem("parent_company"));
  useEffect(() => {
    getCompany();
    getDepartment();
    permissionConfig();
  }, []);

  const permissionConfig = () => {
    setUpdatePermission(checkRolePermissions("update", moduleName.overTime));
  };

  useEffect(() => {
    fetchOverTimeCard();
  }, [selectedData]);

  useEffect(() => {
    getDepartment();
  }, [selectedData?.company]);

  const fetchOverTimeCard = () => {
    setIsFetching(true);
    axiosPost(
      URL.getOverTimeCard,
      {
        staff_id: selectedData?.departmentStaff ?? null,
        company_id: selectedData?.company ?? null,
        pass_from: selectedData?.from ?? null,
        pass_to: selectedData?.to ?? null,
        department_id: selectedData?.department ?? null
      },
      response => {
        if (response.status === 200) {
          setIsFetching(false);
          let modules = response.data;
          setOverTimeData(modules);
        }
      },
      err => {
        setIsFetching(false);
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    if (selectedData?.department) {
      setOvertimeDepartmentData(null);
      setSelectedData(prevState => ({
        ...prevState,
        departmentStaff: null
      }));
      getDepartmentFilterOvertime();
      getDepartmentStaff();
    }
  }, [selectedData?.department]);

  const getDepartmentFilterOvertime = () => {
    let param = {
      dept_id: Number(selectedData?.department),
      from_date: null,
      to_date: null
    };
    axiosPost(
      URL.getOvertimeFilterByDepartment,
      param,
      res => {
        if (res.status === 200) {
          setOvertimeDepartmentData(res.data);
        }
      },
      err => {
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    if (selectedData?.company) {
      setSelectedData(prevState => ({
        ...prevState,
        department: null,
        departmentStaff: null
      }));
    }
  }, [selectedData?.company]);

  const getCompany = () => {
    axiosGet(
      URL.getRespectiveCompany,
      response => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setCompany(modules);
        }
      },
      err => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartment = () => {
    let url =
      selectedData?.company === undefined
        ? URL.getDepartment
        : `${URL.getResDepartment}/${selectedData?.company}`;
    axiosGet(
      url,
      response => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDepartment(modules);
        }
      },
      err => {
        displayErrorAlert(err);
      }
    );
  };
  const getDepartmentStaff = () => {
    axiosGet(
      `${URL.getDepartmentStaffLeave}/${selectedData?.department}`,
      response => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDepartmentStaff(modules);
        }
      },
      err => {
        displayErrorAlert(err);
      }
    );
  };

  const resetData = () => {
    setIsFilter(false);
    setSelectedData(null);
    setOvertimeDepartmentData(null);
    // setLeaveDate(null);
    // setLeaveData(null);
    // setLeaveDepartmentData(null);
    // setSelectedLeaveToView(null);
    // isParentCompany && setDepartment(null);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSelectedData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">View OverTime Card </div>

        {submitSpinner && (
          <div
            className="container"
            style={{ padding: "1rem", height: "100%" }}
          >
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Submitting ...
              </div>
            </div>
          </div>
        )}

        <div className="whiteContainer p-4">
          <div className="row justify-content-center d-flex">
            {isParentCompany && (
              <div className="col" style={{ position: "relative" }}>
                <label className="floating">Company</label>
                <select
                  className="formSelect"
                  name="company"
                  onChange={handleChange}
                  value={selectedData?.company ?? ""}
                >
                  <option value="" disabled selected>
                    Choose Company
                  </option>
                  {company?.map((cmp, idx) => (
                    <option value={cmp?.id} key={idx}>
                      {cmp?.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department</label>
              <select
                className="formSelect"
                name="department"
                onChange={handleChange}
                value={selectedData?.department ?? ""}
                disabled={false}
              >
                <option value="" disabled selected>
                  Choose Department
                </option>
                {department?.map((dpt, idx) => (
                  <option value={dpt?.id} key={idx}>
                    {dpt?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department staff</label>
              <select
                className="formSelect"
                name="departmentStaff"
                onChange={handleChange}
                value={selectedData?.departmentStaff ?? ""}
                disabled={selectedData?.department === undefined ? true : false}
              >
                <option value="" disabled selected>
                  Choose DepartmentStaff
                </option>
                {departmentStaff?.map((dptStf, idx) => (
                  <option value={dptStf?.user_id} key={idx}>
                    {dptStf?.first_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isFilter && (
            <div className="row">
              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">From</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="from"
                  onChange={handleChange}
                  value={selectedData?.from ?? ""}
                ></input>
              </div>

              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">To</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="to"
                  onChange={handleChange}
                  value={selectedData?.to ?? ""}
                ></input>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col text-end">
              <Button name="Filter" onClick={() => setIsFilter(!isFilter)} />

              <Button
                name="Reset"
                onClick={() => resetData()}
                className="ms-2"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center d-flex text-center">
          <div className="mt-2">
            {overtimeDepartmentData &&
            (selectedData?.departmentStaff === null ||
              selectedData?.departmentStaff === undefined) ? (
              <OvertimeDepartmentList dataOvertime={overtimeDepartmentData} />
            ) : (
              <OverTimeCardList
                overTimeData={overTimeData}
                isFetching={isFetching}
                fetchOverTimeCard={fetchOverTimeCard}
                setSubmitSpinner={setSubmitSpinner}
                updatePermission={updatePermission}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px"
};

export default ViewAllOverTimeCard;
