import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import CommonModal from "../../components/common/Modal";
import Button from "../../components/common/Button";
import { Spinner } from "reactstrap";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import { moduleName } from "../../utils/Constants";
import {
  faTimesCircle,
  faCheckCircle,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { faRev } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import LeaveResponse from "./LeaveResponse";
import Pagination from "react-js-pagination";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const ListLeaveRequest = (props) => {
  const {
    allUsers,
    ccUsers,
    leaveData,
    toggleModal,
    modal,
    handleAcceptLeave,
    handleSubmit,
    remarks,
    setRemarks,
    isLoading,
    leaveStatus,
    handleCcToTeam,
    leaveDataFiltered,
    viewLeave,
    handleViewLeave,
    request,
    getLeaveData,
    getLeaveFilteredData,
    activeModule,
  } = props;
  useEffect(() => {
    checkPermissions();
    // setRemarks(null);
  }, []);

  useEffect(() => {
    !modal && setRemarks(null);
  }, [modal]);

  const [updatePermission, setUpdatePermission] = useState(false);

  const checkPermissions = () => {
    let update = checkRolePermissions("update", moduleName.leave);
    setUpdatePermission(update);
  };
  if (leaveData) {
    var { current_page, per_page, total } = leaveData;
  }

  // const dataLeave = leaveDataFiltered ? leaveDataFiltered?.leaves : leaveData;
  const dataLeave = viewLeave ? leaveDataFiltered?.leaves : leaveData?.data;

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              {request ? <th>Requested By</th> : null}
              <th>Leave From</th>
              <th>Leave To</th>
              {/* {viewLeave ? null : <th>Leave Type</th>} */}
              <th>Leave Type</th>
              <th>Subject</th>
              <th>Leave Duration (Days)</th>
              <th>Leave this month</th>
              {/* {viewLeave ? <th>Status</th> : null} */}
              <th>Applied at</th>
              <th>Status</th>
              {/* {viewLeave && <th>Remarks</th>} */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataLeave && dataLeave.length > 0 ? (
              dataLeave.map((leave, id) => (
                <tr key={id}>
                  <td>{id + 1}</td>
                  {request ? (
                    <td>
                      {leave.user.first_name} {leave.user.last_name}
                    </td>
                  ) : null}
                  <td>
                    {leave?.from_date}
                    <br></br>
                    <strong>
                      {leave.from_half_leave === 1
                        ? leave.from_half_type === "before"
                          ? "[ First Half Leave]"
                          : leave.from_half_type === "after"
                          ? "[Second Half Leave]"
                          : "[Half Leave]"
                        : null}
                    </strong>
                  </td>
                  <td>
                    {leave?.to_date} <br></br>
                    <strong>
                      {leave.to_half_leave === 1
                        ? leave.to_half_type === "before"
                          ? "[ First Half Leave]"
                          : leave.to_half_type === "after"
                          ? "[Second Half Leave]"
                          : "[Half Leave]"
                        : null}
                      {/* {leave.to_half_leave === 1 ? "[ Half Day ]" : null} */}
                    </strong>
                  </td>
                  <td>{leave?.leave_category?.leave_type}</td>
                  <td>{leave?.subject}</td>
                  <td>{leave?.duration}</td>
                  <td>{leave?.this_month_count}</td>
                  <td>{moment(leave.created_at).format("ll")}</td>
                  <td>
                    {leave?.status}{" "}
                    {leave?.status !== "Pending" ? (
                      <strong>
                        <br />
                        by {leave.acceptor_user.first_name}{" "}
                        {leave.acceptor_user.last_name}
                      </strong>
                    ) : null}
                  </td>
                  <td>
                    {updatePermission ? (
                      <>
                        {leave.status !== "Accepted" && (
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-success btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="Accept"
                            onClick={(e) =>
                              handleAcceptLeave(e, leave?.id, "accept")
                            }
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </button>
                        )}

                        {leave.status === "Pending" ? null : leave.status ===
                          "Revoked" ? null : (
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-warning btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="Revoke"
                            onClick={(e) =>
                              handleAcceptLeave(e, leave?.id, "revoke")
                            }
                          >
                            <FontAwesomeIcon icon={faRev} />
                          </button>
                        )}

                        {leave.status === "Pending" ? null : leave.status ===
                          "Breached" ? null : (
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-secondary btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="Breached"
                            onClick={(e) =>
                              handleAcceptLeave(e, leave?.id, "breach")
                            }
                          >
                            <FontAwesomeIcon icon={faBroom} />
                          </button>
                        )}

                        {leave.status !== "Rejected" && (
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-danger btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="Reject"
                            onClick={(e) =>
                              handleAcceptLeave(e, leave?.id, "reject")
                            }
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </button>
                        )}
                      </>
                    ) : null}

                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-primary btn-just-icon btn-sm"
                      style={{ marginLeft: "0.75rem" }}
                      data-original-title=""
                      title="View"
                      onClick={(e) => handleViewLeave(e, leave?.id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={request ? 11 : 10}>
                  {isLoading ? (
                    <LoadingAnimation spinner />
                  ) : (
                    "No Leave request have been created"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!leaveData || leaveData?.total <= 5 || props.noPagination ? null : (
          <div className="paginationContainer">
            <Pagination
              activePage={current_page}
              itemsCountPerPage={per_page}
              totalItemsCount={total}
              onChange={(pageNumber) =>
                activeModule === "main"
                  ? getLeaveData(pageNumber)
                  : getLeaveFilteredData(pageNumber)
              }
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        )}
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="lg"
        modalHeader="Add Remarks"
        modalBody={
          <LeaveResponse
            allUsers={allUsers}
            ccUsers={ccUsers}
            handleSubmit={handleSubmit}
            handleCcToTeam={handleCcToTeam}
            setRemarks={setRemarks}
            remarks={remarks}
            leaveStatus={leaveStatus}
          />
          // <div className="container">
          //   <form onSubmit={handleSubmit} id="msform">
          //     <div className="row">
          //       <div className="col text-start">
          //         <label className="floating">Remarks:</label>
          //         <textarea
          //           type="text"
          //           className="form-control"
          //           value={remarks}
          //           onChange={e => setRemarks(e.target.value)}
          //         />
          //       </div>
          //     </div>
          //     <div className="row mt-2">
          //       <div className="col text-end ">
          //         <Button
          //           name={
          //             leaveStatus?.status === "accept"
          //               ? "Accept"
          //               : leaveStatus?.status === "reject"
          //               ? "Reject"
          //               : leaveStatus?.status === "revoke"
          //               ? "Revoke"
          //               : leaveStatus?.status === "breach" && "Breach"
          //           }
          //         />
          //       </div>
          //     </div>
          //   </form>
          // </div>
        }
      />
    </>
  );
};

export default ListLeaveRequest;
