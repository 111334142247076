export function emailValidate(value) {
  let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailReg.test(value)) {
    return true;
  }
  return false;
}

export function phoneValidate(value) {
  let numbers = /^[0-9]+$/;
  if (numbers.test(value)) {
    return true;
  }
  return false;
}

export function CheckIsValidDomain(domain) {
  var exp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  var re = new RegExp(exp);
  return domain.match(re);
}

export const checkIfEmpty = dD => {
  for (let [key, value] of Object.entries(dD)) {
    if (dD[key] === "" || dD[key] === null) {
      return { isEmpty: true, emptyKey: key };
    }
    //do nothing
  }
};

export const checkIsValidPassword = pwd => {
  var exp =
    "^(?=.*[a-z])(?=." + "*[A-Z])(?=.*\\d)" + "(?=.*[-+_!@#$%^&*., ?]).+$";
  var re = new RegExp(exp);
  return pwd.match(re);
};

export const convertToSnakeCase = value => {
  const finalName = value.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
  return finalName;
};

export const convertToCamelCase = value => {
  const finalName = value.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );
  return finalName;
};

// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = str => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};
