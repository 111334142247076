import React, { Component } from "react";

class OvertimeDepartmentList extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>S.N.</th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Email</th>
              <th>Total Overtime Requests</th>
              <th>Accepted OverTime (in Hours)</th>
            </tr>
          </thead>
          <tbody>
            {this.props.dataOvertime && this.props.dataOvertime.length > 0 ? (
              this.props.dataOvertime.map((ot, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      {ot?.first_name} {ot?.middle_name && ot?.middle_name}{" "}
                      {ot?.last_name}
                    </td>
                    <td>{ot?.job_title}</td>
                    <td>{ot?.office_email}</td>
                    <td>
                      {ot.pass_report.gatepasses.length > 0
                        ? ot.pass_report.gatepasses.length
                        : 0}
                    </td>
                    <td>{ot.pass_report.total_accepted_hours}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={6}>No Overtime request have been created yet </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default OvertimeDepartmentList;
